export const initial_state_hbu = {
  peruntukan: "",
  hbu_type: "",
  alamat: "",
  luas_tanah: 0,
  panjang_tanah: 0,
  lebar_tanah: 0,
  ratio_tanah: 0,
  panjang_bangunan: 0,
  lebar_bangunan: 0,
  LLBE: 0,
  LDBE: 0,
  KLB: 1.2,
  KDB: 60,
  LDB: 0,
  LLB: 0,
  JLB: 0,
  LDB_excel: 0,
  LLB_excel: 0,
  JLB_excel: 0,
  GSB: 0,
  tahun_peraturan_dikeluarkan: 0,
  IMB: "Tidak Ada",
  tahun_uji_hbu: 0,
  tahun_bangun: 0,
  lebar_jalan_depan: 8,
  lebar_hadap_jalan: 0,
  capex_tanah_input: 0,
  calc_update: 0,
  sini_hbu_update: 0,
  alt_selected: {},
  first_year: 0,
  headers: [],
  rows: [],
  rows_filtered_1: [],
  rows_filtered_2: [],
  rows_filtered_3: [],
  rows_filtered_4: [],
  rows_filtered_5: [],
  rows_rejected_1: [],
  rows_rejected_2: [],
  rows_rejected_3: [],
  rows_rejected_4: [],
  rows_rejected_5: [],
  real_filtered_rows: [],
  calculated_rows: [],
  is_force_pass_1: false,
  is_force_pass_2: false,
  is_force_pass_3: false,
  is_force_pass_4: false,
  is_force_pass_5: false,
  percent_ratio_tolerance: 0,
  project_duration_years: 10, //Durasi Proyek
  discount_rate_1_percent: 15, //Tingkat Diskonto Satu
  revenue_growth_percent: 8, //Growth Pendapatan
  opex_growth_percent: 3, //Growth OPEX
  working_capital_idr: 0, //Modal Kerja
  discount_rate_2_percent: 30, //Tingkat Diskonto Dua
  economic_life_years: 30, //Umur Ekonomis
  tax_percent: 22, //Pajak Penghasilan Badan
  skema_terminal_value: true, //Skema Perhitungan Terminal Value
};

export default function hbu_reducer(state = initial_state_hbu, action) {
  switch (action.type) {
    case "calc_update":
      return {
        ...state,
        calc_update: state.calc_update + 1,
      };
    case "sini_hbu_update":
      return {
        ...state,
        sini_hbu_update: state.sini_hbu_update + 1,
      };
    case "set_value_hbu":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_hbu_reducer_from_db":
      return {
        ...action.payload,
      };
    case "set_hbu_reducer_into_intitial":
      return {
        ...initial_state_hbu,
      };
    default:
      return state;
  }
}
