/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import circle from "@turf/circle";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_genangan } from "../../App/actions/algoritma_genangan";

import {
  get_contour_genangan,
  set_value_genangan,
  genangan_update,
  push_value_genangan,
  loading_rambatan,
  recursiveFloodPolygon,
  not_recursiveFloodPolygon,
  find_polygon_genangan,
  get_DEM_debug,
} from "../../App/actions/genangan_actions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import format_date_formal from "../../App/validation/format_date_formal";

/*NON IMPORT*/

class STATUS_GET_GENANGAN_CONTENT extends Component {
  state = {
    index_max: 50,
    radius_start: 2.5,
    radius_start_propagation: 10,
    delta_sample_perimeter_m: 1.45,
    delta_sample_final_m: 7,
    overwrite_status: false,
    jalankan_ulang: false,
    get_polygon_from_db: false,
    genangan_mdpl: this.props.genangan_reducer.genangan_mdpl,
    radius_titik_dem: 300,
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.genangan_reducer.genangan_mdpl !==
      this.props.genangan_reducer.genangan_mdpl
    ) {
      this.setState({
        genangan_mdpl: this.props.genangan_reducer.genangan_mdpl,
      });
    }
  }

  on_rerun_debug_genangan = (e) => {
    e.preventDefault();
    const { genangan_mdpl } = this.state;
    const {
      feature_key_params_genangan,
      latitude_params_genangan,
      longitude_params_genangan,
    } = this.props.properties;
    const body = {
      feature_key_params_genangan: feature_key_params_genangan,
      genangan_mdpl: genangan_mdpl,
      body: {
        centroid: {
          latitude: latitude_params_genangan,
          longitude: longitude_params_genangan,
        },
        radiusInMeters: this.state.radius_titik_dem,
      },
    };
    this.props.get_DEM_debug(body, (result) => {});
  };

  on_overwrite_status_true = (e) => {
    // this.setState({ overwrite_status: true });
    const { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer_object = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );
    const geo_layer_id = geo_layer_object?.geo_layer?.storage_key;

    e.preventDefault();
    const {
      feature_key_params_genangan,
      genangan_cm_params_genangan,
      latitude_params_genangan,
      longitude_params_genangan,
    } = this.props.properties;

    const genangan_mdpl_baru = parseFloat(this.state.genangan_mdpl);
    const tinggi_titik_dem_terdekat =
      this.props.genangan_reducer.tinggi_titik_dem_terdekat;

    const genangan_cm = (genangan_mdpl_baru - tinggi_titik_dem_terdekat) * 100;

    const body = {
      feature_key_params_genangan: feature_key_params_genangan,
      genangan_cm: genangan_cm,
      body: {
        centroid: {
          latitude: latitude_params_genangan,
          longitude: longitude_params_genangan,
        },
        radiusInMeters: this.state.radius_titik_dem,
      },
    };
    // this.props.loading_rambatan("geojson_polygon_convex");
    // this.props.genangan_update();
    this.props.get_contour_genangan(
      body,
      (geojson_contour_flooded, geojson_polygon_not_flooded) => {
        const radius_5_meter = circle(
          [longitude_params_genangan, latitude_params_genangan],
          5,
          {
            steps: 15,
            units: "meters",
            properties: { foo: "bar" },
          }
        );

        const geo_radius_5_meter = {
          type: "FeatureCollection",
          features: [radius_5_meter],
        };
        this.props.not_recursiveFloodPolygon(
          geojson_contour_flooded,
          geojson_polygon_not_flooded,
          geo_radius_5_meter,
          genangan_mdpl_baru,
          feature_key_params_genangan,
          latitude_params_genangan,
          longitude_params_genangan,
          genangan_cm_params_genangan,
          this.state.index_max,
          this.state.radius_start_propagation,
          true,
          geo_layer_id,
          tinggi_titik_dem_terdekat
        );
      }
    );
  };

  on_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  on_close_modal = () => {
    this.props.set_value_properties({
      key: "modal_pop_up_genangan",
      value: false,
    });
  };

  // on_overwrite = () => {
  //   const {
  //     overwrite_longitude,
  //     overwrite_latitude,
  //     overwrite_genangan_cm,
  //     overwrite_feature_key,
  //   } = this.props.sini;
  //   const body = {
  //     longitude: overwrite_longitude,
  //     latitude: overwrite_latitude,
  //     genangan_cm: overwrite_genangan_cm,
  //     feature_key: overwrite_feature_key,
  //     overwrite_status: true,
  //   };
  //   this.props.get_genangan(body);
  // };

  // on_get_genangan = (e) => {
  //   e.preventDefault();
  //   const {
  //     feature_key_params_genangan,
  //     genangan_cm_params_genangan,
  //     latitude_params_genangan,
  //     longitude_params_genangan,
  //   } = this.props.properties;
  //   const {
  //     index_max,
  //     radius_start,
  //     radius_start_propagation,
  //     delta_sample_perimeter_m,
  //     delta_sample_final_m,
  //     overwrite_status,
  //   } = this.state;
  //   const body = {
  //     longitude: longitude_params_genangan,
  //     latitude: latitude_params_genangan,
  //     genangan_cm: genangan_cm_params_genangan,
  //     feature_key: feature_key_params_genangan,
  //     overwrite_status,
  //     index_max,
  //     radius_start,
  //     radius_start_propagation,
  //     delta_sample_perimeter_m,
  //     delta_sample_final_m,
  //   };
  //   this.props.get_genangan(body);
  //   this.setState({
  //     overwrite_status: false,
  //   });
  // };

  on_get_genangan_baru = (e) => {
    e.preventDefault();

    const { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer_object = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );
    const geo_layer_id = geo_layer_object?.geo_layer?.storage_key;
    const {
      feature_key_params_genangan,
      genangan_cm_params_genangan,
      latitude_params_genangan,
      longitude_params_genangan,
    } = this.props.properties;

    const { features_polygon_convex, feature_key_selected_genangan } =
      this.props.genangan_reducer;
    const body = {
      feature_key_params_genangan: feature_key_params_genangan,
      genangan_cm: genangan_cm_params_genangan,
      body: {
        centroid: {
          latitude: latitude_params_genangan,
          longitude: longitude_params_genangan,
        },
        radiusInMeters: this.state.radius_titik_dem,
      },
    };

    this.props.find_polygon_genangan(
      feature_key_params_genangan,
      geo_layer_id,
      (result) => {
        if (result === null) {
          this.props.get_contour_genangan(body, (result) => {});
        } else {
          this.setState({
            genangan_mdpl: this.props.genangan_reducer.genangan_mdpl,
          });
          if (
            features_polygon_convex.some(
              (data) =>
                data.properties?.feature_key === feature_key_selected_genangan
            )
          ) {
            //jalankan ulang
            this.setState({ jalankan_ulang: true });
            this.setState({ get_polygon_from_db: false });
          } else {
            this.props.push_value_genangan({
              key: "features_polygon_convex",
              value: result,
            });
            this.props.genangan_update();

            this.setState({ jalankan_ulang: true });
            this.setState({ get_polygon_from_db: false });
          }
        }
      }
    );
  };

  toggle_dem_visible = () => {
    this.props.set_value_genangan({
      key: "is_dem_visible",
      value: !this.props.genangan_reducer.is_dem_visible,
    });
    this.props.genangan_update();
  };

  on_start = async () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer_object = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );
    const geo_layer_id = geo_layer_object?.geo_layer?.storage_key;

    const {
      geojson_contour_flooded,
      genangan_mdpl,
      geojson_polygon_not_flooded,
      tinggi_titik_dem_terdekat,
    } = this.props.genangan_reducer;
    const {
      latitude_params_genangan,
      longitude_params_genangan,
      genangan_cm_params_genangan,
      feature_key_params_genangan,
    } = this.props.properties;

    const radius_5_meter = circle(
      [longitude_params_genangan, latitude_params_genangan],
      5,
      {
        steps: 15,
        units: "meters",
        properties: { foo: "bar" },
      }
    );

    const geo_radius_5_meter = {
      type: "FeatureCollection",
      features: [radius_5_meter],
    };

    if (this.state.get_polygon_from_db) {
      await this.props.find_polygon_genangan(
        feature_key_params_genangan,
        geo_layer_id,
        (result) => {
          this.props.push_value_genangan({
            key: "features_polygon_convex",
            value: result,
          });
          this.props.genangan_update();
        }
      );
      this.setState({ jalankan_ulang: true });
      this.setState({ get_polygon_from_db: false });
    } else {
      await this.props.not_recursiveFloodPolygon(
        geojson_contour_flooded,
        geojson_polygon_not_flooded,
        geo_radius_5_meter,
        genangan_mdpl,
        feature_key_params_genangan,
        latitude_params_genangan,
        longitude_params_genangan,
        genangan_cm_params_genangan,
        this.state.index_max,
        this.state.radius_start_propagation,
        false,
        geo_layer_id,
        tinggi_titik_dem_terdekat
      );
      this.setState({ jalankan_ulang: true });
      this.setState({ get_polygon_from_db: false });
      // this.props.genangan_update();
    }
  };

  render() {
    //local storage

    //local state
    const {
      // index_max,
      // radius_start_propagation,
      jalankan_ulang,
      genangan_mdpl,
      radius_titik_dem,
    } = this.state;

    //global props
    const { feature_key_params_genangan, genangan_cm_params_genangan } =
      this.props.properties;
    // const { features_genangan } = this.props.sini;
    const {
      array_genangan_failed,
      array_genangan_message_failed,
      current_union,
      total_union,
      contourCreated,
      features_polygon_convex,
      feature_key_selected_genangan,
      feature_object_selected_genangan,
      is_dem_visible,
      feature_key_failed_genangan,
      tinggi_titik_dem_terdekat,
    } = this.props.genangan_reducer;

    const data_genangan = features_polygon_convex.find(
      (data) => data?.properties?.feature_key === feature_key_selected_genangan
    );
    let error_status = this.props.properties.error_status;
    let error_message = this.props.properties.error_message;
    if (array_genangan_failed.includes(feature_key_params_genangan)) {
      error_status = true;
      error_message = array_genangan_message_failed.find(
        (item) => item?.feature_key === feature_key_params_genangan
      )?.error_message;
    }
    const { loading_item_array } = this.props.loading_reducer;
    //content
    // const feature_genangan = features_genangan.find(
    //   (item) => item?.properties?.feature_key === feature_key_params_genangan
    // );

    const input_parameter_content = (
      <main>
        {contourCreated ? (
          <form onSubmit={this.on_get_genangan_baru}>
            {/* ini2 untuk debugging  */}
            <button
              className={`${
                is_dem_visible ? "checklist_active" : "checklist_not"
              } margin_bottom`}
              onClick={this.toggle_dem_visible}
            />
            <p className="text_medium">radius_titik_dem (meter)</p>
            <input
              className="margin_bottom"
              type="number"
              onChange={this.on_change}
              min={5}
              value={radius_titik_dem}
              name="radius_titik_dem"
            />
            <p className="text_medium">ketinggian_DEM_terdekat (meter)</p>
            <input
              className="margin_bottom"
              type="number"
              onChange={this.on_change}
              min={5}
              value={tinggi_titik_dem_terdekat}
              name="tinggi_titik_dem_terdekat"
              disabled
            />
            <p className="text_medium">genangan_mdpl (meter)</p>
            <input
              className="margin_bottom"
              type="number"
              onChange={this.on_change}
              min={5}
              value={genangan_mdpl}
              name="genangan_mdpl"
            />

            {!loading_item_array.includes("get_DEM_debug") ? (
              <button
                onClick={this.on_rerun_debug_genangan}
                className="button background_blue margin_bottom"
              >
                get ulang data DEM
              </button>
            ) : (
              <button className="button background_grey margin_bottom">
                mengambil data DEM ..
              </button>
            )}

            {/* <p className="text_medium">index_max</p>
            <p className="text_small">
              Total iterasi yang akan dilakukan semakin besar angka semakin
              banyak iterasi yang dilakukan, luas area kemungkinan menjadi
              semakin luas.
            </p>
            <input
              className="margin_bottom"
              type="number"
              onChange={this.on_change}
              min={5}
              value={index_max}
              name="index_max"
            />
            <br />
            <p className="text_medium">radius_start_propagation (meter)</p>
            <p className="text_small">
              Radius perambatan (buffer dari suatu line) dalam meter untuk
              mendapatkan data elevasi tetangga.
            </p>
            <input
              className="margin_bottom"
              type="number"
              onChange={this.on_change}
              value={radius_start_propagation}
              name="radius_start_propagation"
            /> */}
            <br />

            {/* <div
          onClick={this.on_get_genangan}
          className="button background_blue margin_bottom"
        >
          Jalankan algoritma genangan by LiDAR
        </div> */}
          </form>
        ) : null}

        {!contourCreated ? (
          !loading_item_array.includes("get_contour_genangan") ? (
            feature_key_failed_genangan.includes(
              feature_key_selected_genangan
            ) ? (
              <div className="button background_red margin_bottom">
                Tidak ada data DEM...
              </div>
            ) : (
              <div
                onClick={this.on_get_genangan_baru}
                className="button background_blue margin_bottom"
              >
                Get data DEM
              </div>
            )
          ) : (
            <div className="button background_grey margin_bottom">
              Mengambil data DEM...
            </div>
          )
        ) : (
          <button onClick={this.on_start} className="button background_blue">
            Mulai jalankan perambatan
          </button>
        )}
      </main>
    );

    let content;
    //
    if (loading_item_array.includes("get_FloodPolygon")) {
      content = (
        <>
          <p className="text_medium">Menjalankan algoritma genangan...</p>
          <br />
          <br />
          <br />

          <p className="text_bold">Proses utama</p>
          <ProgressBar
            current_number={current_union}
            total_number={total_union}
            name="Proses utama"
          />
          <br />
          <br />
        </>
      );
    } else if (
      features_polygon_convex?.some(
        (data) =>
          data?.properties?.feature_key === feature_key_selected_genangan
      ) &&
      jalankan_ulang
    ) {
      content = (
        <>
          <p className="text_small">Status:</p>
          <p className="text_medium">Berhasil memuat genangan</p>
          <br />
          <p className="text_small">Cara pemanggilan:</p>
          {/* <p className="text_medium">
              {get_polygon_from_db
                ? "Dari database (algoritma sudah pernah dijalankan)"
                : jalankan_ulang
                ? "Fungsi perambatan genangan pertama kali dijalankan dan data poligon telah tersimpan"
                : "Fungsi perambatan baru saja dijalankan dan database telah menimpa/overwrite data poligon sebelumnya"}
            </p> */}
          <br />
          <br />

          <section>
            {!loading_item_array.includes("get_contour_genangan") ? (
              <div>
                <button
                  className={`${
                    is_dem_visible ? "checklist_active" : "checklist_not"
                  } margin_bottom`}
                  onClick={this.toggle_dem_visible}
                />
                <p className="text_medium">radius_titik_dem (meter)</p>
                <input
                  className="margin_bottom"
                  type="number"
                  onChange={this.on_change}
                  min={5}
                  value={radius_titik_dem}
                  name="radius_titik_dem"
                />
                <p className="text_medium">ketinggian_DEM_terdekat (meter)</p>
                <input
                  className="margin_bottom"
                  type="number"
                  onChange={this.on_change}
                  min={5}
                  value={tinggi_titik_dem_terdekat}
                  name="tinggi_titik_dem_terdekat"
                  disabled
                />
                <p className="text_medium">genangan_mdpl (meter)</p>
                <input
                  className="margin_bottom"
                  type="number"
                  onChange={this.on_change}
                  min={5}
                  value={genangan_mdpl}
                  name="genangan_mdpl"
                />
                <button
                  className="button margin_bottom background_blue"
                  onClick={this.on_overwrite_status_true}
                >
                  Generate ulang
                </button>
              </div>
            ) : (
              <button
                className="button margin_bottom background_grey"
                onClick={this.on_overwrite_status_true}
                disabled
              >
                Loading ...
              </button>
            )}

            <p className="text_small">
              Algoritma akan dijalankan ulang dan data poligon lama akan ditimpa
              dengan data baru.
            </p>
          </section>

          <br />
        </>
      );
    } else if (error_status) {
      content = (
        <>
          <p className="text_small">Status:</p>
          <p className="text_medium">Gagal menjalankan algoritma genangan</p>
          <br />
          <p className="text_small">Pesan error:</p>
          <p className="container_light background_red">{error_message}</p>
        </>
      );
    } else if (feature_key_params_genangan && genangan_cm_params_genangan) {
      content = <>{input_parameter_content}</>;
    }

    return (
      <main className="container_light background_white margin_top margin_bottom outline_black">
        {/* <p className="text_bold">Detail laporan</p> */}
        <table className="table table_width">
          <tbody>
            <tr>
              <td>Ketinggian genangan(cm)</td>
              <td>{genangan_cm_params_genangan}</td>
            </tr>
            <tr>
              <td>Kota/Kabupaten</td>
              <td>
                {feature_object_selected_genangan?.properties?.["KotaKabupaten"]
                  ? feature_object_selected_genangan?.properties?.[
                      "KotaKabupaten"
                    ]
                  : feature_object_selected_genangan?.properties?.[
                      "e8256e12-5bf2-4f06-a02e-99470199550e"
                    ]}
              </td>
            </tr>
            {feature_object_selected_genangan?.properties?.[
              "Sungai_Terdekat"
            ] ? (
              <tr>
                <td>Sungai_Terdekat</td>
                <td>
                  {
                    feature_object_selected_genangan?.properties?.[
                      "Sungai_Terdekat"
                    ]
                  }
                </td>
              </tr>
            ) : feature_object_selected_genangan?.properties?.[
                "3ed8c1e9-7d16-441b-83bb-4dbbc6182ad6"
              ] ? (
              <tr>
                <td>Lokasi_banjir</td>
                <td>
                  {
                    feature_object_selected_genangan?.properties?.[
                      "3ed8c1e9-7d16-441b-83bb-4dbbc6182ad6"
                    ]
                  }
                </td>
              </tr>
            ) : null}

            <tr>
              <td>Penyebab</td>
              <td>
                {feature_object_selected_genangan?.properties?.["Penyebab"] ??
                  feature_object_selected_genangan?.properties?.[
                    "739c1870-e656-4e96-98b8-71c3f76d0c56"
                  ] ??
                  ""}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <p className="text_bold">Detail hasil algoritma</p>
        <table className="table full_width">
          <tbody>
            <tr>
              <td>Perkiraan luas genangan (meter persegi)</td>
              <td>
                {data_genangan?.properties?.area_meter_square
                  ? data_genangan?.properties?.area_meter_square.toLocaleString(
                      "id-ID"
                    )
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Perkiraan luas genangan (hektar)</td>
              <td>
                {data_genangan?.properties?.area_hectare
                  ? data_genangan?.properties?.area_hectare.toLocaleString(
                      "id-ID"
                    )
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Tanggal dijalankan</td>
              <td>
                {data_genangan?.properties?.date_run
                  ? format_date_formal(data_genangan?.properties?.date_run)
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Waktu dijalankan</td>
              <td>
                {data_genangan?.properties?.time_run
                  ? data_genangan?.properties?.time_run
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
  sini: state.sini,
  genangan_reducer: state.genangan_reducer,
  loading_reducer: state.loading_reducer,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  get_genangan,
  get_contour_genangan,
  set_value_genangan,
  push_value_genangan,
  genangan_update,
  loading_rambatan,
  get_DEM_debug,
  recursiveFloodPolygon,
  not_recursiveFloodPolygon,
  find_polygon_genangan,
})(STATUS_GET_GENANGAN_CONTENT);
