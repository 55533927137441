/*LIBRARY*/
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { ReactToPrint } from "react-to-print";
import { CircularProgress } from "@mui/material";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import HBU_MANUAL from "./HBU_MANUAL";
import HBU_SUMMARY_MANUAL from "./HBU_SUMMARY_MANUAL";
import ProgressBar from "../common_spinner/ProgressBar";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import PRINT_HBU_MANUAL from "./PRINT_HBU_MANUAL";
import UJI_FINANSIAL from "./UJI_FINANSIAL";

/*REDUX*/
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import {
  set_value_hbu,
  update_hbu_result_object,
  set_hbu_reducer_into_intitial,
} from "../../App/actions/hbu_actions";
import {
  calc_fs,
  conclude_hbu_legal,
  conclude_hbu_fisik_tanah_kosong,
  set_calc_fs,
} from "../../App/actions/calc_fs";

/*PICTURE*/

/*FUNCTION*/
import { get_value_cell } from "../../App/validation/calculateExcelFormula";
import { compress_sini } from "../../App/validation/compress_sini";

/*DATA*/
import PEDOMAN_HBU from "../../Data/PEDOMAN_HBU.csv";
import INPUT_DATA_OBJECT from "./INPUT_DATA_OBJECT";

/*CONST*/

class PARENT_HBU_MANUAL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_message: "",
      is_chat_ai_show: false,
      modal_calculation: false,
      is_print: false,
      modal_error_no_data: false,
    };
    this.printRef = createRef();
  }

  componentDidMount() {
    this.init_csv();
    this.generate_init_data();
  }

  componentDidUpdate(prevProps) {
    //post data after calculation done
    const rows_filtered_3_after = this.props.hbu_reducer.rows_filtered_3;
    const calc_update_before = prevProps.hbu_reducer.calc_update;
    const calc_update_after = this.props.hbu_reducer.calc_update;
    if (
      calc_update_before !== calc_update_after &&
      rows_filtered_3_after?.length > 0 &&
      calc_update_after !== 0
    ) {
      const { request_id_active } = this.props.sini;
      const body = {
        request_id: request_id_active,
        hbu_result_object: {
          ...this.props.hbu_reducer,
          calc_update: 0,
          sini_hbu_update: 0,
        },
      };
      this.props.update_hbu_result_object(body);
    }

    //calc when get_sini_data done
    const sini_hbu_update_before = prevProps.hbu_reducer.sini_hbu_update;
    const sini_hbu_update_after = this.props.hbu_reducer.sini_hbu_update;
    if (
      sini_hbu_update_before !== sini_hbu_update_after &&
      sini_hbu_update_after !== 0
    ) {
      this.on_run(); //make this function called after 1
    }
  }

  componentWillUnmount() {
    this.props.set_hbu_reducer_into_intitial();
  }

  toggle_error_no_data = () => {
    this.setState({
      modal_error_no_data: !this.state.modal_error_no_data,
    });
  };

  init_csv = async () => {
    try {
      const response = await fetch(PEDOMAN_HBU);
      const text = await response.text();
      const rows = text
        .split("\n")
        .map((row) => row.trim())
        .filter((row) => row);
      if (rows.length < 2) return;
      const headers = rows[0].split(";").map((header) => header.trim());
      const jsonData = rows.slice(1).map((row) => {
        const values = row.split(";").map((value) => value.trim());
        return headers.reduce((acc, header, index) => {
          acc[header] = values[index] || "";
          return acc;
        }, {});
      });
      this.props.set_value_hbu({
        key: "headers",
        value: headers,
      });
      this.props.set_value_hbu({
        key: "rows",
        value: jsonData,
      });
    } catch (error) { }
  };

  toggle_modal_calculation = () => {
    this.setState({
      modal_calculation: !this.state.modal_calculation,
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show,
    });
  };

  generate_init_data = () => {
    const { worksheet } = this.props;

    // get value dari worksheet "Data Objek"
    const hbu_type =
      get_value_cell("B5", worksheet, "Data Objek") ===
        "DATA OBJEK HBU BANGUNAN"
        ? "HBU Terbangun"
        : "HBU Tanah Kosong";
    const first_year = +get_value_cell("D18", worksheet, "Data Objek");
    const luas_tanah = +get_value_cell("D12", worksheet, "Data Objek");
    const LLBE = +get_value_cell("D24", worksheet, "Data Objek") ||
      +get_value_cell("D63", worksheet, "Uji HBU - Physical") || 0
    const panjang_tanah = +get_value_cell("D14", worksheet, "Data Objek");
    const lebar_tanah = +get_value_cell("D16", worksheet, "Data Objek");
    const capex_tanah_input = +get_value_cell("D26", worksheet, "Data Objek");

    // get value dari worksheet "Uji HBU - Physical"
    const tahun_bangun = +get_value_cell("D39", worksheet, "Uji HBU - Physical");
    const LDBE = +get_value_cell("D51", worksheet, "Uji HBU - Physical");
    const tahun_peraturan_dikeluarkan = +get_value_cell(
      "H9",
      worksheet,
      "Uji HBU - Physical"
    );
    const IMB = +get_value_cell("E7", worksheet, "Uji HBU - Physical");
    const lebar_jalan_depan = +get_value_cell("D20", worksheet, "Data Objek");

    const ratio_tanah = (panjang_tanah / lebar_tanah).toFixed(1);

    // simpan ke reducer
    this.props.set_value_hbu({
      key: "hbu_type",
      value: hbu_type,
    });
    this.props.set_value_hbu({
      key: "lebar_jalan_depan",
      value: lebar_jalan_depan,
    });
    this.props.set_value_hbu({
      key: "first_year",
      value: +first_year || 0,
    });
    this.props.set_value_hbu({
      key: "tahun_peraturan_dikeluarkan",
      value: +tahun_peraturan_dikeluarkan || 0,
    });
    this.props.set_value_hbu({
      key: "IMB",
      value: +IMB || 0,
    });
    this.props.set_value_hbu({
      key: "luas_tanah",
      value: +luas_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "LLBE",
      value: +LLBE || 0,
    });
    this.props.set_value_hbu({
      key: "LDBE",
      value: +LDBE || 0,
    });
    this.props.set_value_hbu({
      key: "panjang_tanah",
      value: +panjang_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "lebar_tanah",
      value: +lebar_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "ratio_tanah",
      value: +ratio_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "capex_tanah_input",
      value: +capex_tanah_input || 0,
    });
    this.props.set_value_hbu({
      key: "tahun_bangun",
      value: +tahun_bangun || 0,
    });
  };

  on_run = () => {
    // const {
    //   rows,
    //   luas_tanah,
    //   percent_ratio_tolerance,
    //   project_duration_years,
    //   discount_rate_1_percent,
    //   revenue_growth_percent,
    //   opex_growth_percent,
    //   working_capital_idr,
    //   discount_rate_2_percent,
    //   economic_life_years,
    //   tax_percent,
    //   capex_tanah_input,
    //   skema_terminal_value,
    //   hbu_type,
    //   LDBE,
    //   LLBE,
    //   tahun_bangun,
    //   IMB,
    //   tahun_peraturan_dikeluarkan,
    //   panjang_tanah,
    //   lebar_tanah
    // } = this.props.hbu_reducer;
    // let { ratio_tanah } = this.props.hbu_reducer;

    const { ai_parent_detail_list, request_id_active } = this.props?.sini;
    const { worksheet } = this.props;
    let sini_object = {};

    const {
      rows,
      percent_ratio_tolerance,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      economic_life_years,
      tax_percent,
      skema_terminal_value,
      // lebar_jalan_depan,
    } = this.props.hbu_reducer;

    let luas_tanah = +get_value_cell("D12", worksheet, "Data Objek");
    let capex_tanah_input = +get_value_cell("D26", worksheet, "Data Objek");
    let hbu_type =
      get_value_cell("B5", worksheet, "Data Objek") ===
        "DATA OBJEK HBU BANGUNAN"
        ? "HBU Terbangun"
        : "HBU Tanah Kosong";
    let LDBE = +get_value_cell("D51", worksheet, "Uji HBU - Physical");
    let LLBE =
      +get_value_cell("D24", worksheet, "Data Objek") ||
      +get_value_cell("D63", worksheet, "Uji HBU - Physical") ||
      0;
    let tahun_bangun = +get_value_cell("D39", worksheet, "Uji HBU - Physical");
    let IMB = get_value_cell("E7", worksheet, "Uji HBU - Physical");
    let tahun_peraturan_dikeluarkan = +get_value_cell(
      "H9",
      worksheet,
      "Uji HBU - Physical"
    );
    let panjang_tanah = +get_value_cell("D14", worksheet, "Data Objek");
    let lebar_tanah = +get_value_cell("D16", worksheet, "Data Objek");
    let panjang_bangunan = +get_value_cell(
      "D45",
      worksheet,
      "Uji HBU - Physical"
    );
    let lebar_bangunan = +get_value_cell("D47", worksheet, "Uji HBU - Physical");
    let lebar_jalan_depan = +get_value_cell("D20", worksheet, "Data Objek");
    let LDB_excel = 0;
    let LLB_excel = 0;
    let JLB_excel = 0;

    let ratio_tanah = 0;
    if (hbu_type === "HBU Terbangun") {
      ratio_tanah = (panjang_bangunan / lebar_bangunan).toFixed(1);
    } else {
      LDB_excel = +get_value_cell("D29", worksheet, "Data Objek");
      LLB_excel = +get_value_cell("D31", worksheet, "Data Objek");
      JLB_excel = +get_value_cell("D33", worksheet, "Data Objek");
      ratio_tanah = (panjang_tanah / lebar_tanah).toFixed(1);
    }

    this.props.set_value_hbu({
      key: "IMB",
      value: +IMB || 0,
    });
    this.props.set_value_hbu({
      key: "luas_tanah",
      value: +luas_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "LLBE",
      value: +LLBE || 0,
    });
    this.props.set_value_hbu({
      key: "LDBE",
      value: +LDBE || 0,
    });
    this.props.set_value_hbu({
      key: "panjang_tanah",
      value: +panjang_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "lebar_tanah",
      value: +lebar_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "ratio_tanah",
      value: +ratio_tanah || 0,
    });
    this.props.set_value_hbu({
      key: "capex_tanah_input",
      value: +capex_tanah_input || 0,
    });
    this.props.set_value_hbu({
      key: "tahun_bangun",
      value: +tahun_bangun || 0,
    });
    this.props.set_value_hbu({
      key: "lebar_bangunan",
      value: +lebar_bangunan || 0,
    });
    this.props.set_value_hbu({
      key: "panjang_bangunan",
      value: +panjang_bangunan || 0,
    });
    this.props.set_value_hbu({
      key: "lebar_jalan_depan",
      value: +lebar_jalan_depan || 0,
    });

    if (
      !+luas_tanah ||
      !+ratio_tanah ||
      (hbu_type === "HBU Terbangun" && !LLBE)
    ) {
      this.setState({
        modal_error_no_data: true,
      });
      return;
    }

    //sini_object dengan request_id_active
    if (request_id_active) {
      sini_object = ai_parent_detail_list?.find(
        (item) => item.request_id === request_id_active
      );
    }
    const poi_tipe_2_sekitar_data_objek = sini_object?.poi_resume_2;

    // merangkum hbu legal
    const peruntukan_zoning = get_value_cell(
      "E5",
      worksheet,
      "Uji HBU - Legal"
    );

    const dokumen_peraturan_eksisting = [
      get_value_cell("E23", worksheet, "Uji HBU - Legal"),
      get_value_cell("E25", worksheet, "Uji HBU - Legal"),
      get_value_cell("H23", worksheet, "Uji HBU - Legal"),
      get_value_cell("H25", worksheet, "Uji HBU - Legal"),
    ].filter((item) => item);

    let KLB, KDB, JLB, GSB, KDH, NAMOBJ, peruntukan, is_optimal;

    if (sini_object?.rdtr_one) {
      const rdtr = JSON.parse(
        JSON.stringify(sini_object?.rdtr_one?.properties)?.replaceAll(
          '"null"',
          "null"
        )
      );

      KDB = +rdtr?.KDB;
      KLB = +rdtr?.KLB;
      KDH = +rdtr?.KDH;
      NAMOBJ = rdtr?.NAMOBJ;

    }

    if (hbu_type === "HBU Terbangun") {
      KDB = KDB || +get_value_cell("F50", worksheet, "Uji HBU - Legal");
      if (!KDB) KDB = (LDBE / luas_tanah) * 100;
      KLB = KLB || +get_value_cell("F55", worksheet, "Uji HBU - Legal");
      if (!KLB) KLB = LLBE / luas_tanah;
    } else {
      KDB = KDB || +get_value_cell("F35", worksheet, "Uji HBU - Legal") || 60;
      KLB = KLB || +get_value_cell("F40", worksheet, "Uji HBU - Legal") || 1.2;
    }

    const LDB = (KDB / 100) * luas_tanah;
    const LLB = KLB * luas_tanah;
    if (hbu_type === "HBU Terbangun") {
      JLB = Math.ceil(LLB / LDBE);
    } else {
      JLB = Math.ceil(LLB / LDB);
    }

    peruntukan = NAMOBJ || peruntukan_zoning;
    GSB = +get_value_cell("E39", worksheet, "Uji HBU - Legal");

    is_optimal = false;
    if (LLBE >= LLB) is_optimal = true;

    if (hbu_type === "HBU Terbangun") {
      this.props.set_value_hbu({
        key: "is_optimal",
        value: is_optimal,
      });
    } else {
      this.props.set_value_hbu({
        key: "LDB_excel",
        value: +LDB_excel || 0,
      });
      this.props.set_value_hbu({
        key: "LLB_excel",
        value: +LLB_excel || 0,
      });
      this.props.set_value_hbu({
        key: "JLB_excel",
        value: +JLB_excel || 0,
      });
    }

    this.props.set_value_hbu({
      key: "peruntukan",
      value: peruntukan,
    });

    this.props.set_value_hbu({
      key: "KLB",
      value: KLB,
    });

    this.props.set_value_hbu({
      key: "KDB",
      value: KDB,
    });

    this.props.set_value_hbu({
      key: "LDB",
      value: LDB,
    });

    this.props.set_value_hbu({
      key: "LLB",
      value: LLB,
    });

    this.props.set_value_hbu({
      key: "JLB",
      value: JLB,
    });

    this.props.set_value_hbu({
      key: "GSB",
      value: GSB,
    });

    this.props.set_value_hbu({
      key: "KDH",
      value: KDH,
    });


    // ------------------- kesimpulan hbu legal ----------------------------
    const kesimpulan_uji_legal = conclude_hbu_legal({
      LDBE,
      LLBE,
      KDB,
      KLB,
      LLB,
      LDB,
      JLB,
      is_optimal,
      peruntukan,
      dokumen_peraturan_eksisting,
      luas_tanah,
      hbu_type,
      tahun_bangun,
      IMB,
      tahun_peraturan_dikeluarkan,
    });

    this.props.set_value_hbu({
      key: "kesimpulan_uji_legal",
      value: kesimpulan_uji_legal,
    });

    // -------------------  hbu fisik bangunan ----------------------------

    // const jumlah_lantai = get_value_cell(
    //   "D37",
    //   worksheet,
    //   "Uji HBU - Physical"
    // );
    // const kondisi_bangunan = get_value_cell(
    //   "D43",
    //   worksheet,
    //   "Uji HBU - Physical"
    // );

    // const kesimpulan_hbu_fisik_bangunan = conclude_hbu_fisik_bangunan({
    //   luas_bangunan,
    //   tahun_dibangun,
    //   jumlah_lantai,
    //   kondisi_bangunan,
    // });

    // this.props.set_value_hbu({
    //   key: "kesimpulan_hbu_fisik_bangunan",
    //   value: kesimpulan_hbu_fisik_bangunan,
    // });

    // -------------------  hbu fisik tanah kosong ----------------------------

    const bentuk_tanah = get_value_cell("D21", worksheet, "Uji HBU - Physical");
    const lokasi_dalam_market_area = get_value_cell(
      "D19",
      worksheet,
      "Uji HBU - Physical"
    );
    const transportasi_umum = get_value_cell(
      "D15",
      worksheet,
      "Uji HBU - Physical"
    );
    const kemungkinan_bencana = get_value_cell(
      "D27",
      worksheet,
      "Uji HBU - Physical"
    );

    const kesimpulan_hbu_fisik_tanah_kosong = conclude_hbu_fisik_tanah_kosong({
      luas_tanah,
      bentuk_tanah,
      lokasi_dalam_market_area,
      transportasi_umum,
      kemungkinan_bencana,
    });

    this.props.set_value_hbu({
      key: "kesimpulan_hbu_fisik_tanah_kosong",
      value: kesimpulan_hbu_fisik_tanah_kosong,
    });

    // ----------------- Calculation ------------------------

    // Perhitungan Rangkuman finansial setiap alternatif
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = +landvalue_one?.properties?.["HARGA"];

    const calculated_rows = calc_fs({
      request_id: request_id_active,
      luas_tanah,
      luas_bangunan: hbu_type === "HBU Terbangun" ? LLBE : (LLB_excel || LLB),
      rows,
      project_duration_years,
      discount_rate_1_percent,
      revenue_growth_percent,
      opex_growth_percent,
      working_capital_idr,
      discount_rate_2_percent,
      economic_life_years,
      tax_percent,
      landvalue_value,
      capex_tanah_input,
      skema_terminal_value,
    });

    this.props.set_value_hbu({
      key: "calculated_rows",
      value: calculated_rows,
    });

    // ----------------- FILTERING ------------------------

    // =========================================================================
    let real_filtered_rows = [];

    //seleksi 1    //"pendukung dan resisten (supply demand)", 
    let rows_filtered_1 = [];
    let rows_rejected_1 = [];

    calculated_rows?.forEach((item) => {
      // belum fix, "SEWA, JUAL" masih dianggurin & nanti filter bakal diubah menggunakan jarak poi
      let total_poi = 0;
      item?.POI_PENDUKUNG?.split(/(?<!SEWA),(?!JUAL)/) //split (,) menjadi array, kecuali "SEWA, JUAL"
        ?.forEach((name) => {
          const jenis = name?.trim()?.replace(/['"]/, "");
          const jumlah_poi =
            +poi_tipe_2_sekitar_data_objek?.find((type) => type?.name === jenis)
              ?.number || 0;
          total_poi += jumlah_poi;
        });

      let total_poi_resisten = 0;
      item?.POI_RESISTEN_1KM?.split(/(?<!SEWA),(?!JUAL)/) //split (,) menjadi array, kecuali "SEWA, JUAL"
        ?.forEach((name) => {
          const jenis = name?.trim();
          const jumlah_poi_resisten =
            +poi_tipe_2_sekitar_data_objek?.find((type) => type?.name === jenis)
              ?.number || 0;
          total_poi_resisten += jumlah_poi_resisten;
        });

      if (
        total_poi >= +item.JML_MIN_POI &&
        total_poi_resisten <= +item.MAKS_RESISTEN
      ) {
        rows_filtered_1.push(item);
      } else {
        rows_rejected_1.push(item);
      }
    });

    real_filtered_rows = rows_filtered_1;

    this.props.set_value_hbu({
      key: "rows_filtered_1",
      value: rows_filtered_1,
    });

    this.props.set_value_hbu({
      key: "rows_rejected_1",
      value: rows_rejected_1,
    });


    // =========================================================================
    //seleksi 2    //"MIN_LT", "MAX_LT",
    let rows_rejected_2 = []
    let rows_filtered_2 = real_filtered_rows.filter(
      (item) => {
        const is_pass = ((+luas_tanah >= +item.MIN_LT) && (+luas_tanah <= +item.MAX_LT))
        if (!is_pass) rows_rejected_2.push(item)
        return is_pass
      }
    );

    if (rows_filtered_2?.length < 3) {
      this.props.set_value_hbu({
        key: "is_force_pass_2",
        value: true,
      });
    } else {
      real_filtered_rows = [...rows_filtered_2];
    }

    this.props.set_value_hbu({
      key: "rows_filtered_2",
      value: rows_filtered_2,
    });
    this.props.set_value_hbu({
      key: "rows_rejected_2",
      value: rows_rejected_2,
    });

    // =========================================================================
    //seleksi 3    //"MIN_LDB", "MAX_LDB",

    let rows_rejected_3 = []
    let rows_filtered_3 = real_filtered_rows.filter(
      (item) => {
        const is_pass_1 = (((+LDB_excel || +LDB) >= +item.MIN_LDB) && ((+LDB_excel || +LDB) <= +item.MAX_LDB));
        const is_pass_2 = (((+JLB_excel || +JLB) >= +item?.MIN_LANTAI) && ((+JLB_excel || +JLB) <= +item?.MAX_LANTAI));
        if (!is_pass_1 && !is_pass_2) rows_rejected_3.push()
        return is_pass_1 || is_pass_2
      }
    );

    if (rows_filtered_3?.length < 3) {
      this.props.set_value_hbu({
        key: "is_force_pass_3",
        value: true,
      });
    } else {
      real_filtered_rows = rows_filtered_3
    }

    this.props.set_value_hbu({
      key: "rows_filtered_3",
      value: rows_filtered_3,
    });
    this.props.set_value_hbu({
      key: "rows_rejected_3",
      value: rows_rejected_3,
    });

    // =========================================================================
    //seleksi 4    //"MIN_LEBAR_JALAN",
    let rows_rejected_4 = []
    let rows_filtered_4 = real_filtered_rows.filter(
      (item) => {
        const is_pass = (parseFloat(lebar_jalan_depan) >= parseFloat(item.MIN_LEBAR_JALAN))
        if (!is_pass) rows_rejected_4.push(item)
        return is_pass
      }
    );

    if (rows_filtered_4?.length < 3) {
      this.props.set_value_hbu({
        key: "is_force_pass_4",
        value: true,
      });
    } else {
      real_filtered_rows = rows_filtered_4
    }

    this.props.set_value_hbu({
      key: "rows_filtered_4",
      value: rows_filtered_4,
    });
    this.props.set_value_hbu({
      key: "rows_rejected_4",
      value: rows_rejected_4,
    });

    // =========================================================================
    //seleksi 5    //"MIN_RASIO", "MAX_RASIO",
    const min_dec = 1 - parseFloat(percent_ratio_tolerance) / 100;
    const max_dec = 1 + parseFloat(percent_ratio_tolerance) / 100;
    let rows_rejected_5 = []
    let rows_filtered_5 = real_filtered_rows.filter(
      (item) => {
        const is_pass = ratio_tanah >= parseFloat(item.MIN_RASIO * min_dec) &&
          ratio_tanah <= parseFloat(item.MAX_RASIO * max_dec)
        if (!is_pass) rows_rejected_5.push(item)
        return is_pass
      }
    );

    if (rows_filtered_5?.length < 3) {
      this.props.set_value_hbu({
        key: "is_force_pass_5",
        value: true,
      });
    } else {
      real_filtered_rows = rows_filtered_5
    }
    
    this.props.set_value_hbu({
      key: "real_filtered_rows",
      value: real_filtered_rows,
    });

    this.props.set_value_hbu({
      key: "rows_filtered_5",
      value: rows_filtered_5,
    });

    this.props.set_value_hbu({
      key: "rows_filtered_5",
      value: rows_filtered_5,
    });

    this.props.set_value_hbu({
      key: "rows_rejected_5",
      value: rows_rejected_5,
    });
    // ===========================================================================

    this.props.set_calc_fs();

  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, request_id_active } = this.props.sini;
    // sini_object dengan request_id_active
    const data = ai_parent_detail_list?.find(
      (item) => item?.request_id === request_id_active
    );

    let sini_object = compress_sini(data); // Copy object agar tidak merusak original

    const {
      luas_tanah,
      luas_bangunan,
      ratio_tanah,
      percent_ratio_tolerance,
      data_bangun,
      kesimpulan_tanah_kosong,
      kesimpulan_bangunan,
      kesimpulan_hbu_fisik_tanah_kosong,
      kesimpulan_hbu_fisik_bangunan,
      rows_filtered_3,
    } = this.props.hbu_reducer;

    const data_sheet = JSON.stringify({
      luas_tanah,
      luas_bangunan,
      ratio_tanah,
      percent_ratio_tolerance,
      data_bangun,
      kesimpulan_tanah_kosong,
      kesimpulan_bangunan,
      kesimpulan_hbu_fisik_tanah_kosong,
      kesimpulan_hbu_fisik_bangunan,
      perhitungan_alternatif: rows_filtered_3,
    });
    const body = {
      ai_hbu_summary_object: data_sheet,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };

    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  render() {
    //local storage

    //local state
    const {
      is_chat_ai_show,
      modal_calculation,
      user_message,
      modal_error_no_data,
    } = this.state;

    //global props
    const {
      ai_parent_detail_list,
      total_step,
      current_step,
      current_topic_loading,
      ai_hbu_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
      request_id_active,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const sini_object = ai_parent_detail_list?.find(
      (item) => item?.request_id === request_id_active
    );
    const summary_sini = sini_object?.summary_sini || "";

    // data properties
    const properties = sini_object?.demography_one?.properties || {};
    // const kelurahan = properties?.["DESA"];

    // let rentang_usia_dominan = properties && Object.entries(properties);
    // rentang_usia_dominan = rentang_usia_dominan
    //   .filter((item) => item[0].startsWith("USIA"))
    //   .sort(([, a], [, b]) => b - a)?.[0]?.[0];

    // const jumlah_penduduk =
    //   idFormatingNumber(
    //     sini_object?.demography_one?.properties?.["JUMLAH PENDUDUK"]
    //   ) || 0;

    // const potensi_bencana = {
    //   banjir: sini_object?.flood_one?.properties?.Kelas || "",
    //   tanah_longsor: sini_object?.landslide_one?.properties?.Kelas || "",
    //   tsunami: sini_object?.landslide_one?.properties?.Kelas || "",
    // };

    // const retail =
    //   sini_object?.poi_resume_1?.find((item) => item?.name === "RETAIL") || {};

    // const tempat_makan_minum =
    //   sini_object?.poi_resume_1?.find(
    //     (item) => item?.name === "TEMPAT MAKAN/MINUM"
    //   ) || {};

    // let poi_resume_1_dominan =
    //   sini_object && Object.entries(sini_object?.poi_resume_1);
    // poi_resume_1_dominan = poi_resume_1_dominan
    //   ?.sort(([, a], [, b]) => b?.number - a?.number)
    //   ?.slice(0, 3);

    // const pekerjaan = {
    //   belum_tidak_bekerja: sini_object["BELUM/TIDAK BEKERJA"],
    //   guru: sini_object["GURU"],
    //   nelayan: sini_object["NELAYAN"],
    //   pekerjaan_lain: sini_object["PEKERJAAN LAINNYA"],
    //   pelajar_dan_mahasiswa: sini_object["PELAJAR DAN MAHASISWA"],
    //   pengacara: sini_object["PENGACARA"],
    //   pensiunan: sini_object["PENSIUNAN"],
    //   perawat: sini_object["PERAWAT"],
    //   perdagangan: sini_object["PERDAGANGAN"],
    //   wiraswasta: sini_object["WIRASWASTA"],
    // };

    // const fasilitas_kesehatan = ai_parent_detail_list?.poi_resume_1.find(
    //   (item) => item.name === "FASILITAS KESEHATAN"
    // ).number;

    // const fasilitas_pendidikan = ai_parent_detail_list?.poi_resume_1.find(
    //   (item) => item.name === "FASILITAS PENDIDIKAN"
    // ).number;

    // const pekerjaan_dominan = Object.entries(pekerjaan)
    //   .sort(([, a], [, b]) => b - a)[0][0]
    //   .split("_")
    //   .join(" ");

    //modal

    const modal_calculation_content = modal_calculation && (
      <Modal
        modalSize="large"
        isOpen={modal_calculation}
        onClose={this.toggle_modal_calculation}
      >
        <div className="box-body" id="box-body">
          <HBU_MANUAL {...this.props} on_run={this.on_run} />
        </div>
      </Modal>
    );

    const modal_error_no_data_content = modal_error_no_data && (
      <Modal
        modalSize="small"
        isOpen={modal_error_no_data}
        onClose={this.toggle_error_no_data}
      >
        <div className="box-body" id="box-body">
          {/* Data objek belum di isi, mohon isi di sheet bagian "DATA OBJEK" */}
          <INPUT_DATA_OBJECT {...this.props} />
        </div>
      </Modal>
    );

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button background_blue"
                style={{
                  alignSelf: "flex-start",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );

    const { map_object_excel } = this.props.layer;
    const summary_result = ai_hbu_summary_object?.summary_result;

    return (
      <div
        style={{
          display: "flex",
          gap: "10px",
          height: "calc(100vh - 75px)",
        }}
      >
        {modal_error_no_data_content}
        {modal_calculation_content}
        <section style={{ width: "50%" }}>
          <div className="hidden">
            <PRINT_HBU_MANUAL
              ref={(el) => (this.printRef = el)}
              summary_result={summary_result}
              properties={properties}
              map_object_excel={map_object_excel}
              is_print={this.state.is_print}
              rows_filtered_3={this.props.hbu_reducer.rows_filtered_3}
              kesimpulan_tanah_kosong={
                this.props.hbu_reducer.kesimpulan_tanah_kosong
              }
              kesimpulan_hbu_fisik_tanah_kosong={
                this.props.hbu_reducer.kesimpulan_hbu_fisik_tanah_kosong
              }
              kesimpulan_hbu_fisik_bangunan={
                this.props.hbu_reducer.kesimpulan_hbu_fisik_bangunan
              }
              ai_parent_detail_list={this.props.sini.ai_parent_detail_list}
              luas_tanah={this.props.hbu_reducer.luas_tanah}
              luas_bangunan={this.props.hbu_reducer.luas_bangunan}
              summary_sini={summary_sini}
            />
          </div>

          <div>
            <button
              onClick={this.toggle_modal_calculation}
              disabled={summary_sini ? false : true}
              className={`button ${summary_sini ? "background_black" : "background_grey"
                }  margin_bottom margin_right`}
              style={{ cursor: `${summary_sini ? "pointer" : "auto"}` }}
            >
              Detail
            </button>
            <button
              disabled={summary_sini ? false : true}
              className={`button ${summary_sini ? "background_black" : "background_grey"
                }  margin_bottom margin_right`}
              style={{ cursor: `${summary_sini ? "pointer" : "auto"}` }}
              onClick={this.on_run}
            >
              Calculate
            </button>
            <ReactToPrint
              trigger={() => (
                <button
                  className={`button ${summary_sini ? "background_blue" : "background_grey"
                    } margin_bottom margin_right`}
                  style={{ cursor: `${summary_sini ? "pointer" : "auto"}` }}
                >
                  Print PDF
                </button>
              )}
              onAfterPrint={() => {
                this.setState({
                  is_print: true,
                });
              }}
              content={() => {
                return this.printRef;
              }}
              documentTitle="HBU SUMMARY"
            />
          </div>

          <div
            className="scroll_y_auto"
            style={{ height: "calc(100vh - 109px)" }}
          >
            <HBU_SUMMARY_MANUAL />
          </div>
        </section>

        <section
          style={{
            display: "flex",
            height: "100%",
            width: "50%",
            gap: "15px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ height: "50%" }}>
              <MAP_EXCEL />
            </div>

            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{
                height: "50%",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2
                className="text_bold margin_bottom"
                style={{ fontSize: "1.2rem" }}
              >
                Summary SINI
              </h2>
              <hr />
              {summary_sini &&
                !loading_item_array.includes("get_ai_parent_object") ? (
                <MARKDOWN_RENDERER markdownContent={summary_sini} />
              ) : (
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "1.2rem",
                  }}
                >
                  <CircularProgress />
                  Memuat...
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              width: "300px",
              overflowY: "auto",
            }}
          >
            <PARENT_SINI from="ai_hbu" />
          </div>
        </section>

        <section
          style={{
            visibility: is_chat_ai_show ? "visible" : "hidden",
            width: is_chat_ai_show ? "300px" : "0px",
            flexShrink: 0,
            flexGrow: 0,
            height: "100%",
          }}
        >
          {chat_content}
        </section>

        <button
          className="floating_button circle_button center_perfect"
          onClick={this.toggle_chat_ai}
        >
          <div className="flex flex_col">
            <span className="w_full flex justify_center">
              <QuestionAnswerIcon />
            </span>
            <label className="font_10 pointer">Chat AI</label>
          </div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {
  set_calc_fs,
  set_value_hbu,
  calc_fs,
  push_ai_sheet_chat,
  onCalcFs: UJI_FINANSIAL.on_calc_fs,
  update_hbu_result_object,
  set_hbu_reducer_into_intitial,
})(PARENT_HBU_MANUAL);
