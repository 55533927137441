
import React, { Component } from 'react'
import {
  edit_cell,
} from "../../App/actions/sheetAction";
import { connect } from 'react-redux';
import { columnToNumber, get_value_cell, splitExcelReference } from '../../App/validation/calculateExcelFormula';
import { set_value_hbu } from '../../App/actions/hbu_actions';

const atribut_data_objek = {
  alamat: {
    sheet: "Data Objek",
    cell: "D8",
    label: "Alamat",
    default: "",
    type: "textarea",
    is_optional: false
  },
  panjang_tanah: {
    sheet: "Data Objek",
    cell: "D14",
    label: "Panjang Tanah (m)",
    default: 0,
    is_optional: false
  },
  lebar_tanah: {
    sheet: "Data Objek",
    cell: "D16",
    label: "Lebar Tanah (m)",
    default: 0,
    is_optional: false
  },
  luas_tanah: {
    sheet: "Data Objek",
    cell: "D12",
    label: "Luas Tanah (m2)",
    default: 0,
    is_optional: false
  },
  lebar_jalan_depan: {
    sheet: "Data Objek",
    cell: "D20",
    label: "Lebar Jalan Depan (m)",
    default: 8,
    is_optional: false
  },
  LDBE: {
    hbu_type: "HBU Terbangun",
    sheet: "Uji HBU - Physical",
    cell: "D51",
    label: "Luas Dasar Bangunan Eksisting (LDBE)",
    default: 0,
    is_optional: false
  },
  LLBE: {
    hbu_type: "HBU Terbangun",
    sheet: "Data Objek",
    cell: "D24",
    label: "Luas Lantai Bangunan Eksisting (LLBE)",
    default: 0,
    is_optional: false
  },
  panjang_bangunan: {
    hbu_type: "HBU Terbangun",
    sheet: "Uji HBU - Physical",
    cell: "D45",
    label: "Panjang Bangunan (m)",
    default: 0,
    is_optional: false
  },
  lebar_bangunan: {
    hbu_type: "HBU Terbangun",
    sheet: "Uji HBU - Physical",
    cell: "D47",
    label: "Lebar Bangunan (m)",
    default: 0,
    is_optional: false
  },
  LDB: {
    hbu_type: "HBU Tanah Kosong",
    sheet: "Data Objek",
    cell: "D29",
    label: "Luas Dasar Bangunan (LDB)",
    default: 0,
    is_optional: true
  },
  LLB: {
    hbu_type: "HBU Tanah Kosong",
    sheet: "Data Objek",
    cell: "D31",
    label: "Luas Lantai Bangunan (LLB)",
    default: 0,
    is_optional: true
  },
  JLB: {
    hbu_type: "HBU Tanah Kosong",
    sheet: "Data Objek",
    cell: "D33",
    label: "Jumlah Lantai Bangunan (JLB)",
    default: 0,
    is_optional: true
  },
}

const atribut_data_rdtr = {
  // peruntukan: {
  //   sheet: "Uji HBU - Legal",
  //   cell: "E5",
  //   label: "Peruntukan (Zoning)",
  //   type: "select",
  //   options: [
  //     "Perkantoran",
  //     "Perdagangan dan Jasa",
  //     "Perumahan",
  //     "Pariwisata",
  //     "Sarana Pelayanan Umum",
  //     "Transportasi",
  //     "Taman Kota",
  //     "Taman Wisata Alam",
  //     "Jalur Hijau",
  //     "Hutan Produksi",
  //     "Cagar Alam",
  //     "Cagar Budaya",
  //     "Perikanan dan Budidaya",
  //     "Pertanian dan Perkebunan",
  //     "Kawasan Industri",
  //     "Pemakaman",
  //     "Pembangkit Tenaga Listrik",
  //     "Pertahanan dan Keamanan",
  //   ],
  //   is_optional: true
  // },
  KDB: {
    sheet: "Uji HBU - Legal",
    cell: "F50",
    label: "Koefisien Dasar Bangunan (KDB)",
    default: 60,
    is_optional: false
  },
  KLB: {
    sheet: "Uji HBU - Legal",
    cell: "F55",
    label: "Koefisien Lantai Bangunan (KLB)",
    default: 1.2,
    is_optional: false
  },
  // tahun_peraturan_dikeluarkan: {
  //   sheet: "Uji HBU - Legal",
  //   cell: "H9",
  //   label: "Tahun Peraturan Dikeluarkan",
  //   default: 0,
  //   is_optional: false
  // },
  IMB: {
    sheet: "Uji HBU - Legal",
    cell: "E7",
    label: "IMB",
    type: "select",
    options: ["Tidak Ada", "Ada"],
    default: "Tidak Ada",
    is_optional: true
  },
}

const semua_atribut = {
  ...atribut_data_objek,
  ...atribut_data_rdtr
}

class INPUT_DATA_OBJECT extends Component {
  constructor(props) {
    super(props);
    let body = {}
    Object.keys(semua_atribut).forEach(key => {
      body[key] = semua_atribut[key]["default"]
    })
    this.state = { ...body }

  }

  componentDidMount = () => {
    const { worksheet } = this.props
    let body = {}
    Object.keys(semua_atribut).forEach(key => {
      const value = get_value_cell(semua_atribut[key]["cell"], worksheet, semua_atribut[key]["sheet"]) || semua_atribut[key]["default"]
      if (this.state[key] !== value) {
        body[key] = value
      }
    });

    const hbu_type =
      get_value_cell("B5", worksheet, "Data Objek") ===
        "DATA OBJEK HBU BANGUNAN"
        ? "HBU Terbangun"
        : "HBU Tanah Kosong";

    this.props.set_value_hbu({
      key: "hbu_type",
      value: hbu_type,
    });

    this.setState({ ...body })
  }

  onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value
    })
  }

  onChangeDataObject = async (e) => {

    const name = e.target.name;
    const value = e.target.value;

    const { huruf, angka } = splitExcelReference(semua_atribut[e.target.name]["cell"])
    const col_idx = columnToNumber(huruf) - 1;
    const row_idx = Number(angka) - 1;
    const hyperformulaInstance = this.props?.hyperformulaInstance;

    const sheetName = semua_atribut[name]["sheet"];
    const hyperformula_sheet_id = hyperformulaInstance.getSheetId(sheetName);
    const old_value = hyperformulaInstance.getCellValue({ sheet: hyperformula_sheet_id, col: col_idx, row: row_idx });

    if (String(old_value) !== String(value)) {
      this.setState({ [name]: value })
      const { rawSheets, dbWorksheet, setRawSheets, setWorksheet, hyperformulaInstance } = this.props;

      const sheet_idx =
        dbWorksheet?.findIndex((obj) => obj?.metadata?.id_ori === sheetName);

      if (sheet_idx !== -1) {
        const cell_ref = semua_atribut[e.target.name]["cell"];
        const res = await this.props.edit_cell({
          sheet_id: dbWorksheet[sheet_idx]?._id,
          cell_ref,
          value: e.target.value,
        });

        if (res?.status === 200) {
          let new_worksheet = JSON.parse(JSON.stringify(dbWorksheet));
          let new_rawSheets = JSON.parse(JSON.stringify(rawSheets));

          new_rawSheets[sheetName][row_idx][col_idx] = value
          hyperformulaInstance.setCellContents({ col: col_idx, row: row_idx, sheet: hyperformula_sheet_id }, [[value]]);

          const cell_index = dbWorksheet[sheet_idx].value?.findIndex(
            (cell) => cell?.p === cell_ref
          );
          if (cell_index !== -1) {
            if (value?.[0] === "=") {
              let new_cell = new_worksheet[sheet_idx]["value"][cell_index];
              new_cell.f = value?.slice(1);
              new_worksheet[sheet_idx]["value"][cell_index] = new_cell;
            } else {
              let new_cell = new_worksheet[sheet_idx]["value"][cell_index];
              new_cell.v = value;
              new_worksheet[sheet_idx]["value"][cell_index] = new_cell;
            }
          } else {
            if (value?.[0] === "=") {
              new_worksheet[sheet_idx]["value"].push({
                p: cell_ref,
                f: value?.slice(1),
              });
            } else {
              new_worksheet[sheet_idx]["value"].push({
                p: cell_ref,
                v: value,
              });
            }
          }
          this.props.set_value_hbu({
            key: name,
            value: isNaN(value) ? value : (+value || 0),
          });
          setRawSheets(new_rawSheets);
          setWorksheet(new_worksheet)
        }

      }
    }

  }

  generate_content = ({ data, key, idx, is_must, value }) => {
    let content = null;
    if (data[key]?.["type"] === "textarea") {
      content = <textarea
        name={key}
        value={this.state[key]}
        onBlur={this.onChangeDataObject}
        onChange={this.onChange}
        style={{
          height: "100px",
        }}
        className={is_must && !value ? "outline_red" : "outline_grey"}
      />
    } else if (data[key]?.["type"] === "select") {
      content = <select
        name={key}
        value={this.state[key]}
        onBlur={this.onChangeDataObject}
        onChange={this.onChange}
        className={is_must && !value ? "outline_red" : "outline_grey"}
      >
        {
          atribut_data_rdtr[key]?.["options"]?.map((option, index) =>
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          )
        }
      </select>
    } else {
      content = <input
        name={key}
        value={this.state[key]}
        onBlur={this.onChangeDataObject}
        onChange={this.onChange}
        className={is_must && !value ? "outline_red" : "outline_grey"}
      />
    }
    return content
  }

  render() {

    const { hbu_type } = this.props?.hbu_reducer

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <h2 >Mohon isi data berikut</h2>
        <hr />
        <h3 style={{ fontWeight: "bold", marginBottom: "15px", textDecoration: "underline" }}>Informasi Fisik Aset</h3>
        {
          Object.keys(atribut_data_objek).filter(key => atribut_data_objek[key]["hbu_type"] === hbu_type || !atribut_data_objek[key]["hbu_type"]).map((key, idx) => {
            const is_must = !atribut_data_objek[key]["is_optional"]
            const value = isNaN(this.state[key]) ? this.state[key] : Number(this.state[key]);
            let content = this.generate_content({ data: atribut_data_objek, key, idx, is_must, value });
            return (
              <div
                key={idx}
                className="two-container-input">
                <div>{atribut_data_objek[key]["label"]}{is_must && <label style={{ color: "darkRed" }}>*</label>}</div>
                {content}
              </div>)
          }
          )
        }
        <hr />
        <h3 style={{ fontWeight: "bold", marginBottom: "15px", textDecoration: "underline" }}>Peraturan Zonasi dan Bangunan</h3>
        {
          Object.keys(atribut_data_rdtr).filter(key => atribut_data_rdtr[key]["hbu_type"] === hbu_type || !atribut_data_rdtr[key]["hbu_type"]).map((key, idx) => {
            const is_must = !atribut_data_rdtr?.[key]?.["is_optional"]
            const value = isNaN(this.state[key]) ? this.state[key] : Number(this.state[key]);
            let content = this.generate_content({ data: atribut_data_rdtr, key, idx, is_must, value });
            return (
              <div
                key={idx}
                className="two-container-input">
                <div>{atribut_data_rdtr[key]["label"]}{is_must && <label style={{ color: "darkRed" }}>*</label>}</div>
                {content}
              </div>)
          }
          )
        }
        <hr />
        <span>Keterangan: <label style={{ color: "darkRed" }}>*</label> Wajib diisi</span>
      </div >
    )
  }

}



const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {
  edit_cell,
  set_value_hbu
})(INPUT_DATA_OBJECT);
