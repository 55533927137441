import React, { Component } from "react";
import "./style.css";
import dict from "../../../Data/dict.json";
// import { BsSearch } from "react-icons/bs";
// import { IoClose } from "react-icons/io5";

const Icon = () => {
  return (
    <svg
      // className="w-4 h-4 inline"
      style={{
        width: "16px",
        height: "16px",
      }}
      aria-hidden="true"
      fill="currentColor"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.searchRef = React.createRef();
    this.state = {
      filter_text: "",
      filtered_children: [],
      is_active: false,
      value: "",
    };
  }

  convertChildren = (unknown_children) => {
    if (
      typeof unknown_children === "object" &&
      !Array.isArray(unknown_children) &&
      unknown_children !== null
    ) {
      return [unknown_children];
    } else {
      return unknown_children;
    }
  };

  componentDidMount = () => {
    const { value, children, is_multi } = this.props;

    let selected = [];
    if (is_multi && Array.isArray(value)) {
      selected = value;
    } else if (!is_multi && typeof value !== "object") {
      selected = value || "";
    }

    this.setState({
      filter_text: "",
      filtered_children: this.convertChildren(children),
      is_active: false,
      value: selected,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { is_multi, value } = this.props;

    const { is_active } = this.state;

    if (prevProps.is_multi !== is_multi) {
      this.setState({
        is_multi: is_multi,
        // value: is_multi ? [] : "",
      });
    }

    // mengaktifkan focus pada textfield
    if (prevState.is_active !== is_active) {
      this.setState({
        filter_text: "",
      });
      if (is_active && this.searchRef?.current) {
        this.searchRef?.current?.focus();
      }
    }

    if (Array.isArray(prevProps.value) && Array.isArray(value) && is_multi) {
      if (prevProps.value?.length !== value?.length) {
        this.setState({
          value: value,
        });
      }
    }

    if (prevProps?.value !== value) {
      this.setState({
        value,
      });
    }

    window.addEventListener("click", this.handler);
    return () => {
      window.removeEventListener("click", this.handler);
    };
  }

  handler = (e) => {
    // const element = document.getElementById("dropdown");
    if (this.inputRef.current && !this.inputRef?.current?.contains(e.target)) {
      this.setState({
        is_active: false,
      });
    }
  };

  filterOnChange = (e) => {
    const { children } = this.props;
    const keywords = e.target.value?.toLowerCase();
    const regex_string = `${keywords
      ?.split(" ")
      ?.map((word) => `(?=.*?${word})`)
      ?.join("")}.*`;
    const regex_pattern = new RegExp(regex_string);
    if (keywords) {
      const filtered_children = this.convertChildren(children).filter((item) =>
        regex_pattern.test(item.props?.children?.toLowerCase())
      );
      this.setState({
        filtered_children,
        filter_text: keywords,
      });
    } else {
      this.setState({
        filtered_children: this.convertChildren(children),
        filter_text: keywords,
      });
    }
  };

  toggleDropdown = () => {
    const { children } = this.props;
    const { is_active } = this.state;
    this.setState({
      filtered_children: this.convertChildren(children),
      is_active: !is_active,
    });
  };

  removeOption = (option) => {
    const { value } = this.state;
    return value.filter((value) => value !== option);
  };

  handleClick = (key) => {
    const { onChange, is_multi } = this.props;
    const { value } = this.state;

    let newValue = "";
    if (is_multi) {
      newValue = key ? [...value, key] : [value];
    } else {
      newValue = key || "";
    }
    this.setState(
      {
        value: newValue,
      },
      () => {
        if (onChange) onChange(newValue);
      }
    );

    // this.toggleDropdown();
  };

  onTagRemove = (props) => {
    const { onChange } = this.props;
    const newValue = this.removeOption(props);
    this.setState(
      {
        value: newValue,
      },
      () => {
        if (onChange) onChange(newValue);
      }
    );
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      children,
      className,
      color,
      type,
      size,
      filter_on,
      placeholder = dict["Select"][language],
      is_multi,
      is_enable_empty,
      ...props
    } = this.props;
    const { is_active, value, filtered_children } = this.state;

    const options = Array.isArray(filtered_children) && (
      <>
        {is_enable_empty && (
          <div
            key=""
            className="option_list_dropdown"
            onClick={() => {
              this.handleClick();
            }}
          >
            Select
          </div>
        )}
        {filtered_children?.length > 0 &&
          filtered_children?.map((item, idx) => {
            return (
              <div
                key={item.key}
                className="option_list_dropdown"
                onClick={() => {
                  this.handleClick(item.key);
                }}
                data-mapid={`selectType-${idx}`}
              >
                {item.props.children}
              </div>
            );
          })}
      </>
    );

    const getDisplay = () => {
      if (!value || value?.length === 0) {
        return placeholder;
      }
      if (is_multi) {
        if (Array.isArray(value)) {
          return (
            <div className="parent_children_dropdown">
              {value?.length >= 0 &&
                value?.map((key) => {
                  const label = filtered_children?.find(
                    (child) => child?.key === key
                  )?.props?.children;
                  return (
                    <div
                      key={key}
                      className="children_dropdown"
                      onClick={(e) => this.onTagRemove(key)}
                    >
                      {label}
                    </div>
                  );
                })}
            </div>
          );
        } else {
          return placeholder;
        }
      } else {
        if (Array.isArray(filtered_children)) {
          const label = filtered_children?.find((child) => child?.key === value)
            ?.props?.children;
          return label?.props?.children || label;
        }
        return placeholder;
      }
    };

    return (
      <div className="parent_dropdown">
        <div
          {...props}
          type=""
          className={`noselect primary_button justify_between ${className}`}
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => this.toggleDropdown()}
          ref={this.inputRef}
        >
          <span className="dropdown_child ">{getDisplay()}</span>
          <span className="flex">{<Icon />}</span>
        </div>
        {is_active && (
          <div id="dropdown" className={`poupup_dropdown`}>
            {filter_on && (
              <input
                type="text"
                ref={this.searchRef}
                style={{
                  width: "100%",
                  height: "25px",
                  maxHeight: "25px",
                }}
                onChange={this.filterOnChange}
              />
            )}
            <div className="option_list_box_dropdown">{options}</div>
          </div>
        )}
      </div>
    );
  }
}
