/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
/*
  "JENIS",
  "MIN_LUAS",
  "MAX_LUAS",
  "MIN_RASIO",
  "MAX_RASIO",
  "MIN_LEBAR_JALAN",
  "TIPE_BANGUNAN",
  "BIAYA_PEMBANGUNAN_PER_M_2",
  "HARGA_SEWA_PER_M_2_PER_TAHUN",
  "OKUPANSI_PER_TAHUN",
  "DISEWAKAN_DIGUNAKAN",
  "FASUM",
  "PENDAPATAN PER M_2",
  "RASIO_BIOP_MIN",
  "RASIO_BIOP_MAX",
  "BIOP_PER_M_2_MIN",
  "BIOP_PER_M_2_MAX"
  */

class UJI_LEGAL extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const {
      luas_tanah,
      LLB,
      KDB,
      LDB,
      KLB,
      JLB,
    } = this.props.hbu_reducer;


    // digunakan ketika data diloloskan secara paksa

    //content

    // console.log(LLBE)
    // console.log(`LDB = (${KDB} / 100) * ${luas_tanah} = `, LDB)
    // console.log(`LLB = ${KLB} * ${luas_tanah} = `, LDB)

    const ldb_calc = (KDB / 100) * luas_tanah
    const is_ldb_input = ldb_calc !== LDB;
    const llb_calc = (KLB) * luas_tanah
    const is_llb_input = llb_calc !== LLB;
    const jlb_calc = Math.ceil(LLB / LDB)
    const is_jlb_input = jlb_calc !== JLB;

    return (
      <main className="two_container_auto">
        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">Luas Lantai Bangunan (LLB)</h2>
          <table>
            <tbody>
              {!is_llb_input ? <>
                <tr>
                  <td>LLB</td>
                  <td>= KLB x Luas Tanah</td>
                </tr>
                <tr>
                  <td>LLB</td>
                  <td>
                    ={` ${KLB} x ${new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(luas_tanah)}`}
                  </td>
                </tr>
                <tr>
                  <td>LLB</td>
                  <td>
                    ={" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(llb_calc)}{" "}
                  </td>
                </tr>
              </> :
                <>
                  <tr>
                    <td>LLB</td>
                    <td>{`= ${LLB}`}</td>
                    <td>[Fix]</td>
                  </tr>
                </>
              }
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <h2 className="text_bold margin_bottom">Luas Dasar Bangunan (LDB)</h2>
              {!is_ldb_input ?
                <>
                  <tr>
                    <td>LDB</td>
                    <td>= KDB x Luas Tanah</td>
                  </tr>
                  <tr>
                    <td>LDB</td>
                    <td>
                      ={` ${(KDB / 100)} x ${new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(luas_tanah)}`}
                    </td>
                  </tr>
                  <tr>
                    <td>LDB</td>
                    <td>
                      ={" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(ldb_calc)}{" "}
                    </td>
                  </tr>
                </> :
                <>
                  <tr>
                    <td>LDB</td>
                    <td>{`= ${LDB}`}</td>
                    <td>[Fix]</td>
                  </tr>
                </>
              }
              {!is_jlb_input ?
                <>
                  <tr>
                    <td colSpan={2}>
                      <hr />
                    </td>
                  </tr>
                  <h2 className="text_bold margin_bottom">Jumlah Lantai Bangunan (JLB)</h2>
                  <tr>
                    <td>JLB</td>
                    <td>= ⌈LLB / LDB⌉</td>
                  </tr>
                  <tr>
                    <td>JLB</td>
                    <td>
                      ={` ⌈${LLB} / ${LDB}⌉`}
                    </td>
                  </tr>
                  <tr>
                    <td>JLB</td>
                    <td>
                      {`= ⌈${(LLB / LDB).toFixed(3)}⌉`}
                    </td>
                  </tr>
                  <tr>
                    <td>JLB</td>
                    <td>
                      {`= ${JLB}`}
                    </td>
                  </tr>
                </> :
                <>
                  <tr>
                    <td>JLB</td>
                    <td>{`= ${JLB}`}</td>
                    <td>[Fix]</td>
                  </tr>
                </>
              }

            </tbody>
          </table>
        </section>
      </main >
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(UJI_LEGAL);
