import React, { Component } from "react";
import dict from "../../Data/dict.json";
import { convert_conditional_statement_operator_to_readable } from "../../App/validation/formula_validation";
// import { Dropdown } from "../reuseable";
import { MenuItem, Select } from "@mui/material";

const booleans = ["true", "false"];
const math_symbols = ["<", ">", "="];
const logical_operators = ["&", "|"];
const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default class ConditionalStatementEdit extends Component {
  constructor(props) {
    super(props);
    this.logicalInput = React.createRef();
    this.state = {
      variable: {
        key: "",
        name: "",
      },
    };
  }

  componentDidMount() {
    this?.logicalInput?.current?.focus();
  }

  handleResetFormula = () => {
    this.props.handleFormula([]);
  };

  resetSelect = () => {
    let element = document.getElementById("select_variable");
    element.value = "";
  };

  handleFormulaOperator = (e) => {
    const { value } = e.target;
    if (math_symbols.includes(value)) {
      this.pushFormula("math_symbol", value);
    } else if (logical_operators.includes(value)) {
      this.pushFormula("logical_operators", value);
    } else if (booleans.includes(value)) {
      this.pushFormula("boolean", value);
    } else if (value === ".") {
      this.handleFormulaOnChange(e);
    } else {
      this.pushFormula("operator", value);
    }
    this?.logicalInput?.current?.focus();
  };

  handleColumnSelector = (value) => {
    const key = value?.split("#_spliter_#")[0];
    const name = value?.split("#_spliter_#")[1];
    this.pushFormula("variable", name, key);
    // this.resetSelect();
  };

  setFormula = (value) => {
    this.props.handleFormula(value);
  };

  pushFormula = (type, value, key) => {
    const {
      conditional_statement_index_that_will_be_edited,
      conditional_statement_list,
    } = this.props;

    conditional_statement_list[conditional_statement_index_that_will_be_edited][
      "if"
    ].push({
      type: type,
      content: value,
      ref_key: key,
    });

    this.props.handleConditionalStatementList(conditional_statement_list);
  };

  setLogicalList = (logical_list) => {
    const {
      conditional_statement_index_that_will_be_edited,
      conditional_statement_list,
    } = this.props;

    conditional_statement_list[conditional_statement_index_that_will_be_edited][
      "if"
    ] = logical_list;

    this.props.handleConditionalStatementList(conditional_statement_list);
  };

  handleThen = (e) => {
    const {
      conditional_statement_index_that_will_be_edited,
      conditional_statement_list,
    } = this.props;

    conditional_statement_list[conditional_statement_index_that_will_be_edited][
      "then"
    ] = e.target.value;

    this.props.handleConditionalStatementList(conditional_statement_list);
  };

  handleFormulaOnChange = (e) => {
    const {
      conditional_statement_index_that_will_be_edited,
      conditional_statement_list,
    } = this.props;

    const logical_list =
      conditional_statement_list[
        conditional_statement_index_that_will_be_edited
      ]["if"];

    const { value } = e.target;
    const { data, inputType } = e.nativeEvent;
    // hapus
    if (inputType === "deleteContentBackward" || value === "backspace") {
      const last_array = logical_list[logical_list.length - 1];
      if (last_array?.type === "constant" && last_array?.content?.length > 1) {
        let new_logical_list = logical_list.slice();
        new_logical_list[new_logical_list.length - 1].content =
          new_logical_list[new_logical_list.length - 1].content.slice(0, -1);

        this.setLogicalList(new_logical_list);

        // this.props.handleConditionalStatementList(conditional_statement_list);
        // this.setFormula(new_conditional_statement_list);
      } else if (
        (last_array?.type === "constant" &&
          last_array?.content?.length === 1) ||
        last_array?.type !== "constant"
      ) {
        const new_conditional_statement_list = logical_list.slice(0, -1);

        this.setLogicalList(new_conditional_statement_list);
        // this.setFormula(conditional_statement_list?.slice(0, -1));
      } else {
        const new_conditional_statement_list = logical_list.slice(0, -1);

        this.setLogicalList(new_conditional_statement_list);
        // this.setFormula(conditional_statement_list?.slice(0, -1));
      }
    }
    // input angka
    else if (
      (/[0-9]/gi.test(data) === true &&
        inputType === "insertText" &&
        !isNaN(data)) ||
      (/[0-9]/gi.test(value) === true && !data && !isNaN(value)) ||
      (data === "." && isNaN(data)) ||
      (value === "." && isNaN(value))
    ) {
      if (logical_list[logical_list.length - 1]?.type === "constant") {
        let new_logical_list = logical_list.slice();
        new_logical_list[new_logical_list.length - 1].content =
          new_logical_list[new_logical_list.length - 1].content +
          (data || value);

        this.setLogicalList(new_logical_list);
      } else {
        this.pushFormula("constant", data || value);
      }
    }
    // input simbol matematika (<,>,=)
    else if (/^(<|>|=){1}$/gi.test(data) === true) {
      switch (data) {
        case "<":
          this.pushFormula("math_symbol", "<");
          break;
        case ">":
          this.pushFormula("math_symbol", ">");
          break;
        case "=":
          this.pushFormula("math_symbol", "===");
          break;
        default:
          break;
      }
    }
    // input operasi logika (and, or)
    else if (/^(t|f){1}$/gi.test(data) === true) {
      switch (data) {
        case "t":
          this.pushFormula("boolean", "true");
          break;
        case "f":
          this.pushFormula("boolean", "false");
          break;
        default:
          break;
      }
    }
    // input operasi logika (and, or)
    else if (/^(&|\||!){1}$/gi.test(data) === true) {
      switch (data) {
        case "&":
          this.pushFormula("logical_operator", "&&");
          break;
        case "|":
          this.pushFormula("logical_operator", "||");
          break;
        case "!":
          this.pushFormula("logical_operator", "!");
          break;
        default:
          break;
      }
    }
    // input standard operator matematika
    else if (/^(\*|\/|\+|-|\(|\)|\^|%){1}$/gi.test(data) === true) {
      this.pushFormula("operator", data);
    }
  };

  render() {
    const { language } = this.props;
    const {
      headers,
      conditional_statement_index_that_will_be_edited,
      conditional_statement_list,
    } = this.props;

    const conditional_statement =
      conditional_statement_list[
        conditional_statement_index_that_will_be_edited
      ];

    return (
      <div className="">
        <div className="marginBottom_10">
          <h3 className="font_18 w_full text_center">Edit Logic</h3>
        </div>
        <div className="grid_child gap_10_10 marginBottom_10 w_full">
          {conditional_statement?.function === "if" && (
            <div className="grid_child gap_10_10">
              <div>
                <label htmlFor="refer_to_column" className="font_14 w_full">
                  Referring Column
                </label>
                <Select
                  className="w_full bg_white"
                  placeholder={dict["Select"][language]}
                  value={"select"}
                  onChange={(value) => {
                    this.handleColumnSelector(value?.target?.value);
                  }}
                  data-mapid="clickReffering"
                >
                  <MenuItem value={"select"}>
                    {dict["Select"][language]}
                  </MenuItem>
                  {headers
                    // ?.filter((header) => header.type === "number")
                    .map((header, key) => {
                      return (
                        <MenuItem
                          key={`${header.field}#_spliter_#${header.headerName}`}
                          value={`${header.field}#_spliter_#${header.headerName}`}
                          data-mapid={`selectFormula-${key}`}
                        >
                          {header.headerName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>

              <div className="flex w_full gap_5_5 wrap">
                <button
                  value="C"
                  onClick={this.handleResetFormula}
                  className="rounded_10 center_perfect text_white background_red font_14 hover_darkRed w_40 h_30"
                >
                  C
                </button>
                <button
                  value="backspace"
                  onClick={this.handleFormulaOnChange}
                  className="rounded_10 center_perfect text_white font_14 background_red hover_darkRed w_40 h_30"
                >
                  Del
                </button>
                <button
                  value=">"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                  data-mapid="click>"
                >
                  {">"}
                </button>
                <button
                  value="<"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                  data-mapid="click<"
                >
                  {"<"}
                </button>
                <button
                  value="<="
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {"≤"}
                </button>
                <button
                  value=">="
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {"≥"}
                </button>
                <button
                  value="==="
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  =
                </button>
                <button
                  value="!="
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  ≠
                </button>
                <button
                  value="&&"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  AND
                </button>
                <button
                  value="||"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  OR
                </button>
                <button
                  value="!"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  Not
                </button>
                <button
                  value="*"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  *
                </button>
                <button
                  value="/"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  /
                </button>
                <button
                  value="+"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  +
                </button>
                <button
                  value="-"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  -
                </button>
                <button
                  value="("
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {"("}
                </button>
                <button
                  value=")"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {")"}
                </button>
                <button
                  value="^"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  ^
                </button>
                <button
                  value="%"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  %
                </button>
                <button
                  value="."
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  .
                </button>
                <button
                  value="true"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {dict["True"][language]}
                </button>
                <button
                  value="false"
                  onClick={this.handleFormulaOperator}
                  className="rounded_10 center_perfect text_white font_14 bg_green hover_darkGreen w_40 h_30"
                >
                  {dict["False"][language]}
                </button>
              </div>
              <div className="flex gap_5_5 wrap">
                {numbers?.map((number) => (
                  <button
                    key={number}
                    value={number}
                    onClick={this.handleFormulaOnChange}
                    className="rounded_10 center_perfect text_white bg_blue font_20 hover_darkBlue w_40 h_30"
                  >
                    {number}
                  </button>
                ))}
              </div>
            </div>
          )}
          <div>
            <div className="grid_child w_full">
              <div className="four_container_100px_auto_100px_auto">
                <div className="center_perfect">
                  <label className="text_center font_16">
                    {conditional_statement?.function === "if"
                      ? dict["IF"][language]
                      : dict["ELSE"][language]}
                  </label>
                </div>

                {conditional_statement?.if && (
                  <textarea
                    ref={this.logicalInput}
                    type="number"
                    onChange={(e) => this.handleFormulaOnChange(e)}
                    className="w_full"
                    value={conditional_statement?.if
                      .map((f) =>
                        convert_conditional_statement_operator_to_readable(
                          f.content,
                          language,
                          dict
                        )
                      )
                      .join("")}
                  />
                )}

                {conditional_statement?.function === "if" && (
                  <div className="center_perfect">
                    <label className="text_center font_16">
                      {dict["THEN"][language]}
                    </label>
                  </div>
                )}
                <textarea
                  type="text"
                  onChange={(e) => this.handleThen(e)}
                  className="w_full"
                  value={conditional_statement?.then}
                  data-mapid="typeThen"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
