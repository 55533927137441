/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/
import icon_ok from "../../Assets/svg/icon_ok.svg";
import icon_not_ok from "../../Assets/svg/icon_not_ok.svg";

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class EDIT_LAYER_STYLE_2 extends Component {
  state = {};

  on_step_2 = () => {
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 2,
    });
  };

  on_next_step = () => {
    this.props.set_value_properties({
      key: "layer_style_step",
      value: 3,
    });
  };

  on_generate_color = () => {
    this.props.status_action();
    const { style_parameter } = this.props.properties;
    const paint_object_edited = generate_paint(style_parameter);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_edited,
    });
    this.on_next_step();
    this.props.status_action();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { features_length, layer_style_step, style_parameter } =
      this.props.properties;

    //content
    let {
      name,
      min,
      max,
      type_of_array,
      is_number_count,
      value_array,
      case_recomendation,
    } = style_parameter;

    type_of_array = type_of_array || [];

    const field_key = style_parameter?.field_key;
    let recomendation_content;
    const button_recomendation_content = (
      <button
        className="button background_green margin_right"
        onClick={this.on_generate_color}
      >
        {dict?.["Create a color style"]?.[language]}
      </button>
    );

    if (case_recomendation === "text") {
      //text
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="ok" width={50} src={icon_ok} />
          </section>
          <p className="margin_bottom">
            {dict?.["All values are text confirmed."]?.[language]}
            <br />
            <br />
            {dict?.["Use text mode style."]?.[language]}
          </p>
        </main>
      );
    } else if (case_recomendation === "color") {
      //text
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="ok" width={50} src={icon_ok} />
          </section>
          <p className="margin_bottom">
            {dict?.["All values are color."]?.[language]}
            <br />
            <br />
            {dict?.["Use color mode style."]?.[language]}
          </p>
        </main>
      );
    } else if (case_recomendation === "number") {
      //kasus number
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="ok" width={50} src={icon_ok} />
          </section>
          <p className="margin_bottom">
            {dict?.["All values are confirmed as numbers."]?.[language]}
            <br />
            <br />
            {dict?.["Use number mode style."]?.[language]}
          </p>
        </main>
      );
    } else if (case_recomendation === "number_in_text") {
      //kasus angka tapi di dalam format teks
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="Not ok" width={50} src={icon_not_ok} />
          </section>
          <p className="margin_bottom">
            {
              dict?.[
                "The data values are numbers in text, so styling them using the number classification mode may cause errors."
              ]?.[language]
            }
            <br />
            <br />
            {
              dict?.[
                "Convert the data to numbers first or use the text mode style."
              ]?.[language]
            }
          </p>
        </main>
      );
    } else if (case_recomendation === "number_and_text") {
      //kasus angka dan teks tercampur
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="Not ok" width={50} src={icon_not_ok} />
          </section>
          <p className="margin_bottom">
            {
              dict?.[
                "This column contains text and numbers, the styling of numbers can be errored."
              ]?.[language]
            }
            <br />
            <br />
            {dict?.["Resolve first or use text mode styles."]?.[language]}
          </p>
        </main>
      );
    } else {
      //kasus lain yang belum terhandle
      recomendation_content = (
        <main>
          <section className="text_center margin_bottom">
            <img alt="Not ok" width={50} src={icon_not_ok} />
          </section>
          <p className="margin_bottom">
            {dict?.["No suitable recommendations were found."]?.[language]}
          </p>
        </main>
      );
    }
    let content = <></>;
    if (!!style_parameter.key) {
      content = (
        <>
          <p className="badge_small background_black">
            {dict?.["Edit mode"]?.[language]}
          </p>
          <p>
            {dict?.["Recommendations do not apply in edit mode"]?.[language]}
          </p>
        </>
      );
    } else if (field_key) {
      content = (
        <>
          <table
            className="table full_width margin_bottom"
            style={{ tableLayout: "fixed" }}
          >
            <thead>
              <tr>
                <td>{dict?.["Column Name"]?.[language]}</td>
                <td
                  style={{
                    maxHeight: "2em",
                    overflowY: "hidden",
                    padding: "5px",
                    wordBreak: "break-all",
                  }}
                  className="text_bold cursor_pointer"
                  onClick={this.on_step_2}
                >
                  {name}
                </td>
              </tr>
            </thead>
            {layer_style_step === 2 && (
              <tbody>
                {type_of_array.map((type, index) => {
                  const percentage =
                    Number((type.value / features_length).toFixed(1)) * 100;
                  return (
                    <tr key={index} className="background_dark_blue">
                      <td>{dict?.[type.key]?.[language]}</td>
                      <td className="text_right" style={{ minWidth: "35px" }}>
                        {percentage} %
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>{dict?.["Number compatibility"]?.[language]}</td>
                  <td className="text_right">
                    {Number((is_number_count / features_length).toFixed(1)) *
                      100}{" "}
                    %
                  </td>
                </tr>
                <tr>
                  <td>
                    {dict?.["Minimum number value"]?.[language]}
                    <br />
                    {dict?.["(if any)"]?.[language]}
                  </td>
                  <td className="text_right">{min}</td>
                </tr>
                <tr>
                  <td>
                    {dict?.["Maximum number value"]?.[language]}
                    <br />
                    {dict?.["(if any)"]?.[language]}
                  </td>
                  <td className="text_right">{max}</td>
                </tr>
                <tr>
                  <td>{dict?.["Number of data groups"]?.[language]}</td>
                  <td className="text_right">{value_array?.length || 0}</td>
                </tr>
                <tr>
                  <td>{dict?.["Count total data"]?.[language]}</td>
                  <td className="text_right">{features_length || 0}</td>
                </tr>
              </tbody>
            )}
          </table>
          {layer_style_step === 2 && (
            <section className="container_light background_white outline_black">
              {recomendation_content}
              {button_recomendation_content}
            </section>
          )}
        </>
      );
    }
    return (
      <main className="container_light margin_bottom outline_black">
        <h1 className="text_bold margin_bottom">
          {dict?.["Style recommendation:"]?.[language]}
        </h1>
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(EDIT_LAYER_STYLE_2);
