// Helper function to convert meters to degrees
function meters_to_degrees(meters, latitude) {
  const meters_per_degree_lat = 111320; // Approximate meters per degree of latitude
  const meters_per_degree_lon = 111320 * Math.cos((latitude * Math.PI) / 180); // Adjust for longitude

  const lat_degree = meters / meters_per_degree_lat;
  const lon_degree = meters / meters_per_degree_lon;

  return { lat: lat_degree, lon: lon_degree };
}

// Helper function to compute the four corners of the square grid
function compute_square_grid(lat, lon, grid_width_meter) {
  const { lat: lat_offset, lon: lon_offset } = meters_to_degrees(
    grid_width_meter,
    lat
  );

  // Create a grid around the central point
  const grid_corners = [
    { lat: lat - lat_offset, lon: lon - lon_offset }, // Bottom left
    { lat: lat - lat_offset, lon: lon + lon_offset }, // Bottom right
    { lat: lat + lat_offset, lon: lon + lon_offset }, // Top right
    { lat: lat + lat_offset, lon: lon - lon_offset }, // Top left
  ];

  return grid_corners;
}

// Main function to generate GeoJSON polygon for the grid
export default function generate_geojson_grid(
  features_point,
  grid_width_meter
) {
  const lat = features_point.geometry.coordinates[1]; // Extract latitude from the point
  const lon = features_point.geometry.coordinates[0]; // Extract longitude from the point

  const grid_corners = compute_square_grid(lat, lon, grid_width_meter);

  // Return GeoJSON Polygon structure
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [grid_corners[0].lon, grid_corners[0].lat],
          [grid_corners[1].lon, grid_corners[1].lat],
          [grid_corners[2].lon, grid_corners[2].lat],
          [grid_corners[3].lon, grid_corners[3].lat],
          [grid_corners[0].lon, grid_corners[0].lat], // Close the polygon
        ],
      ],
    },
    properties: {},
  };
}

// Example usage
// const features_point = {
//   type: "Feature",
//   geometry: {
//     type: "Point",
//     coordinates: [106.8271, -6.1754], // Example coordinates (lon, lat)
//   },
//   properties: {},
// };

// const grid_width_meter = 1000; // 1 km grid width
// const geojson_grid = generate_geojson_grid(features_point, grid_width_meter);
