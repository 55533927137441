const initial_state = {
  genangan_update: 0,
  array_genangan_success: [],
  array_genangan_failed: [],
  array_genangan_message_failed: [],
  geojson_contour_all: {
    type: "FeatureCollection",
    features: [],
  },
  geojson_contour_notflooded: {
    type: "FeatureCollection",
    features: [],
  },
  geojson_contour_flooded: {
    type: "FeatureCollection",
    features: [],
  },
  geojson_polygon_convex: {
    type: "FeatureCollection",
    features: [],
  },
  geojson_polygon_not_flooded: {
    type: "Feature",
    features: [],
  },
  features_polygon_convex: [],
  features_polygon_new_algorithm: [],
  genangan_mdpl: 0,
  contourCreated: false,
  feature_key_selected_genangan: "",
  feature_key_params_genangan: "",
  feature_object_selected_genangan: {},

  feature_key_failed_genangan: "",
  current_progress_genangan: 0,
  is_dem_visible: false,
  // radius_titik_dem: 300,

  total_union: 1,
  current_union: 0,

  tinggi_titik_dem_terdekat: 0,
};

export default function dataReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_value_genangan":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "push_value_genangan":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };
    case "find_and_replace_value_genangan":
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].map((item, index) => {
          if (item.properties.feature_key === action.payload.feature_key) {
            return action.payload.value;
          } else {
            return item;
          }
        }),
      };

    case "delete_value_genangan":
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].filter(
          (item) =>
            item.properties.feature_key !==
            action.payload.properties.feature_key
        ),
      };

    case "pull_value_genangan_string":
      return {
        ...state,
        ...pull_value_genangan_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };
    case "pull_value_genangan_object":
      return {
        ...state,
        ...pull_value_genangan_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.key_child
        ),
      };
    case "genangan_update":
      return {
        ...state,
        genangan_update: state.genangan_update + 1,
      };

    default:
      return state;
  }
}

const pull_value_genangan_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_genangan_object = (key, list, value, key_child) => {
  list = list.filter((item) => item[key_child] !== value);
  return { [key]: list };
};
