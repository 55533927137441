/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import io from "socket.io-client";

import { getLayer_Id } from "../../App/actions/layerActions";
import dict from "../../Data/dict.json";
import pic_static from "../../Assets/svg/profile_pic_comment.svg";

/*CONSTANT*/
const SOCKET_URL_MAPID = "https://socket.mapid.io";
class ONLINE_EDITORS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxViewEditors: 4,
    };
    this.socket = io.connect(SOCKET_URL_MAPID, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });
  }
  componentDidMount() {}

  componentDidUpdate(prevProps) {}
  componentWillUnmount() {
    this.socket.off(this.props._id);
  }

  render() {
    const language = localStorage?.language || "ina";
    const { editorUsers } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "3px",
        }}
      >
        {editorUsers ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Rata kiri
            }}
          >
            <p
              style={{
                fontSize: "12px",
              }}
            >
              {dict["Online Collaborators"][language] + ":"}
            </p>
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: "10px",
          }}
        >
          {(
            editorUsers?.users?.slice(0, this?.state?.maxViewEditors) || []
          ).map((data, index) => {
            return (
              <div
                key={data._id}
                className={`photo_${data._id}`}
                title={data.name}
                style={{
                  position: "relative",
                  marginLeft: index !== 0 ? "-10px" : "0",
                  height: "30px",
                  width: "30px",
                  borderRadius: "50%",
                  border: `2px solid ${
                    data.borderColor || this.getRandomColor()
                  }`,
                  background: `url(${
                    data?.profile_picture?.url || pic_static
                  }) no-repeat center center / cover`,
                }}
                // onClick={() => {
                //   this.handleModalEditorUser(data);
                // }}
              ></div>
            );
          })}
          {(editorUsers?.users?.length ?? 0) > this?.state?.maxViewEditors && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                border: "2px solid #2196f3",
                backgroundColor: "#c1c1c1",
                color: "#fff",
                fontWeight: "bold",
                position: "relative",
                marginLeft: "-10px",
              }}
            >
              +{(editorUsers?.users?.length || 0) - this?.state?.maxViewEditors}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { getLayer_Id })(ONLINE_EDITORS);
