import React, { Component } from "react";
class CheckBoxNew extends Component {
  render() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  marginBottom: "10px",
                  width: "40px",
                }}
              >
                <div className="center_switch">
                  <div
                    onClick={this.props.handle}
                    className="button_slider_new"
                    style={{
                      backgroundColor: !!this.props.value ? "#2196f3" : "grey",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        right: !!this.props.value ? "3px" : "18px",
                        backgroundColor: "white",
                        width: "calc(( 50% - 3px ))",
                        height: "calc(( 100% - 6px ))",
                        borderRadius: "2rem",
                        transition: "right 0.1s linear",
                      }}
                    />
                  </div>
                </div>
                <style>
                  {`.center_switch{
                    display: inline-block;
                    align: center;
                    vertical-align: middle;
                    }`}
                </style>
              </td>
              <td>
                <div
                  onClick={this.props.handle}
                  className="noselect"
                  style={{ textAlign: "left", cursor: "pointer" }}
                  data-mapid="clickGeser"
                >
                  {this.props.title}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CheckBoxNew;
