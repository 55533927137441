import axios from "axios";
import { iotServerBaseUrl } from "./baseUrl";
import uuid from "../validation/uuid";

const IOT_SERVER = iotServerBaseUrl;

const data_list = (item) => {
  let item_new = item.data.list.filter((e) => {
    return (
      e.Lat > -13.687868244013657 &&
      e.Lat < 8.565418618579756 &&
      e.Lng > 91.69636356736675 &&
      e.Lng < 142.58365621950827
    );
  }); //filter di dalam batas indonesia
  item_new = item_new.map((d, idx) => ({
    key: idx,
    type: "Feature",
    properties: {
      ...d,
      long: d.Lng,
      lat: d.Lat,
      key: idx,
    },
    geometry: {
      coordinates: [d.Lng, d.Lat],
      type: "Point",
    },
  }));
  return item_new;
};

const generate_field = (properties) => {
  let fields = [];

  for (const key in properties) {
    fields.push({
      isHighlight: false,
      isRequired: true,
      date: "2021-07-01T04:21:47.910Z",
      isHide: false,
      isStyle: false,
      _id: uuid(), // usahakan unik
      key: key, // penting
      name: key, // penting
      type: "text",
      array_templates: [],
      term: [],
      digit_term: [],
    });
  }
  return fields;
};

export const get_pda = (geo_layer_item) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING_PROCESS_LAYER",
      payload: "get_detail",
    });
    const res = await axios.get(`${IOT_SERVER}/cilicis/pda`);
    dispatch({
      type: "SDA_PDA",
      payload: res.data,
    });
    const geojson = {
      type: "FeatureCollection",
      features: data_list(res),
    };

    // const fields_test = [
    //   {
    //     isHighlight: false,
    //     isRequired: true,
    //     date: "2021-07-01T04:21:47.910Z",
    //     isHide: false,
    //     isStyle: false,
    //     _id: "60dd4317bf440e04ec89173f", // usahakan unik
    //     key: "nama_lokasi", // penting
    //     name: "Nama", // penting
    //     type: "text",
    //     array_templates: [],
    //     term: [],
    //     digit_term: [],
    //   },
    // ];

    const fields = generate_field(geojson?.features[0]?.properties);

    geo_layer_item.geojson = geojson;
    geo_layer_item.geojson_filtered = geojson;
    geo_layer_item.fields = fields;
    const payload = { geo_layer_item };
    dispatch({
      type: "GET_LAYER_DETAIL",
      payload: payload,
    });
    dispatch({
      type: "CLEAR_LOADING_LAYER",
    });
    dispatch({
      type: "status_action",
    });
    dispatch({
      type: "fly_action",
    });
  } catch (error) {
    dispatch({
      type: "SDA_PDA",
      payload: [],
    });
  }
};

export const get_pch = (geo_layer_item) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_LOADING_PROCESS_LAYER",
      payload: "get_detail",
    });
    const res = await axios.get(`${IOT_SERVER}/cilicis/pch`);
    dispatch({
      type: "SDA_PCH",
      payload: res.data,
    });
    const geojson = {
      type: "FeatureCollection",
      features: data_list(res),
    };
    const fields = generate_field(geojson?.features[0]?.properties);

    geo_layer_item.geojson = geojson;
    geo_layer_item.geojson_filtered = geojson;
    geo_layer_item.fields = fields;
    const payload = { geo_layer_item };

    dispatch({
      type: "GET_LAYER_DETAIL",
      payload: payload,
    });
    dispatch({
      type: "CLEAR_LOADING_LAYER",
    });
    dispatch({
      type: "status_action",
    });
    dispatch({
      type: "fly_action",
    });
  } catch (error) {
    dispatch({
      type: "SDA_PDA",
      payload: [],
    });
  }
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_LAYER",
  };
};

export const getAllDataset = () => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .get(`${IOT_SERVER}/items`, header)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const getLayerIotList = (layerId) => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const body = {
      layer_id: layerId,
    };
    axios
      .post(`${IOT_SERVER}/items/gis_layer/detail`, body, header)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const postAddLayerId = (layerId, datasetId) => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const body = {
      layer_id: layerId,
      dataset_id: datasetId,
    };
    axios
      .post(`${IOT_SERVER}/items/add_layer_id`, body, header)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const deleteLayerId = (layerId, datasetId) => {
  return new Promise((resolve) => {
    axios
      .delete(`${IOT_SERVER}/items/delete_layer_id/${layerId}/${datasetId}`, {
        headers: {
          accesstoken: localStorage.token_mapid,
        },
      })
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const getUpdateIoTGeo = (layerId) => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .get(`${IOT_SERVER}/items/update_iot_data/${layerId}`, header)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const converDate = (time) => {
  let date = new Date(time);
  const hour = date.getHours() < 9 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 9 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds =
    date.getSeconds() < 9 ? `0${date.getSeconds()}` : date.getSeconds();
  return `${hour}:${minutes}:${seconds}`;
};

export const actionsIotData = (item) => (dispatch) => {
  dispatch({
    type: "IOT_DATA",
    payload: item,
  });
};

export const iotLastDataAll = (item) => (dispatch) => {
  dispatch({
    type: "IOT_LAST_DATA_ALL",
    payload: [item],
  });
};

export const getSDATelemetry = (e) => (dispatch) => {
  if (e === "pda") {
    axios
      .get(`${IOT_SERVER}/cilicis/pda`)
      .then((result) => {
        const geo_json = {
          type: "FeatureCollection",
          features: data_list(result),
        };
        dispatch({
          type: "SDA_PDA",
          payload: result.data,
        });
        dispatch({
          type: "get_pda",
          payload: geo_json,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SDA_PDA",
          payload: [],
        });
      });
  }

  if (e === "pch") {
    axios
      .get(`${IOT_SERVER}/cilicis/pch`)
      .then((result) => {
        const geo_json = {
          type: "FeatureCollection",
          features: data_list(result),
        };
        dispatch({
          type: "SDA_PCH",
          payload: result.data,
        });
        dispatch({
          type: "get_pch",
          payload: geo_json,
        });
      })
      .catch((err) => {
        dispatch({
          type: "SDA_PCH",
          payload: [],
        });
      });
  }
};

export const getAllDatasetv3 = (params) => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .get(
        `${IOT_SERVER}/dataset/v3/read/original/${params.id}?start=${
          params.startDate
        }&finish=${params.finishDate}&hour=${true}`,
        header
      )
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const getGeoPushFeature = (params) => {
  return new Promise((resolve) => {
    const header = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    axios
      .get(
        `${IOT_SERVER}/items/geo/push/${params.itemId}/${params.layerId}`,
        header
      )
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

// Cilicis
export const getCilicisByPos = (params) => {
  return new Promise((resolve) => {
    const url = `${IOT_SERVER}/cilicis/bypos?nama_lokasi=${params.nama_lokasi}&startDate=${params.startDate}&finishDate=${params.finishDate}`;
    axios
      .get(url)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const getDetailItem = (params) => {
  return new Promise((resolve) => {
    axios
      .get(`${IOT_SERVER}/dataset/v3/item/${params.id}`)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};

export const brantasGetAllPosLatest = (e) => (dispatch) => {
  if (e === "pda") {
    axios
      .get(`${IOT_SERVER}/brantas/pos/pda/latest`)
      .then((result) => {
        dispatch({
          type: "BRANTAS_PDA",
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BRANTAS_PDA",
          payload: [],
        });
      });
  }

  if (e === "pch") {
    axios
      .get(`${IOT_SERVER}/brantas/pos/pch/latest`)
      .then((result) => {
        dispatch({
          type: "BRANTAS_PCH",
          payload: result.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BRANTAS_PCH",
          payload: [],
        });
      });
  }
};

export const brantasGetDataByPos = (e) => {
  return new Promise((resolve) => {
    const url = `${IOT_SERVER}/brantas/bypos?pos=${e.pos}&sensor=${e.sensor}&start=${e.start}&end=${e.end}`;
    axios
      .get(url)
      .then((e) => resolve(e.data))
      .catch((err) => {});
  });
};
