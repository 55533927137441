import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
// import { HotTable } from "@handsontable/react";
import { connect } from "react-redux";

import { snackbar } from "../../App/actions/snackbarActions";
// import {
//   convertExcelDataToHandsontableFormat,
//   setStyleHandsonTable,
// } from "../../App/validation/convert_data";
import dict from "../../Data/dict.json";
import SheetButton from "./SheetButton";
import { getFeatureByKey, getGeoLayer } from "../../App/reduxHelper/layer";

import Modal from "../common_modal/Modal";
import EXCEL_HBU from "./EXCEL_HBU";
import HotTableWrapper from "./HotTableWrapper";
import PANGGIL_MENU from "./PANGGIL_MENU";
// import domain_list from "../../Data/domain_list";
import EXCEL_APPRAISAL from "./EXCEL_APPRAISAL";
import {
  convertGeoJsonToSheetPembanding,
  data_object_terisi,
} from "../../App/validation/kai_appraisal_function";
import { get_data_pembanding } from "../../App/actions/generate_ai_appraisal_summary";
import { CircularProgress } from "@mui/material";
import {
  set_sheet_value,
  get_sheets_by_cell,
} from "../../App/actions/sheetAction";
import { convertExcelDataToHandsontableFormatArray } from "../../App/validation/convert_data";
// import { convertExcelDataToHandsontableFormatArray } from "../../App/validation/convert_data";
// import { HyperFormula } from "hyperformula";
// import Spreadsheet from "./Spreadsheet";

registerAllModules();

class SelectTableContent extends Component {
  constructor(props) {
    super(props);

    this.data = {};
    this.state = {
      modal_ai: false,
    };
  }

  toggle_ai = () => {
    this.setState({
      modal_ai: !this.state.modal_ai,
    });
  };

  componentDidMount() {
    const { hotTableRef, handleKeyDown } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;

    // Tambahkan hook `beforeKeyDown` untuk menangkap shortcut
    if (hotInstance) hotInstance?.addHook("beforeKeyDown", handleKeyDown);
  }

  // componentDidMount() {
  //   const { hotTableRef, handleKeyDown } = this.props;
  //   const hotInstance = hotTableRef?.current?.hotInstance;

  //   // Tambahkan hook `beforeKeyDown` untuk menangkap shortcut
  //   if (hotInstance) hotInstance?.addHook("beforeKeyDown", handleKeyDown);

  //   const {
  //     worksheet,
  //     setCalculatedSheets
  //   } = this.props;

  //   const data = convertExcelDataToHandsontableFormatArray(worksheet);

  //   this.hyperformulaInstance = HyperFormula.buildFromSheets(data, hyperformulaConfigInput);
  //   const calculatedSheeData = this.hyperformulaInstance.getAllSheetsValues();
  //   setCalculatedSheets(calculatedSheeData);
  // }

  // handleBeforeKeyDown = (event) => {
  //   const { hotTableRef, handleKeyDown } = this.props;
  //   const hotInstance = hotTableRef?.current?.hotInstance;
  //   const activeEditor = hotInstance?.getActiveEditor();

  //   if (activeEditor) {

  //     // const editorElement = activeEditor?.getElement();
  //     // const currentValue = editorElement?.value;

  //     // // Manipulasi atau ubah nilai di sini
  //     // if (event.key === "Enter") {
  //     //   // Misalnya, ganti nilai menjadi "Hello World" saat tekan Enter
  //     //   editorElement.value = "Hello World";
  //     // }
  //   }
  // };

  componentWillUnmount() {
    const { hotTableRef, handleKeyDown } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;

    // Hapus hook untuk menghindari kebocoran event listener
    if (hotInstance) hotInstance?.removeHook("beforeKeyDown", handleKeyDown);
  }

  // buat development
  dev_data_object = () => {
    const { worksheet } = this.props;
    const data_object = structuredClone(worksheet[1]);
    const new_data_object = {
      ...data_object[1],
      _id: data_object?._id,
      feature_key: data_object?.feature_key,
      field_key: data_object?.field_key,
      geo_layer_id: data_object?.geo_layer_id,
      metadata: { ...data_object_terisi.metadata },
      value: [...data_object_terisi.value],
    };
    return new_data_object;
  };

  handle_get_data_pembanding = async () => {
    // perlu dihapus
    const development_worksheet = this.props.worksheet;
    let worksheet = JSON.parse(JSON.stringify(development_worksheet));

    const parameter_data_pembanding =
      this.get_kebutuhan_data_pembanding(worksheet);
    const { sheetIndexDataPembanding } = parameter_data_pembanding;

    const {
      latitude,
      longitude,
      setWorksheet,
      setRawSheets,
      rawSheets,
      hyperformulaInstance,
      geo_layer_id,
      feature_key,
      field_key,
    } = this.props;

    const body = {
      latitude,
      longitude,
      ...parameter_data_pembanding, //tipeProperti, luasTanah, tahunDibangun
    };

    let res_data_pembanding = await this.props.get_data_pembanding(body);

    if (res_data_pembanding?.status === 200) {
      if (res_data_pembanding?.data?.length > 0) {
        res_data_pembanding = res_data_pembanding?.data.map((item, index) => {
          const index_for_worksheet = sheetIndexDataPembanding?.[index];
          const sheet_id = worksheet?.[index_for_worksheet]?._id;
          return {
            ...item,
            _id: sheet_id,
          };
        });

        const sheet_data_pembanding = convertGeoJsonToSheetPembanding({
          features: res_data_pembanding,
          geo_layer_id,
          feature_key,
          field_key,
        });

        for (const [
          index_for_dataPembanding,
          index_for_worksheet,
        ] of sheetIndexDataPembanding.entries()) {
          if (sheet_data_pembanding?.[index_for_dataPembanding]) {
            worksheet[index_for_worksheet] =
              sheet_data_pembanding?.[index_for_dataPembanding];
            if (
              sheet_data_pembanding?.[index_for_dataPembanding]?.value?.length >
              0
            ) {
              await this.props.set_sheet_value({
                sheet_id: worksheet?.[index_for_worksheet]?._id, //template
                value: sheet_data_pembanding?.[index_for_dataPembanding]?.value,
              });
            }
          }
        }

        const body = {
          geo_layer_id,
          feature_key,
          field_key,
        };

        const data = await this.props.get_sheets_by_cell(body);
        const sheetData = convertExcelDataToHandsontableFormatArray(data);
        if (hyperformulaInstance) {
          Object.keys(rawSheets).forEach((sheetName) => {
            const sheet_id = hyperformulaInstance.getSheetId(sheetName);
            if (sheet_id)
              hyperformulaInstance.setSheetContent(
                sheet_id,
                sheetData[sheetName]
              );
          });
        }

        setRawSheets(sheetData);
        setWorksheet(data?.length > 0 ? data : null);
      }
    }
  };

  handle_generate_data_objek = async () => {
    const development_worksheet = this.props.worksheet;
    const { setWorksheet } = this.props;
    let worksheet = JSON.parse(JSON.stringify(development_worksheet));
    const data_objek_sheet_index = worksheet.findIndex(
      (sheet) => sheet?.metadata?.id_ori === "Data Objek"
    );
    worksheet[data_objek_sheet_index] = this.dev_data_object();

    setWorksheet(worksheet);
  };

  get_kebutuhan_data_pembanding = (worksheet) => {
    const nam_data_pembanding = [
      "Data Pembanding 1",
      "Data Pembanding 2",
      "Data Pembanding 3",
    ];
    let sheetIndexDataObject = -1;
    let sheetIndexDataPembanding = [];

    for (const [index, sheet] of worksheet?.entries()) {
      if (sheet?.metadata?.id_ori === "Data Objek") {
        sheetIndexDataObject = index;
      } else if (nam_data_pembanding.includes(sheet?.metadata?.id_ori)) {
        sheetIndexDataPembanding.push(index);
      }
    }

    let tipeProperti = "";
    let luasTanah = 0;
    let tahunDibangun = 0;

    worksheet?.[sheetIndexDataObject]?.value?.forEach((item) => {
      if (item?.p === "E6") {
        tipeProperti = item?.v;
      } else if (item?.p === "E10") {
        luasTanah = item?.v;
      } else if (item?.p === "E26") {
        tahunDibangun = item?.v;
      }
    });

    return {
      tipeProperti,
      luasTanah,
      tahunDibangun,
      sheetIndexDataObject,
      sheetIndexDataPembanding,
    };
  };

  render() {
    const language = localStorage?.language || "ina";
    const { modal_ai } = this.state;
    const {
      define_need_to_show,
      selectedCell,
      uploaded_file,
      focused,
      selectedSheet,
      sheetnames,
      setSheetnames,
      setSelectedSheet,
      setWorksheet,
      handleRefCol,
      // handle_selected_cell,
      // setFocused,
      // handle_edit_cell,
      // hotTableRef,
      // isDeleting,
      column_type,
      cloneTemplate,
      worksheet,
      is_loading_clone,
      // hyperformulaInstance
      calculatedSheets,
      setRawSheets,
      rawSheets,
      hyperformulaInstance,
    } = this.props;

    // const sheet =
    //   worksheet?.find((obj) => obj?.metadata?.id_ori === selectedSheet) || {};
    // const is_mobile = window.innerWidth < 600;

    // const domain = this.props.auth.domain;
    // const is_domain_kai = domain_list?.[domain]?.is_domain_kai;

    let textfield = "";
    if (selectedCell?.f) {
      textfield = `=${selectedCell?.f}`;
    } else if (selectedCell?.R) {
      const { sheet_id, cell_ref } = selectedCell?.R;
      textfield = `@ref?id=${sheet_id}&cell=${cell_ref}`;
    } else {
      textfield = selectedCell?.v || "";
    }

    // const { data, columns } = convertExcelDataToHandsontableFormat({
    //   sheet,
    //   selectedSheet,
    //   rawData: worksheet,
    // });

    let summary_content = null;
    if (column_type === "sheet_hbu") {
      summary_content = (
        <EXCEL_HBU
          hyperformulaInstance={hyperformulaInstance}
          setWorksheet={setWorksheet}
          setRawSheets={setRawSheets}
          rawSheets={rawSheets}
          dbWorksheet={worksheet}
          worksheet={calculatedSheets}
          geo_layer_id={this.props.geo_layer_id}
          feature_key={this.props.feature_key}
          field_key={this.props.field_key}
        />
      );
    } else {
      summary_content = (
        <EXCEL_APPRAISAL
          worksheet={calculatedSheets}
          geo_layer_id={this.props.geo_layer_id}
          feature_key={this.props.feature_key}
          field_key={this.props.field_key}
        />
      );
    }

    const modal_ai_content = modal_ai && (
      <Modal
        title="AI Excel"
        id="modal_ai_content"
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="5px"
        isOpen={modal_ai}
        onClose={this.toggle_ai}
      >
        <div className="box-body" id="box-body" style={{ overflow: "hidden" }}>
          {summary_content}
        </div>
      </Modal>
    );

    let content_button_clone = null;
    if (is_loading_clone) {
      content_button_clone = <CircularProgress />;
    } else {
      if (column_type === "sheet_hbu") {
        content_button_clone = (
          <>
            {["HBU Tanah Kosong", "HBU Terbangun"].map((key, idx) => (
              <button
                key={idx}
                className="button bg_blue text_white h_30 hover_bigger marginRight_20 marginTop_10"
                onClick={async () => {
                  cloneTemplate(key);
                  this.toggle_ai();
                }}
              >
                {`Use ${key}`}
              </button>
            ))}
          </>
        );
      } else {
        content_button_clone = (
          <button
            className="button bg_blue text_white h_30 hover_bigger marginRight_20 marginTop_10"
            onClick={async () => {
              cloneTemplate();
              this.toggle_ai();
            }}
          >
            {`Use Appraisal`}
          </button>
        );
      }
    }

    const button_use_template = (
      <div className="h_full center_right">{content_button_clone}</div>
    );

    const is_use_template = ["sheet_hbu", "sheet_appraisal"].includes(
      column_type
    );
    const is_button_use_template_showing = is_use_template && !worksheet;

    return (
      <main>
        {modal_ai_content}
        {!worksheet && (
          // '(!["hbu", "appraisal"].includes(from)) &&
          <div>
            {is_button_use_template_showing && button_use_template}
            <label>Upload your Sheet (.xlsx)</label>
            <div className="border_dash">
              <FileUploader
                classes="container_upload absolute h_full pointer"
                handleChange={(e) => {
                  define_need_to_show(e);
                }}
                name="file"
                types={["xlsx"]}
                onTypeError={(e) => {
                  this.props.snackbar({
                    is_open: true,
                    status: "error",
                    message: e,
                  });
                }}
                children={
                  <div className="container_upload center_perfect">
                    <div>
                      {uploaded_file?.fileName ||
                        `${dict["Drop a file here"][language]}`}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {/* {
                worksheet && <Button onClick={() => this.on_dowload_json()}>
                  Download
                </Button>
              } */}
        {/* {
          column_type === "sheet_appraisal" && worksheet && <button className="button bg_blue" onClick={() => { this.handle_generate_data_objek() }}>
            Generate data objek (development only)
          </button>
        } */}

        {column_type === "sheet_appraisal" && worksheet && (
          <button
            className="button bg_blue"
            onClick={() => {
              this.handle_get_data_pembanding();
            }}
          >
            Generate Data Pembanding
          </button>
        )}

        {worksheet && (
          <section>
            <div className="flex gap_10">
              <input
                type="text"
                value={textfield}
                disabled
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  padding: "8px",
                  fontSize: "16px",
                  display: "inline-block",
                }}
              />

              <PANGGIL_MENU handleRefCol={handleRefCol} focused={focused} />

              {/* {focused ? (
                <Button
                  id="ref_button"
                  onClick={handleRefCol}
                  style={{ height: "40px" }}
                  variant="outlined"
                >
                  Ref
                </Button>
              ) : (
                <Button
                  id="ref_button"
                  onClick={handleRefCol}
                  style={{ height: "40px" }}
                  variant="outlined"
                  disabled={true}
                >
                  Ref
                </Button> 
              )}*/}
              {is_use_template && (
                <button
                  id="chat_ai"
                  onClick={this.toggle_ai}
                  style={{ height: "40px", width: "200px" }}
                  variant="outlined"
                  className="button_big background_black"
                >
                  {`${dict["Summary"][language]} ${
                    column_type === "sheet_hbu" ? "HBU" : "Appraisal"
                  }`}
                </button>
              )}
            </div>

            {/* <Spreadsheet /> */}

            <HotTableWrapper
              setSelectedSheet={setSelectedSheet}
              selectedSheet={selectedSheet}
              textfield={textfield}
              {...this.props}
            />

            {worksheet && (
              <div className="w_full scroll_x_auto">
                <div className="w_full flex_row flex align_center no_wrap gap_10 h_50">
                  <label>Sheet:</label>
                  {sheetnames?.map((item) => (
                    <SheetButton
                      key={item?.id_ori}
                      sheetname={item}
                      selectedSheet={selectedSheet}
                      sheetnames={sheetnames}
                      setSheetnames={setSheetnames}
                      setSelectedSheet={setSelectedSheet}
                      setWorksheet={setWorksheet}
                      worksheet={worksheet}
                    />
                  ))}
                </div>
              </div>
            )}
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state, props) => {
  const geo_layer = getGeoLayer(state.layer);
  const feature = getFeatureByKey({
    geo_layer,
    feature_key: props.feature_key,
  });
  const [longitude, latitude] = feature?.geometry?.coordinates;
  return {
    longitude,
    latitude,
    geo_layer_id: geo_layer._id,
    fields: geo_layer?.fields,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  snackbar,
  get_data_pembanding,
  set_sheet_value,
  get_sheets_by_cell,
})(SelectTableContent);
