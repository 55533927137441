/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { InsertChart } from "@material-ui/icons";
import { timeout } from "workbox-core/_private";
import { CircularProgress } from "@mui/material";

/*COMPONENT*/
// import RANGKING_CHART from "./RANKING_CHART";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import ProgressBar from "../common_spinner/ProgressBar";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  // get_ai_appraisal_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { get_sini_data } from "../../App/actions/get_sini_data";
import { generate_ai_appraisal_summary, get_ai_appraisal_summary } from "../../App/actions/generate_ai_appraisal_summary";

/*PICTURE*/

/*FUNCTION*/
import uuid from "../../App/validation/uuid";
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import { numberToColumn } from "../../App/validation/calculateExcelFormula";
// import { convertGeoJsonToSheetPembanding } from "../../App/validation/kai_appraisal_function";

/*DATA*/

/*CONST*/

class EXCEL_APPRAISAL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // sheet_array: [],
      user_message: "",
      modal_re_appraisal: false,
      user_message_appraisal: "",
      is_chat_ai_show: false,
      is_loading: false,
      is_timeout: false,
    };

  }

  componentDidMount() {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_appraisal_summary(params);
    this.props.get_ai_sheet_chat_list(params);
    //advance
    const { feature_object_selected } = this.props.layer;
    const long = feature_object_selected?.geometry?.coordinates?.[0];
    const lat = feature_object_selected?.geometry?.coordinates?.[1];
    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini);
  }

  toggle_re_appraisal = () => {
    this.setState({
      modal_re_appraisal: !this.state.modal_re_appraisal,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_generate_ai_appraisal_summary = async () => {
    this.setState({ is_loading: true }, async () => {
      const { user_message_appraisal } = this.state;
      const { geo_layer_id, feature_key, field_key, worksheet } = this.props;
      const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
      let sini_object = ai_parent_detail_list.find(
        (item) => item.request_id === sheet_object_selected.request_id
      );

      // const sini_not_used_list = [
      //   "people_spending",
      //   "rdtr_neighbor",
      //   "rdtr_result_array",
      //   "rdtr_result_object",
      //   "rdtr_one",

      //   "landslide_neighbor",
      //   "landvalue_neighbor",
      //   "flood_neighbor",
      //   "flood_one",
      //   "tsunami_neighbor",

      //   "jenis_bangunan_resume",
      //   "poi_features",
      //   "poi_resume_1",
      //   "poi_resume_2",
      //   "poi_resume_3",
      //   "poi_survey_1_features",

      //   "demography_neighbor",
      //   "demography_one",
      //   "geojson_polygon_isochrone"
      // ]

      // for await (const key of sini_not_used_list) {
      //   if (sini_object?.[key]) delete sini_object[key];
      // }

      // =============================

      const sheet_array = Object.keys(worksheet).map(item => {
        let sheet = worksheet[item]
        sheet[0][0] = item;
        return this.convert2DArrayToObjects(sheet)
      })

      const body = {
        worksheet: sheet_array,
        geo_layer_id,
        feature_key,
        field_key,
        sini_object,
        user_message_appraisal,
      };


      const is_timeout = await this.props.generate_ai_appraisal_summary(body) === "Timeout";
      if (is_timeout) await timeout(25000);

      this.setState({
        user_message_appraisal: "",
        modal_re_appraisal: false,
        is_loading: false,
        is_timeout: is_timeout ? true : false
      });
    })
  };

  convert2DArrayToObjects = (arr) => {
    return arr.map(row => {
      return row.reduce((obj, value, index) => {
        if (value !== null && value !== "") {
          obj[numberToColumn(index)] = value;
        }
        return obj;
      }, {});
    });
  }

  reset_ai_appraisal_summary = () => {
    const { geo_layer_id, feature_key, field_key, worksheet } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    const sheet_array = Object.keys(worksheet).map(item => {
      let sheet = worksheet[item]
      sheet[0][0] = item;
      return this.convert2DArrayToObjects(sheet)
    })

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_appraisal: "",
    };
    this.props.generate_ai_appraisal_summary(body);
    this.setState({
      user_message_appraisal: "",
      modal_re_appraisal: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_appraisal_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_appraisal_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show
    })
  }

  formatRupiah = (value) => {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 2, // Selalu tampilkan dua angka desimal
      maximumFractionDigits: 2,
    });

    // Format nilai, tambahkan tanda negatif jika diperlukan
    return value < 0 ? `-Rp${formatter.format(Math.abs(value)).replace('Rp', '')}` : formatter.format(value);
  }

  handle_refresh = () => {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.setState({ is_loading: true }, async () => {
      await this.props.get_ai_appraisal_summary(params);
      this.setState({
        is_loading: false
      })
    })
  }

  render() {
    //local storage

    //local state
    const { user_message, modal_re_appraisal, user_message_appraisal, is_chat_ai_show, is_loading, is_timeout } = this.state;

    //global props
    const { is_mobile } = this.props.properties;
    const { loading_item_array } = this.props.loading_reducer;
    const {
      total_step,
      current_step,
      current_topic_loading,
      ai_appraisal_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
    } = this.props.sini;

    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;

    //content
    const modal_re_appraisal_content = modal_re_appraisal && (
      <Modal
        modalSize="small"
        isOpen={modal_re_appraisal}
        onClose={this.toggle_re_appraisal}
      >
        <div className="box-body" id="box-body">
          <textarea
            className="margin_bottom"
            rows={6}
            placeholder="Masukkan pesan untuk pembaruan summary"
            value={user_message_appraisal}
            name="user_message_appraisal"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button_big background_black margin_right margin_bottom"
            onClick={() => {
              this.on_generate_ai_appraisal_summary();
              this.setState({ modal_re_appraisal: false });
            }}
          >
            Perbarui Summary
          </button>
          <button
            className="button_big background_black"
            onClick={this.reset_ai_appraisal_summary}
          >
            Reset
          </button>
        </div>
      </Modal>
    );


    const summary_result = ai_appraisal_summary_object?.summary_result;
    let summary_content, sub_content;
    let keterangan = ""
    if (!summary_result) {
      if ((loading_item_array.includes("generate_ai_appraisal_summary") || is_loading)) {
        keterangan = "Sedang dilakukan perangkuman Appraisal..."
        sub_content = <button className="button_big background_grey">
          <CircularProgress size={20} />
        </button>
      } else if (!ai_sini_sheet_object?.request_id) {
        keterangan = "Tunggu, sedang memuat..."
        sub_content = <button className="button_big background_grey"><CircularProgress size={20} /></button>
      } else {
        keterangan = is_timeout ? "Klik tombol di bawah untuk memuat hasil rangkuman" : "Rangkumkan sheet Appraisal"
        sub_content =
          <>
            {is_timeout ?
              <button
                className="button_big background_black"
                onClick={() => { this.handle_refresh() }}
              >
                <InsertChart />
              </button>
              :
              <button
                className="button_big background_black marginRight_10"
                onClick={this.on_generate_ai_appraisal_summary}
              >
                Rangkum
              </button>
            }
          </>
      }

      summary_content = (
        <section className="text_center container_light outline_transparent background_grey_light">
          <p className="text_bold margin_bottom">
            {keterangan}
          </p>
          <br />
          {sub_content}
          <br />
        </section>
      );
    } else {
      // const appraisal_best_alternative = summary_result?.appraisal_best_alternative || "";
      // const appraisal_best_alternative_reason =
      //   summary_result?.appraisal_best_alternative_reason || "";
      // const appraisal_best_alternative_notes =
      //   summary_result?.appraisal_best_alternative_notes || "";
      // const appraisal_alternative_array = summary_result?.appraisal_alternative_array || [];

      const {
        data_object_appraisal = "",
        data_pembanding = [],
        ringkasan_penilaian = {},
        pendekatan_pasar,
        pendekatan_biaya
      } = summary_result || {};

      const { bangunan, tanah, sarana, nilai_pasar } = ringkasan_penilaian
      const { indikasi_nilai_pasar_properti_m2, indikasi_nilai_pasar_properti, range } = pendekatan_pasar
      const {
        replacement_cost_new_rcn,
        total_luas_bangunan,
        biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2,
        total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik
      } = pendekatan_biaya

      summary_content = (
        <section
          style={{
            overflowY: "auto",
            height: "100%",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 className="margin_bottom">Appraisal SUMMARY</h1>
          <section className="margin_bottom">
            {loading_item_array.includes("generate_ai_appraisal_summary") ? (
              <button className="button_big background_grey">
                AI SEDANG MELAKUKAN SUMMARY...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button className="button_big background_grey">WAIT</button>
            ) : (
              <button
                className="button_big background_black"
                onClick={this.toggle_re_appraisal}
              >
                Perbarui Ringkasan Appraisal dengan Prompt
              </button>
            )}
          </section>

          <aside className="container_column">
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{data_object_appraisal}</p>
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Provinsi</label>
                <p className="text_bold">{PROVINSI}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kabupaten atau Kota</label>
                <p className="text_bold">{KABKOT}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kecamatan</label>
                <p className="text_bold">{KECAMATAN}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Desa atau Kelurahan</label>
                <p className="text_bold">{DESA}</p>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Ringkasan Penilaian
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Tanah</label>
                <p className="text_bold">{this.formatRupiah(tanah)}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Bangunan</label>
                <p className="text_bold">{this.formatRupiah(bangunan)}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Sarana</label>
                <p className="text_bold">{this.formatRupiah(sarana)}</p>
              </div>
              <hr />
              <div className="margin_bottom">
                <label className="text_small">Nilai Pasar</label>
                <p className="text_bold">{this.formatRupiah(nilai_pasar)}</p>
              </div>

            </div>


            {
              data_pembanding.map((pembanding, idx) => {
                const { tipe_properti, jumlah_lantai, luas_bangunan, luas_tanah, alamat, indikasi_nilai_tanah_m2 } = pembanding
                return (
                  <div key={idx} className="child_tile container_light background_grey_light outline_transparent">
                    <h1 className="text_bold margin_bottom">
                      {`Data Pembanding ${idx + 1}`}
                    </h1>
                    <div className="margin_bottom">
                      <label className="text_small">Tipe Properti</label>
                      <p className="text_bold">{tipe_properti}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Alamat</label>
                      <p className="text_bold">{alamat}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Luas Tanah /m2</label>
                      <p className="text_bold">{new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(luas_tanah))}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Luas Bangunan /m2</label>
                      <p className="text_bold">{new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(luas_bangunan))}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Jumlah Lantai</label>
                      <p className="text_bold">{new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(jumlah_lantai))}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Indikasi nilai tanah /m2</label>
                      <p className="text_bold">{this.formatRupiah(indikasi_nilai_tanah_m2)}</p>
                    </div>
                  </div>
                )
              })
            }

            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Pendekatan Pasar
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Indikasi Nilai Pasar Properti /m2</label>
                <p className="text_bold">{this.formatRupiah(indikasi_nilai_pasar_properti_m2)}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Indikasi Nilai Pasar Properti</label>
                <p className="text_bold">{this.formatRupiah(indikasi_nilai_pasar_properti)}</p>
              </div>

              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Range:</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Max:  `}</label>
                      <label className="bold">{this.formatRupiah(range.max)}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Min:  `}</label>
                      <label className="bold">{this.formatRupiah(range.min)}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Deviasi:  `}</label>
                      <label className="bold">{new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      })?.format(Number(range.deviasi))}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom ">
                Pendekatan Biaya
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Replacement Cost New (RCN)</label>
                <p className="text_bold">{this.formatRupiah(replacement_cost_new_rcn)}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Total Luas Bangunan</label>
                <p className="text_bold">{new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                })?.format(Number(total_luas_bangunan))}</p>
              </div>

              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Biaya Pengganti Baru Bangunan & SARPARAS Sesuai Fisik /m2</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`RCN:  `}</label>
                      <label className="text_bold">{this.formatRupiah(biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2.rcn)}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`Nilai Pasar:  `}</label>
                      <p className="text_bold">{
                        this.formatRupiah(
                          biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2.nilai_pasar
                        )
                      }</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Total Biaya Pengganti Baru Bangunan & SARPARAS Sesuai Fisik</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`RCN:  `}</label>
                      <p className="text_bold">{this.formatRupiah(
                        total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik.rcn
                      )
                      }</p>
                    </div>
                  </li>
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`Nilai Pasar:  `}</label>
                      <p className="text_bold">{
                        this.formatRupiah(
                          total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik.nilai_pasar
                        )
                      }</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>


          </aside>


        </section>
      );
    }

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button background_blue"
                style={{
                  alignSelf: "flex-start",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );
    let content;

    const { summary_sini = "" } = summary_result || {}

    if (!is_mobile) {
      content = (
        <main
          style={{ display: "flex", gap: "10px", height: "calc(100vh - 75px)" }}
        >
          <section
            style={{
              width: "50%",
            }}
          >
            {summary_content}
          </section>

          <section
            style={{
              display: "flex",
              height: "100%",
              width: "50%",
              gap: "15px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <div style={{ height: "80%" }}>
                <MAP_EXCEL />
              </div>
              <div
                className="child_tile container_light background_grey_light outline_transparent"
                style={{ height: "20%" }}>
                <div>
                  <label className="text_bold"> Summary Appraisal </label>
                  <p>{summary_sini}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "300px",
                overflowY: "auto",
              }}
            >
              <PARENT_SINI from="ai_appraisal" />
            </div>
          </section>

          <section
            style={{
              visibility: is_chat_ai_show ? "visible" : "hidden",
              width: is_chat_ai_show ? "300px" : "0px",
              flexShrink: 0,
              flexGrow: 0,
              height: "100%",
            }}
          >
            {chat_content}
          </section>
        </main>
      );
    } else {
      content = <main>Mode mobile not supported yet</main>;
    }

    // const button_ai_chat = <button
    //   className="floating_button circle_button"
    //   onClick={this.toggle_chat_ai}
    // >
    //   <div className="flex flex_col">
    //     <span className="w_full flex justify_center">
    //       <QuestionAnswerIcon />
    //     </span>
    //     <label className="font_10 pointer" >Chat AI</label>
    //   </div>
    // </button>

    return (
      <main>
        {modal_re_appraisal_content}
        {content}
        {/* {button_ai_chat} */}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_appraisal_summary,
  get_ai_appraisal_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
  get_sini_data,
})(EXCEL_APPRAISAL);
