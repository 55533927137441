import axios from "axios";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

export const set_value_hbu = ({ key, value }) => {
  return {
    type: "set_value_hbu",
    payload: { key, value },
  };
};

export const set_hbu_reducer_from_db = (body) => {
  return {
    type: "set_hbu_reducer_from_db",
    payload: body,
  };
};

export const set_hbu_reducer_into_intitial = () => {
  return {
    type: "set_hbu_reducer_into_intitial",
  };
};

/**
 * @route : update_hbu_result_object
 * @endpoint : /sini_v2/ai_excel/update_hbu_result_object
 * @methode : post
 * @params :-
 * @body : request_id, hbu_result_object
 */
export const update_hbu_result_object = (body) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { request_id, hbu_result_object } = body;
    const body_post = {
      request_id,
      hbu_result_object,
    };
    await axios.post(
      bun_server + `/sini_v2/ai_excel/update_hbu_result_object`,
      body_post,
      config
    );
  } catch (error) {}
};
