//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
//Personal Component
//Redux function
import { uploadSquareLayer } from "../../App/actions/layerActions";
//Picture Asset
//General Function
import dict from "../../Data/dict.json";
import uuid from "../../App/validation/uuid";
class UploadBanner extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }
  handleSubmit(e) {
    const { geo_layer_wo_geojson } = this.props.layer;
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    const objParams = {
      file: file,
      file_name: "square_form_" + uuid(),
      dirname: "square_form",
    };
    let finalObject;
    finalObject = {
      objParams,
      geo_layer_id: geo_layer_wo_geojson?.geo_layer?._id,
    };
    this.props.uploadSquareLayer(finalObject);
  }
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      // loadingProcess,
      // itemLoading,
      geo_layer_wo_geojson,
    } = this.props.layer;
    let itemContent = (
      <label
        className="fileContainer"
        style={{
          backgroundImage: `url(${
            geo_layer_wo_geojson?.geo_layer?.picture_url_square
              ? geo_layer_wo_geojson?.geo_layer?.picture_url_square[0]?.url
              : ""
          })`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: "#dedede",
          width: "100%",
          paddingBottom: "100%",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20px",
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: "2rem",
            padding: "5px 10px 5px 10px",
            boxShadow: "0 0 6px #9d9d9d53",
          }}
        >
          {dict["Click to change the square banner (ratio 1 : 1)"][language]}
        </div>
        <input
          style={{ cursor: "pointer" }}
          type="file"
          accept="image/*"
          ref={this.fileInput}
          onChange={this.handleSubmit}
          data-mapid="uploadRatio-1"
        />
      </label>
    );
    return <div>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  layer: state.layer,
});
export default connect(mapStateToProps, {
  uploadSquareLayer,
})(UploadBanner);
