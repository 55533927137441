/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const color_line = "#104585";
const color_fill = "#104585";

class LAYER_S_ISOCHRONE extends Component {
  componentDidUpdate(prevProps) {
    // const map_object_string = this.props.map_object_string || "map_object";
    // const map_object = this.props.layer[map_object_string];

    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      // if (map_object && map_object.isStyleLoaded()) {
      //   this.on_render_layer();
      // } else if (map_object) {
      //   map_object.once("styledata", this.on_render_layer);
      // }
      this.on_render_layer();
    }

    const fly_init_update_after = this.props.sini.fly_init_update;
    const fly_init_update_before = prevProps.sini.fly_init_update;
    if (
      fly_init_update_after !== fly_init_update_before &&
      !this.props.sini.is_stop_zoom
    ) {
      this.on_fly_init();
    }
  }

  on_fly_init = () => {
    const { request_id_active, sini_v2_list, ai_parent_detail_list } =
      this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (merge_array.length > 0) {
      const sini_object = merge_array.find(
        (item) => item.request_id === request_id_active
      );

      if (sini_object) {
        const long = sini_object?.long;
        const lat = sini_object?.lat;
        if (long && lat) {
          const map_object_string =
            this.props.map_object_string || "map_object";
          const map_object = this.props.layer[map_object_string];
          if (map_object) {
            map_object.flyTo({
              center: [long, lat],
              zoom: 13,
            });
          }
        }
      }
    }
  };

  on_render_layer = () => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      is_sini_visible,
    } = this.props.sini;
    const visibility =
      sini_map_show.includes("isochrone") && is_sini_visible
        ? "visible"
        : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];

    if (!!map_object) {
      merge_array.forEach((item) => {
        const { geojson_polygon_isochrone, request_id } = item;
        const geojson_polygon = geojson_polygon_isochrone || {
          type: "FeatureCollection",
          features: [],
        };
        const layer_id_fill = `geojson_polygon_${request_id}`;
        const layer_id_line = `geojson_polygon_line_${request_id}`;

        if (!map_object.getSource(layer_id_fill)) {
          map_object.addSource(layer_id_fill, {
            type: "geojson",
            data: geojson_polygon,
          });
        } else {
          map_object.getSource(layer_id_fill).setData(geojson_polygon);
        }

        // Ensure the layer exists before setting before_layer_id
        const before_layer_id = map_object.getLayer(`poi_${request_id}`)
          ? `poi_${request_id}`
          : undefined;

        if (!map_object.getLayer(layer_id_fill)) {
          map_object.addLayer(
            {
              id: layer_id_fill,
              source: layer_id_fill,
              type: "fill",
              paint: {
                "fill-color": color_fill,
                "fill-opacity": 0.3,
              },
              layout: {
                visibility: visibility,
              },
            },
            before_layer_id
          );
        } else {
          map_object.setLayoutProperty(layer_id_fill, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_fill,
            "fill-opacity",
            Number(sini_polygon_fill_opacity)
          );

          // Dynamically move layer if before_layer_id updates
          if (before_layer_id) {
            map_object.moveLayer(layer_id_fill, before_layer_id);
          }
        }

        if (!map_object.getLayer(layer_id_line)) {
          map_object.addLayer(
            {
              id: layer_id_line,
              source: layer_id_fill,
              type: "line",
              paint: {
                "line-color": color_line,
                "line-width": 1,
                "line-opacity": 1,
                "line-gap-width": 0,
              },
              layout: {
                visibility: visibility,
              },
            },
            before_layer_id
          );
        } else {
          map_object.setLayoutProperty(layer_id_line, "visibility", visibility);
          map_object.setPaintProperty(
            layer_id_line,
            "line-width",
            Number(sini_polygon_line_width)
          );

          // Dynamically move layer if before_layer_id updates
          if (before_layer_id) {
            map_object.moveLayer(layer_id_line, before_layer_id);
          }
        }
      });
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_ISOCHRONE);
