// const formula = require('formulajs');
// const { find, map, filter, reduce, sort } = require('./manual_loop')
// const math_regex = /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/;

const numberToColumn = (num) => {
  let col = "";
  while (num > 0) {
    const remainder = (num - 1) % 26;
    col = String.fromCharCode("A".charCodeAt(0) + remainder) + col;
    num = Math.floor((num - 1) / 26);
  }
  return col;
};

// Fungsi untuk mengonversi huruf kolom (seperti A, AA) ke indeks angka
const columnToNumber = (col) => {
  let number = 0;
  for (let i = 0; i < col.length; i++) {
    number = number * 26 + (col.charCodeAt(i) - "A".charCodeAt(0) + 1);
  }
  return number;
};

// function getSheetData({ sheetName, rawData }) {
//   if (!sheetName) return;
//   const sheet = find(rawData, (item) => item.metadata.id_ori === sheetName);
//   // const sheet = rawData.find((item) => item.metadata.id_ori === sheetName);
//   if (!sheet) return;
//   return sheet.value;
// }

// function getValue(sheetName, sheet, cellRef, rawData, emptyDefault = 0) {
//   if (sheet) {
//     // const cell = sheet.find(row => row.p === cellRef);
//     const cell = find(sheet, row => row.p === cellRef);
//     if (cell?.f) {
//       return calculateExcelFormula({
//         sheetName, // Extract sheet name from cellRef
//         cell,
//         rawData: rawData
//       });
//     } else if (cell?.v) {
//       return cell?.v
//     }
//     return emptyDefault
//   } else {
//     return "#REF!"
//   }
// }

// function parseReference(reference) {
//   // const match = reference.match(/^'?([\w\s\-.]+)'?!([A-Z]+\d+)$/);
//   const match = reference.match(/^'?([\w\s\-.,]+)'?!([A-Z]+\d+(:[A-Z]+\d+)?)$/);
//   if (match) {
//     return { sheetName: match[1], cellRef: match[2] };
//   }
//   return { sheetName: null, cellRef: reference };
// }

// const getAllSelectedValue = ({ rangeCode, sheetName, rawData, emptyDefault }) => {
//   const [startCell, endCell] = map(rangeCode.split(':'), cell => cell.trim());
//   const startRow = parseFloat(startCell.match(/\d+/)[0]);
//   const endRow = parseFloat(endCell.match(/\d+/)[0]);
//   const startCol = startCell.match(/^[A-Z]+/)[0];
//   const endCol = endCell.match(/^[A-Z]+/)[0];

//   let sheetData = getSheetData({ sheetName, rawData });
//   const values = [];

//   const startColNum = columnToNumber(startCol);
//   const endColNum = columnToNumber(endCol);

//   for (let row = startRow; row <= endRow; row++) {
//     for (let colNum = startColNum; colNum <= endColNum; colNum++) {
//       const colLetter = numberToColumn(colNum);
//       let new_value = String(getValue(sheetName, sheetData, `${colLetter}${row}`, rawData, emptyDefault));
//       if (isOuterParenthesesUnified(new_value)) {
//         new_value = removeFirstAndLastChar(new_value)

//       }
//       values.push(!isNaN(new_value) ? +new_value : new_value);
//     }
//   }

//   return values;
// }

// const getAllSelectedValueWithoutEmpty = ({ rangeCode, sheetName, rawData, emptyDefault }) => {
//   const [startCell, endCell] = map(rangeCode.split(':'), cell => cell.trim());
//   const startRow = parseFloat(startCell.match(/\d+/)[0]);
//   const endRow = parseFloat(endCell.match(/\d+/)[0]);
//   const startCol = startCell.match(/^[A-Z]+/)[0];
//   const endCol = endCell.match(/^[A-Z]+/)[0];

//   let sheetData = getSheetData({ sheetName, rawData });
//   const values = [];

//   const startColNum = columnToNumber(startCol);
//   const endColNum = columnToNumber(endCol);

//   for (let row = startRow; row <= endRow; row++) {
//     for (let colNum = startColNum; colNum <= endColNum; colNum++) {
//       const colLetter = numberToColumn(colNum);
//       let new_value = String(getValue(sheetName, sheetData, `${colLetter}${row}`, rawData, emptyDefault = null));
//       if (new_value) {
//         if (isOuterParenthesesUnified(new_value)) {
//           new_value = removeFirstAndLastChar(new_value)
//         }
//         values.push(!isNaN(new_value) ? +new_value : new_value);
//       }
//     }
//   }

//   return values;
// }

// function removeFirstAndLastChar(text) {
//   return text.slice(1, -1);
// }

// const get_params_of_reference = (str) => {
//   return str.match(/'?([^'!]+)'?!?([A-Z][0-9]+)/);
// }

// function splitSheetAndRange(inputString) {
//   // Memisahkan nama sheet dan rentang sel

//   const [sheetName, cellRange] = inputString.split('!');

//   // Menghapus tanda kutip dari nama sheet
//   const cleanedSheetName = sheetName.replace(/'/g, '').trim();

//   return {
//     sheetName: cleanedSheetName[0] === "'" && cleanedSheetName.at(-1) === "'" ? removeFirstAndLastChar(cleanedSheetName) : cleanedSheetName,
//     cellRange: cellRange
//   };

// }

// function formatText(text) {
//   return text.replace(/"&([^&]*)&"/g, "$1");
// }

// function evaluateTextFormula(formulaStr) {
//   let text = formatText(formulaStr)
//   text = text.replace(/\((".*?"|\(.*?\))(\s*&[A-Z]+\d+&\s*)*.*?\)/g, (match) => {
//     let newText = match
//     if ((newText.startsWith('"') || newText.startsWith('(')) && (newText.endsWith('"') || newText.endsWith(')'))) {
//       while ((newText.startsWith('"') || newText.startsWith('(')) && (newText.endsWith('"') || newText.endsWith(')'))) {
//         newText = removeFirstAndLastChar(newText);
//       }
//       return newText;
//     } else {
//       return text
//     }
//   });

//   return text;
// }

// function isOuterParenthesesUnified(expression) {
//   // Trim whitespace for a cleaner check
//   expression = String(expression).trim();

//   // Check if it starts with '(' and ends with ')'
//   if (expression.startsWith('(') && expression.endsWith(')')) {
//     // Use a stack to verify that the parentheses are properly balanced
//     let stack = [];
//     for (let i = 0; i < expression.length; i++) {
//       const char = expression[i];
//       if (char === '(') {
//         stack.push(char);
//       } else if (char === ')') {
//         stack.pop();
//         // If stack is empty and not at the last character, parentheses are not unified
//         if (stack.length === 0 && i !== expression.length - 1) {
//           return false;
//         }
//       }
//     }
//     // Ensure all parentheses are closed
//     return stack.length === 0;
//   }
//   return false;
// }

// function getLetter(huruf, angka) {
//   // Memastikan huruf adalah 'A'

//   // Menghitung nilai ASCII dari huruf 'A' dan menambahkan angka
//   const asciiValue = huruf.charCodeAt(0) + angka;

//   // Mengembalikan huruf berdasarkan nilai ASCII
//   return String.fromCharCode(asciiValue);

//   // Mengembalikan null jika huruf bukan 'A'
// }

function splitExcelReference(reference) {
  // Menggunakan regex untuk memisahkan huruf dan angka
  const regex = /^([A-Z]+)(\d+)$/;
  const match = reference.match(regex);

  if (match) {
    // Mengembalikan huruf dan angka sebagai array
    return {
      huruf: match[1], // Bagian huruf
      angka: match[2], // Bagian angka
    };
  }

  return null; // Mengembalikan null jika format tidak valid
}

function getColRowIndex(reference) {
  const colLetter = reference?.match(/[A-Z]+/)?.[0];
  const colIndex = columnToNumber(colLetter) - 1;
  const rowIndex = parseInt(reference?.match(/\d+/)[0]) - 1;
  return { rowIndex, colIndex };
}

function get_value_cell(refCell, sheets, sheetName) {
  const { rowIndex, colIndex } = getColRowIndex(refCell);
  const value = sheets?.[sheetName]?.[rowIndex]?.[colIndex];
  return typeof value === "object" ? null : value;
}

// const calculateExcelFormula = ({ sheetName, cell, rawData }) => {

//   if (!cell || !cell.f) {
//     return cell ? cell.v : undefined;
//   }

//   const formulaStr = cell.f?.replace(/\$/g, "");

//   // Mendapatkan nilai referensi antar-cell dalam formula
//   let formulaWithValues = formulaStr.replace(/'[^']+'![A-Z]+\d+|[A-Z]+\d+/g, (match) => {
//     const { sheetName: refSheet, cellRef } = parseReference(match);
//     const sheetToUse = refSheet || sheetName;
//     const sheetData = getSheetData({ sheetName: sheetToUse, rawData });
//     const result_val = getValue(sheetToUse, sheetData, cellRef, rawData);

//     if (String(result_val).includes(null) || String(result_val).includes("#N/A")) {
//       return "#N/A"
//     }

//     return !isNaN(result_val) ? `(${result_val})` : result_val
//   });

//   // Fungsi IRR
//   function calculateIRR(cashFlows, guess = 0.1) {
//     const maxIterations = 1000;
//     const precision = 1e-6;
//     let rate = parseFloat(guess);

//     for (let i = 0; i < maxIterations; i++) {
//       // let npv = cashFlows?.reduce((sum, cf, t) => sum + cf / Math.pow(1 + rate, t), 0); // eslint-disable-line no-loop-func
//       let npv = reduce(cashFlows, (sum, cf, t) => sum + cf / Math.pow(1 + rate, t), 0); // eslint-disable-line no-loop-func

//       let npvDerivative = reduce(cashFlows, (sum, cf, t) => sum - (t * cf) / Math.pow(1 + rate, t + 1), 0); // eslint-disable-line no-loop-func

//       let newRate = rate - npv / npvDerivative;

//       if (Math.abs(newRate - rate) < precision) {
//         return newRate;
//       }
//       rate = newRate;
//     }
//     throw new Error("IRR calculation did not converge.");
//   }

//   // Fungsi RANK
//   function calculateRank(value, range, order = "DESC") {
//     // const sortedRange = [...range].map(number => Number(number)).sort((a, b) => {
//     //   return (order === "ASC" ? a - b : b - a)
//     // });
//     const maped_range = map([...range], number => {
//       if (isOuterParenthesesUnified(number)) { number = removeFirstAndLastChar(number) }
//       return Number(number)
//     })

//     const sortedRange = sort(maped_range, (a, b) => (order === "ASC" ? a - b : b - a));
//     return sortedRange.indexOf(value) + 1;
//   }

//   function isValidParentheses(str) {
//     const stack = [];
//     for (let i = 0; i < str.length; i++) {
//       const char = str[i];
//       if (char === '(') {
//         stack.push(char);
//       }
//       else if (char === ')') {
//         if (stack.length === 0) {
//           return false;
//         }
//         stack.pop();
//       }
//     }
//     return stack.length === 0;
//   }

//   function replaceBoolean(formula) {
//     if (formula.includes("TRUE")) {
//       formula = formula.replaceAll("TRUE", true)
//     }

//     if (formula.includes("FALSE")) {
//       formula = formula.replaceAll("FALSE", false)
//     }

//     return formula
//   }

//   // function vlookup({ lookup_value, tableData, col_index_num, range_lookup }) {

//   //   // Mencari nilai dalam tableData
//   //   for (let i = 0; i < tableData.length; i++) {
//   //     // Mengambil nilai dari kolom pertama (C) untuk pencarian

//   //     const rowValue = tableData[i].v;

//   //     // Memeriksa apakah nilai yang dicari cocok
//   //     if (rowValue === lookup_value) {

//   //       // Mengambil nilai dari kolom yang ditentukan
//   //       const result = tableData[i + (col_index_num - 1)].v;

//   //       // Mengembalikan hasil
//   //       return result;
//   //     }
//   //   }

//   //   // Jika range_lookup adalah false, dan tidak ditemukan, kembalikan null
//   //   if (!range_lookup) {
//   //     return null;
//   //   }

//   //   // Jika range_lookup adalah true, bisa ditambahkan logika untuk pencarian mendekati
//   //   // Namun, untuk saat ini, kita hanya mengembalikan null jika tidak ditemukan
//   //   return null;

//   // }

//   function vlookup({ lookup_value, tableData, col_index_num, range_lookup, sheetName, data, rawData }) {

//     // Mencari nilai dalam tableData
//     let index = -1;
//     for (let i = 0; i < tableData.length; i++) {
//       const rowValue = tableData[i].v;
//       if (rowValue === lookup_value) {
//         index = i;
//         break;
//       }
//     }

//     const { huruf, angka } = splitExcelReference(tableData[index]?.p)
//     const kolom = getLetter(huruf, col_index_num - 1);
//     const result = tableData.find(item => item?.p === `${kolom}${angka}`);
//     return calculateExcelFormula({ sheetName, cell: result, rawData })
//   }

//   try {
//     if (formulaWithValues.startsWith("SUM(")) {
//       if (isValidParentheses(formulaWithValues)) {

//         // SUM function
//         const params = map(formulaStr.match(/SUM\(([^)]+)\)/)?.[1]?.split(","), (text) => text.trim());
//         let result = [];
//         const data = getSheetData({ sheetName, rawData })

//         for (let item of params) {
//           if (item?.includes(":")) {
//             result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
//           } else {
//             const regex = /^(?=.*[A-Z])(?=.*\d)([A-Z\d$]+)$/;
//             if (regex.test(item)) {
//               const value = getValue(sheetName, data, item, rawData);
//               result.push(value)
//             } else {
//               result.push(+item)
//             }
//           }
//         }
//         return formula.SUM(result);
//       } else {
//         return "Error"
//       }
//     }

//     else if (formulaWithValues.startsWith("ROUNDDOWN(")) {
//       // Parsing untuk fungsi ROUNDDOWN
//       // return formulaWithValues
//       const roundDownMatch = formulaWithValues.match(/ROUNDDOWN\(([^,]+),\s*([^)]+)\)/);
//       if (roundDownMatch) {

//         let firstValue = roundDownMatch[1];
//         let secondValue = roundDownMatch[2];
//         if (isOuterParenthesesUnified(firstValue)) {
//           firstValue = removeFirstAndLastChar(firstValue)
//         }
//         if (isOuterParenthesesUnified(secondValue)) {
//           secondValue = removeFirstAndLastChar(secondValue)
//         }
//         const number = parseFloat(firstValue);
//         const digits = Number(secondValue) < 5 ? Number(secondValue) : 3;
//         return Math.floor(number * Math.pow(10, digits)) / Math.pow(10, digits);
//       }

//     } else if (formulaWithValues.startsWith("AVERAGE(")) {
//       if (isValidParentheses(formulaWithValues)) {
//         // AVERAGE function
//         const params = map(formulaStr.match(/AVERAGE\((\(?-?[^]+\)?)\)/)?.[1]?.split(","), (text) => text.trim());
//         let result = [];
//         const data = getSheetData({ sheetName, rawData })
//         for (let item of params) {
//           if (item?.includes(":")) {
//             result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
//           } else {
//             const regex = /^(?=.*[A-Z])(?=.*\d)([A-Z\d$]+)$/;
//             if (regex.test(item)) {
//               const value = getValue(sheetName, data, item, rawData);
//               result.push(+value || value)
//             } else {
//               result.push(+item || item)
//             }
//           }
//         }

//         const avg = result?.reduce((partialSum, a) => partialSum + a, 0) / result?.length
//         return avg;
//       } else {
//         return "Error"
//       }
//     }
//     else if (formulaWithValues.startsWith("RANK(")) {
//       // Parsing untuk fungsi RANK
//       const rankMatch = formulaStr.match(/RANK\(([^)]+)\)/)?.[1]?.split(",");
//       if (rankMatch) {
//         let value = getValue(sheetName, getSheetData({ sheetName, rawData }), rankMatch?.[0], rawData)
//         if (isOuterParenthesesUnified(value)) {
//           value = removeFirstAndLastChar(value)
//         }
//         value = parseFloat(value);
//         const rangeCode = rankMatch[1];
//         const range = getAllSelectedValue({ rangeCode, sheetName, rawData })
//         const order = rankMatch[2]?.toUpperCase() === "ASC" ? "ASC" : "DESC"; // Default DESC
//         const result = calculateRank(value, range, order);
//         return result
//       }
//     } else if (formulaWithValues.startsWith("IRR(")) {
//       if (String(formulaWithValues).includes("null") || String(formulaWithValues).includes("#N/A")) {
//         return "#N/A"
//       }

//       const params = formulaStr.match(/IRR\(([^)]+)\)/)
//       const paramsVal = formulaWithValues.match(/IRR\(([^)]+)\)/)
//       const guessVal = map(paramsVal?.[1].split(","), (text) => text.trim())?.[1];
//       const [cashFlowRangeCode] = map(params?.[1].split(","), (text) => text.trim());
//       const cashFlows = getAllSelectedValue({ rangeCode: cashFlowRangeCode, sheetName, rawData })
//       return calculateIRR(cashFlows, guessVal);
//     } else if (formulaWithValues.startsWith("COUNT(")) {
//       // COUNT function
//       const params = map(formulaStr.match(/COUNT\(([^)]+)\)/)?.[1]?.split(","), (text) => text.trim());
//       let result = [];
//       const data = getSheetData({ sheetName, rawData })
//       for (let item of params) {
//         if (item?.includes(":")) {
//           result = [...result, ...getAllSelectedValue({ rangeCode: item, sheetName, rawData })]
//         } else {
//           const value = getValue(sheetName, data, item, rawData);
//           result.push(value)
//         }
//       }

//       return result.length;
//     }
//     else if (formulaWithValues.startsWith("VLOOKUP(")) {
//       // VLOOKUP function
//       const [searchValue, tableRange, colIndex, isApproximate] = map(formulaStr.match(/VLOOKUP\(([^)]+)\)/)[1].split(','), (text) => text.trim());
//       const sheetData = getSheetData({ sheetName, rawData })

//       let lookup_value
//       let params = null;
//       if (searchValue?.includes("!")) {
//         params = parseReference(searchValue)
//         if (params) {
//           lookup_value = getValue(params?.sheetName, sheetData, params?.cellRef, rawData)
//         }
//       } else {
//         lookup_value = getValue(sheetName, sheetData, searchValue, rawData)
//       }

//       const sheet_cell = splitSheetAndRange(tableRange)
//       const tableData = getSheetData({ sheetName: sheet_cell.sheetName, rawData });

//       const result = vlookup({
//         lookup_value,
//         tableData,
//         col_index_num: colIndex,
//         range_lookup: Boolean(isApproximate),
//         sheetName: sheet_cell?.sheetName,
//         data: sheetData,
//         rawData
//       })

//       return result
//     }
//     else if (formulaWithValues.startsWith("LEFT(")) {
//       // LEFT function
//       let [text, numChars] = map(formulaWithValues.match(/LEFT\((\(?-?[^]+\)?)\)/)[1].split(','), (text) => text.trim());
//       while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
//         text = removeFirstAndLastChar(text);
//       }
//       return text.slice(0, parseInt(numChars));
//     } else if (formulaWithValues.startsWith("MID(")) {
//       // MID function
//       let [text, startPos, numChars] = map(formulaWithValues.match(/MID\((\(?-?[^]+\)?)\)/)[1].split(','), (text) => text.trim());
//       while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
//         text = removeFirstAndLastChar(text);
//       }
//       return text.substr(parseInt(startPos) - 1, parseInt(numChars));
//     } else if (formulaWithValues.startsWith("RIGHT(")) {
//       // RIGHT function
//       let [text, numChars] = map(formulaWithValues.match(/RIGHT\((\(?-?[^]+\)?)\)/)[1].split(','), (text) => text.trim());
//       while ((text.startsWith('"') || text.startsWith('(')) && (text.endsWith('"') || text.endsWith(')'))) {
//         text = removeFirstAndLastChar(text);
//       }
//       return text.slice(-parseInt(numChars));
//     } else if (formulaWithValues.startsWith("TEXTJOIN(")) {
//       // TEXTJOIN function
//       const textJoinMatch = formulaWithValues.match(/TEXTJOIN\(([^,]*),\s*([^,]+),\s*(.+)\)/);
//       if (textJoinMatch) {
//         const delimiter = textJoinMatch[1]?.trim() ? eval(textJoinMatch[1].trim()) : ""; // eslint-disable-line no-eval
//         const ignoreEmpty = textJoinMatch[2].trim().toUpperCase() === "TRUE";
//         const rangeInput = textJoinMatch[3].trim();

//         let items = [];
//         if (rangeInput.includes(":")) {
//           // Handle range
//           items = getAllSelectedValue({ rangeCode: rangeInput, sheetName, rawData });
//         } else {
//           // Handle individual cell references
//           items = rangeInput.split(',');
//         }

//         // Process items based on `ignoreEmpty` flag
//         if (ignoreEmpty) {
//           items = filter(items, item => item !== null && item !== undefined && item !== "");
//         }

//         return items.join(delimiter);
//       }
//     }
//     else if (formulaWithValues.startsWith("ABS(")) {
//       if (String(formulaWithValues).includes("null") || String(formulaWithValues).includes("#N/A")) {
//         return "#N/A"
//       }

//       // MAX function
//       const cellRef = formulaStr.match(/ABS\(([^)]+)\)/)?.[1]
//       const data = getSheetData({ sheetName, rawData })
//       const value = getValue(sheetName, data, cellRef, rawData);
//       // const value = formulaWithValues.match(/ABS\(([^)]+)\)/)[1];
//       return Math.abs(value);
//     }
//     else if (formulaWithValues.startsWith("MIN(")) {
//       // MIN function
//       const rangeCode = formulaStr.match(/MIN\(([^)]+)\)/)[1];
//       const values = getAllSelectedValueWithoutEmpty({ rangeCode, sheetName, rawData })
//       return formula.MIN(values);
//     }
//     else if (formulaWithValues.startsWith("MAX(")) {
//       // MAX function
//       const rangeCode = formulaStr.match(/MAX\(([^)]+)\)/)[1];
//       const values = getAllSelectedValueWithoutEmpty({ rangeCode, sheetName, rawData })
//       return formula.MAX(values);
//     }
//     else if (formulaWithValues.startsWith("ROUND(")) {
//       if (String(formulaWithValues).includes("null") || String(formulaWithValues).includes("#N/A")) {
//         return "#N/A"
//       }

//       // ROUND function
//       const [number, digits = 0] = map(formulaWithValues.match(/ROUND\((\(?-?[^]+\)?)\)/)[1].split(','), (text) => text.trim());
//       let result = `${number}`;

//       const hasil = eval(result) // eslint-disable-line no-eval
//       const rounded = Math.round(parseFloat(hasil) * Math.pow(10, parseInt(digits))) / Math.pow(10, parseInt(digits));
//       return rounded
//     } else if (formulaWithValues.startsWith("AND(")) {
//       // AND function
//       formulaWithValues = replaceBoolean(formulaWithValues);
//       const conditions = map(formulaWithValues.match(/AND\((\(?-?[^]+\)?)\)/)[1].split(','), (cond) => eval(cond.trim())); // eslint-disable-line no-eval
//       const value = conditions.every(Boolean);
//       return value
//     } else if (formulaWithValues.startsWith("OR(")) {
//       // OR function
//       formulaWithValues = replaceBoolean(formulaWithValues);
//       const conditions = map(formulaWithValues.match(/OR\((\(?-?[^]+\)?)\)/)[1].split(','), (cond) => eval(cond.trim())); // eslint-disable-line no-eval
//       const value = conditions.some(Boolean);
//       return value;
//     } else if (formulaWithValues.startsWith("IF(")) {
//       // IF function
//       formulaWithValues = replaceBoolean(formulaWithValues);
//       const ifMatch = map(formulaWithValues.match(/IF\((\(?-?[^]+\)?)\)/)?.[1].split(","), (item) => item.trim());
//       const logical_operator_include_equal = ["<=", ">="]
//       if (ifMatch) {
//         let new_condition = ifMatch[0];
//         if (!logical_operator_include_equal.some(item => ifMatch[0].includes(item))) {
//           new_condition = ifMatch[0].replaceAll("=", "==="); // eslint-disable-line no-eval
//         }
//         const condition = eval(new_condition) // eslint-disable-line no-eval
//         const trueValue = ifMatch[1].trim();
//         const falseValue = ifMatch[2].trim();
//         const result = condition ? eval(trueValue) : eval(falseValue); // eslint-disable-line no-eval
//         return result
//       }
//     }
//     // Additional functions continue here
//     else if (/^[0-9+\-*/()^%.\s]+$/.test(formulaWithValues)) {
//       if (formulaWithValues.includes("/0")) {
//         return "#DIV/0!"
//       }

//       // Evaluate basic arithmetic expressions with +, -, *, /
//       if (/[+\-*/^%]/.test(formulaWithValues)) {
//         if (!formulaWithValues.includes("+-") || !formulaWithValues.includes("+(-")) {
//           if (formulaWithValues.match(/(\+|-|\*|\/)(\s*[+*/-])+/g)) {
//             return eval(formulaWithValues.replace(/(\+|-|\*|\/)(\s*[+*/-])+/g, "$10")); // eslint-disable-line no-eval
//           }
//         }

//         if (formulaWithValues.at(-1).match(/(\+|-|\*|\/)/)) {
//           formulaWithValues = formulaWithValues.slice(0, -1);
//         }

//         if (formulaWithValues.includes("^")) {
//           formulaWithValues = formulaWithValues.replaceAll("^", "**")
//         }

//         if (formulaWithValues.includes("%")) {
//           formulaWithValues = formulaWithValues.replaceAll("%", "/100")
//         }

//         // if (formulaWithValues.startsWith("(") && formulaWithValues.endsWith(")")) {
//         //   formulaWithValues = removeFirstAndLastChar(formulaWithValues)
//         // }

//         // // Handle negative values correctly
//         // formulaWithValues = formulaWithValues.replace(/-?\d+(\.\d+)?/g, (match) => {
//         //   return match.startsWith('-') ? `(${match})` : match; // Wrap negative numbers in parentheses
//         // });

//         // if (math_regex.test(formulaWithValues)) {
//         return eval(formulaWithValues) // eslint-disable-line no-eval
//         // }
//         // return formulaWithValues;
//         // Evaluate addition or subtraction only, empty values become 0
//       } else {
//         // For multiplication or division, return "Error" if there are empty values
//         return formulaWithValues;
//       }
//     }
//     else {
//       if (String(formulaWithValues).includes("null") || String(formulaWithValues).includes("#N/A")) {
//         return "#N/A"
//       }

//       return evaluateTextFormula(formulaWithValues);
//     }
//   } catch (error) {
//     console.error("Error formula:", formulaStr);
//     return "#N/A!";
//   }
// };

module.exports = {
  // calculateExcelFormula,
  numberToColumn,
  columnToNumber,
  splitExcelReference,
  getColRowIndex,
  get_value_cell,
  // getAllSelectedValue,
  // parseReference
};
