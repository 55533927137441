import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const idFormatingNumber = (value) => {
  return new Intl.NumberFormat("id-ID", { style: "decimal" }).format(value);
};

class PROFITABILITY_INDEX_DETAIL extends Component {
  state = {
    mode_formula: "hide",
  };

  on_set_mode_formula = (mode_formula) => {
    this.setState({
      mode_formula,
    });
  };

  render() {
    //local storage

    //local state
    const { mode_formula } = this.state;

    //global props
    const {
      alt_selected,
      luas_tanah,
      // luas_bangunan,
      LLB,
      LLB_excel,
      LLBE,
      hbu_type,
      working_capital_idr,
      economic_life_years,
      skema_terminal_value,
    } = this.props.hbu_reducer;

    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    const luas_bangunan = hbu_type === "HBU Terbangun" ? LLBE : (LLB_excel || LLB)

    //content
    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];

    const {
      capex_tanah,
      capex_bangunan,
      capex_total,
      // depresiasi,
      profitability_index,
      JENIS,
      BIAYA_PEMBANGUNAN_PER_M_2,
      PENDAPATAN_PER_M_2_MAX,
      BIOP_PER_M_2_MIN,
      // r_1,
      // b_1,
      last_year_pv_value,
    } = alt_selected;
    const finance_array = alt_selected?.finance_array?.slice(0, -1) || [];

    const finance_array_tv = alt_selected?.finance_array || [];

    return (
      <main className="max_height">
        <h1 className="text_header margin_bottom">
          Detail Formula Profitability Index
        </h1>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">
            Data objek dan alternatif yang diklik
          </h2>
          <table className="table margin_bottom">
            <tbody>
              <tr>
                <td>Luas tanah</td>
                <td>{idFormatingNumber(luas_tanah)}</td>
              </tr>
              <tr>
                <td>Luas bangunan</td>
                <td>{idFormatingNumber(luas_bangunan)}</td>
              </tr>
              <tr>
                <td colSpan={2}></td>
              </tr>
              <tr>
                <td>Harga tanah per meter persegi (SINI MAPID)</td>
                <td>{idFormatingNumber(landvalue_value)}</td>
              </tr>
              <tr>
                <td>Nama alternatif</td>
                <td>{JENIS}</td>
              </tr>
              <tr>
                <td>Biaya pembangunan per meter persegi</td>
                <td>{idFormatingNumber(BIAYA_PEMBANGUNAN_PER_M_2)}</td>
              </tr>
              <tr>
                <td>Pendapatan per meter persegi</td>
                <td>{idFormatingNumber(PENDAPATAN_PER_M_2_MAX)}</td>
              </tr>
              <tr>
                <td>Biaya operasional per meter persegi</td>
                <td>{idFormatingNumber(BIOP_PER_M_2_MIN)}</td>
              </tr>
              <tr>
                <td>{`Asumsi Umur Ekonomis (tahun)`}</td>
                <td>{idFormatingNumber(economic_life_years)}</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">Nilai Dasar Awal</h2>
          <table>
            <tbody>
              <tr>
                <td>CAPEX tanah</td>
                <td>= Luas tanah x harga tanah per meter persegi</td>
              </tr>
              <tr>
                <td>CAPEX tanah</td>
                <td>
                  = {idFormatingNumber(luas_tanah)} x{" "}
                  {idFormatingNumber(landvalue_value)}
                </td>
              </tr>
              <tr>
                <td>CAPEX tanah</td>
                <td>= {idFormatingNumber(capex_tanah)}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>CAPEX bangunan</td>
                <td>
                  = {idFormatingNumber(luas_bangunan)} x{" "}
                  {idFormatingNumber(BIAYA_PEMBANGUNAN_PER_M_2)}
                </td>
              </tr>
              <tr>
                <td>CAPEX bangunan</td>
                <td>= {idFormatingNumber(capex_bangunan)}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>= capex_tanah + capex_bangunan</td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>
                  = {idFormatingNumber(capex_tanah)} +{" "}
                  {idFormatingNumber(capex_bangunan)}
                </td>
              </tr>
              <tr>
                <td>CAPEX total</td>
                <td>= {idFormatingNumber(capex_total)}</td>
              </tr>
              {/* <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr> */}
              {/* <tr>
                <td>Depresiasi</td>
                <td>= capex-total / Umur Ekonomis</td>
              </tr>
              <tr>
                <td>Depresiasi</td>
                <td>
                  = {idFormatingNumber(capex_total)} /{" "}
                  {idFormatingNumber(economic_life_years)}
                </td>
              </tr>
              <tr>
                <td>Depresiasi</td>
                <td>= {idFormatingNumber(parseInt(depresiasi))}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>= Pendapatan per meter persegi x Luas bangunan</td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>
                  = {idFormatingNumber(parseInt(PENDAPATAN_PER_M_2_MAX))} x{" "}
                  {idFormatingNumber(parseInt(luas_bangunan))}
                </td>
              </tr>
              <tr>
                <td>Pendapatan di tahun pertama (r_1)</td>
                <td>= {idFormatingNumber(parseInt(r_1))}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Biaya operasional di tahun pertama (b_1)</td>
                <td>= BiOp per meter persegi x Luas bangunan</td>
              </tr>
              <tr>
                <td>Biaya operaional di tahun pertama (b_1)</td>
                <td>
                  = {idFormatingNumber(BIOP_PER_M_2_MIN)} x{" "}
                  {idFormatingNumber(luas_bangunan)}
                </td>
              </tr>
              <tr>
                <td>Biaya operasional di tahun pertama (b_1)</td>
                <td>= {idFormatingNumber(parseInt(b_1))}</td>
              </tr> */}
            </tbody>
          </table>
        </section>

        <section className="container background_grey_light margin_bottom">
          <h2 className="text_bold margin_bottom">
            Tabel Finansial Pertahun Selama Durasi Proyek
          </h2>
          <button
            className={`button outline_black margin_right margin_bottom ${
              mode_formula === "hide" ? "background_black" : "button_white"
            }`}
            onClick={this.on_set_mode_formula.bind(this, "hide")}
          >
            Sembunyikan formula
          </button>
          <button
            className={`button outline_black margin_right margin_bottom ${
              mode_formula === "show" ? "background_black" : "button_white"
            }`}
            onClick={this.on_set_mode_formula.bind(this, "show")}
          >
            Tampilkan formula
          </button>
          <aside style={{ overflowX: "auto" }}>
            <table className="table" style={{ minWidth: "100%" }}>
              <thead>
                <tr>
                  <th>No</th>
                  <th>Perhitungan</th>
                  {finance_array?.map((item, index) => {
                    return <th key={index}>Tahun ke-{index + 1}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <p>Pendapatan (R)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>Rn = R1 + (n-1) * a</p>
                        <p>
                          Rn = R1 + (n-1) * (revenue_growth_percent/100) * R1
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array?.map((item, index) => {
                    return (
                      <td key={index} className="text_right">
                        <p>{idFormatingNumber(item?.r_n)}</p>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <p>Biaya Operasional (B)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>Bn = B1 + (n-1) * b</p>
                        <p>Bn = B1 + (n-1) * (opex_growth_percent/100) * B1</p>
                      </>
                    )}
                  </td>
                  {finance_array?.map((item, index) => {
                    return (
                      <td key={index} className="text_right">
                        {idFormatingNumber(item?.b_n)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <p>Earning After Tax (EAT)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>
                          EATn = Rn - Bn - depresiasi - ((tax_percent/100) * (Rn
                          - Bn - depresiasi))
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array?.map((item, index) => {
                    return (
                      <td key={index} className="text_right">
                        {idFormatingNumber(item?.eat_n)}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <p>Cash flow (CF)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>CFn = EATn + depresiasi</p>
                        <p>CFn = EATn + capex_total/economic_life_years</p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    const isLastIndex = idx === finance_array.length - 1;
                    const cf_value =
                      skema_terminal_value && isLastIndex
                        ? finance_array_tv[finance_array_tv.length - 1]?.tv_rp +
                          finance_array_tv[finance_array_tv.length - 1]?.cf_n_10
                        : item?.cf_n;
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(cf_value))}
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td>5</td>
                  <td>
                    <p>Present Value (PV)</p>
                    {mode_formula === "show" && (
                      <>
                        <br />
                        <p>PVn = CFn * DRn</p>
                        <p>
                          PVn = CFn * ( 1 / (( 1 + discount_rate_1_percent/100 )
                          ** n_year ) )
                        </p>
                      </>
                    )}
                  </td>
                  {finance_array.map((item, idx) => {
                    const isLastIndex = idx === finance_array.length - 1;
                    const lastTvRp =
                      finance_array_tv[finance_array_tv.length - 1]?.tv_rp || 0;
                    const lastCfN =
                      finance_array_tv[finance_array_tv.length - 1]?.cf_n_10 ||
                      0;
                    const pv_value =
                      skema_terminal_value && isLastIndex
                        ? (lastTvRp + lastCfN) * item?.drn
                        : item?.pv_n;
                    return (
                      <td key={idx} className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(parseInt(pv_value))}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          </aside>
        </section>

        <section className="container background_grey_light ">
          <h2 className="text_bold margin_bottom">
            Menghitung Profitability Index (PI)
          </h2>
          <table>
            <tbody>
              <tr>
                <td>PI</td>
                <td>= Σ PV / Σ Investasi</td>
              </tr>
              <tr>
                <td>PI</td>
                <td>= Σ PV / (capex_total + working_capital_idr)</td>
              </tr>
              <tr>
                <td style={{ verticalAlign: "top" }}>PI</td>
                <td>
                  = {"("}
                  {finance_array?.slice(0, -1).map((item, index) => {
                    return (
                      <span key={index}>
                        {idFormatingNumber(parseInt(item?.pv_n))}
                        {index < finance_array.length - 1 && " + "}
                      </span>
                    );
                  })}
                  {idFormatingNumber(parseInt(last_year_pv_value))}
                  {")"}
                  <br />
                  {" / "} ({idFormatingNumber(capex_total)} +{" "}
                  {idFormatingNumber(working_capital_idr)})
                </td>
              </tr>
              <tr>
                <td>PI</td>
                <td>= {idFormatingNumber(profitability_index)}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(PROFITABILITY_INDEX_DETAIL);
