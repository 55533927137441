import axios from "axios";
import { compress_sini } from "../validation/compress_sini";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

export const set_value_sini = (body) => (dispatch) => {
  dispatch({
    type: "set_value_sini",
    payload: body,
  });
  dispatch({
    type: "state_update",
  });
};

export const pull_value_sini_object = (body) => (dispatch) => {
  dispatch({
    type: "pull_value_sini_object",
    payload: body,
  });
  dispatch({
    type: "state_update",
  });
};

export const state_update = () => {
  return {
    type: "state_update",
  };
};

export const fly_init_update = () => {
  return {
    type: "fly_init_update",
  };
};

export const fly_init_marker = () => {
  return {
    type: "fly_init_marker",
  };
};

/**
 * @route : admin_information
 * @endpoint : /sini_v2/admin_information
 * @methode : get
 * @params :
 */
export const admin_information = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "admin_information",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/admin_information`,
      config
    );
    const data = res.data;
    if (data) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: "admin_information",
          value: data,
        },
      });
    }
    dispatch({
      type: "clear_loading_action",
      payload: "admin_information",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "admin_information",
    });
  }
};

/**
 * @route : delete_ai_item
 * @endpoint : /sini_v2/delete_ai_item
 * @methode : delete
 * @query : -
 */
export const delete_ai_item = (query) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "delete_ai_item",
    });

    const { request_id } = query;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      bun_server + `/sini_v2/delete_ai_item?request_id=${request_id}`,
      config
    );
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_list",
        value: request_id,
        id: "request_id",
      },
    });
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_detail_list",
        value: request_id,
        id: "request_id",
      },
    });
    dispatch({
      type: "pull_value_sini_object",
      payload: {
        key: "ai_parent_child_list",
        value: request_id,
        id: "request_id",
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_active",
        value: "",
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_child_list",
        value: [],
      },
    });

    dispatch({
      type: "clear_loading_action",
      payload: "delete_ai_item",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "delete_ai_item",
    });
  }
};

/**
 * @route : get_ai_parent_list
 * @endpoint : /sini_v2/get_ai_parent_list
 * @methode : get
 * @params : -
 */
export const get_ai_parent_list = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_parent_list",
    });

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_list`,
      config
    );
    const data = res.data || [];
    dispatch(
      set_value_sini({
        key: "ai_parent_list",
        value: data,
      })
    );

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_list",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_list",
    });
  }
};

/**
 * @route : get_ai_parent_object
 * @endpoint : /sini_v2/get_ai_parent_object
 * @methode : get
 * @params :
              request_id, 
              is_from_excel,
              geo_layer_id,
              feature_key,
              field_key,

 */
export const get_ai_parent_object = (params, from) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_parent_object",
    });
    const { request_id, is_from_excel, geo_layer_id, feature_key, field_key } =
      params;
    if (is_from_excel) {
      const sheet_object_selected = {
        geo_layer_id,
        feature_key,
        field_key,
        request_id,
        is_from_excel,
      };
      dispatch(
        set_value_sini({
          key: "sheet_object_selected",
          value: sheet_object_selected,
        })
      );
    }
    dispatch(
      set_value_sini({
        key: "request_id_active",
        value: request_id,
      })
    );
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_object?request_id=${request_id}`,
      config
    );
    const data = res.data;

    if (from === "ai_hbu" && !data?.summary_sini) {
      let new_data = compress_sini(data); // Copy object agar tidak merusak original
      const body_sini = {
        geo_layer_id,
        feature_key,
        field_key,
        json_ai: new_data,
      };
      const res_summary_sini = await axios.post(
        bun_server + `/sini_v2/ai_excel/generate_summary_sini`,
        body_sini,
        config
      );
      const response_text = res_summary_sini?.data?.summary_sini;
      await axios.post(
        bun_server + `/sini_v2/ai_excel/update_summary_sini`,
        {
          request_id,
          summary_sini: response_text,
        },
        config
      );
      data.summary_sini = response_text;
    }

    if (data) {
      dispatch({
        type: "edit_inside_array_sini",
        payload: {
          key: "ai_parent_detail_list",
          id_key_parent: "request_id",
          id_value_parent: request_id,
          value_child: data,
        },
      });
    }

    if (data?.hbu_result_object !== undefined) {
      dispatch({
        type: "set_hbu_reducer_from_db",
        payload: data?.hbu_result_object,
      }); //untuk HBU dari save, machrus
    }

    dispatch(fly_init_update());
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_loading",
        value: "",
      },
    });
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "sini_marker_active",
        value: {},
      },
    });
    dispatch({
      type: "state_update",
    });

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_object",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_object",
    });
  }
};

/**
 * @route : get_ai_parent_status
 * @endpoint : /sini_v2/get_ai_parent_status
 * @methode : get
 * @params : request_id
 */
export const get_ai_parent_status = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_child_list",
    });

    const { request_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_parent_status?request_id=${request_id}`,
      config
    );
    const data = res.data;
    if (data) {
      dispatch({
        type: "edit_inside_array_sini",
        payload: {
          key: "ai_parent_detail_list",
          id_key_parent: "request_id",
          id_value_parent: request_id,
          value_child: data,
        },
      });
    }
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_status",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_parent_status",
    });
  }
};

/**
 * @route : get_ai_child_list
 * @endpoint : /sini_v2/get_ai_child_list
 * @methode : get
 * @params : request_id
 */
export const get_ai_child_list = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_child_list",
    });

    const { request_id } = params;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/get_ai_child_list?request_id=${request_id}`,
      config
    );
    const data = res.data;

    //masukan detail di ai_parent_detail_list, termasuk ai_child_list, nantinya ai_child_list tidak dipakai, logic ketika sudah ada dan tidak ada dihandle difungsi di bawah
    const object = {
      request_id,
      ai_child_list: data,
    };
    dispatch({
      type: "edit_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        value_child: object,
      },
    });

    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_child_list",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_child_list",
    });
  }
};
