/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user } from "../../App/actions/authActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/
import icon_zoom_in from "../../Assets/svg/icon_zoom_in.svg";
import icon_zoom_out from "../../Assets/svg/icon_zoom_out.svg";
import icon_compass from "../../Assets/svg/icon_compass.svg";
import icon_gps from "../../Assets/svg/icon_gps.svg";

/*FUNCTION*/

/*DATA*/
import generate_uuid from "../../App/validation/generate_uuid";
import get_browser_info from "../../App/validation/get_browser_info";

/*CONST*/

class MAP_CONTROL_DRAW extends Component {
  state = {
    geo_location_status: "pending", //pending, loading,  error, browser_not_supported, success
    geo_location_error: "",
    modal_change_map: false,
    modal_error_gps: false,
    gps_error_message: "",
    browser_info: {},
    error_string: "",
  };

  componentDidMount() {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.on("rotate", this.update_compass_rotation);
    }
    this.setState({
      browser_info: get_browser_info(),
    });
  }

  componentDidUpdate(prevProps) {
    const { map_object } = this.props.layer;
    const map_object_prev = prevProps.layer.map_object;
    if (map_object_prev !== map_object && !!map_object) {
      map_object.on("rotate", this.update_compass_rotation);
    }
  }

  componentWillUnmount = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.off("rotate", this.update_compass_rotation);
    }
  };

  toggle_error_gps = () => {
    this.setState({
      modal_error_gps: !this.state.modal_error_gps,
    });
  };

  update_compass_rotation = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      const bearing = map_object.getBearing();
      const compass = document.getElementById("map_compass");
      if (compass) {
        compass.style.transform = `rotate(${-bearing}deg)`;
      }
    }
  };

  on_zoom_in = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.zoomIn();
    }
  };

  on_zoom_out = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.zoomOut();
    }
  };

  on_reset_compass = () => {
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.easeTo({
        pitch: 0,
        bearing: 0,
        duration: 1000,
      });
    }
  };

  on_geolocate = (retryCount = 3) => {
    this.setState({
      geo_location_status: "loading",
      modal_error_gps: false,
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const uuid = generate_uuid();
          const {
            latitude,
            longitude,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
          } = position.coords;
          const gps_object = {
            uuid: uuid,
            latitude,
            longitude,
            accuracy,
            altitude,
            altitudeAccuracy,
            heading,
            speed,
          };
          this.props.set_value_layer({
            key: "gps_object",
            value: gps_object,
          });
          this.setState({
            geo_location_status: "success",
          });
        },
        (error) => {
          // Default error messages based on error code
          let errorMessage =
            "Pengambilan GPS browser gagal, alasan tidak diketahui";

          switch (error.code) {
            case 1:
              errorMessage = "Permission denied. Please allow location access.";
              break;
            case 2:
              errorMessage =
                "Position unavailable. Please check your Wi-Fi connection and try again.";
              break;
            case 3:
              errorMessage = "Request timed out. Retrying...";
              break;
            default:
              errorMessage = "An unknown error occurred. Please try again.";
          }

          // Use the error message from the error object if available
          errorMessage = error?.message || errorMessage;

          // Manually create an object with error details and convert it to a string
          const errorDetails = {
            name: error.name || "GeolocationError",
            message: errorMessage,
            code: error.code,
            stack: error.stack || "No stack trace available",
          };
          const errorString = JSON.stringify(errorDetails);

          if (retryCount > 0 && error.code !== 1) {
            setTimeout(() => {
              this.on_geolocate(retryCount - 1);
            }, 2000); // 2-second delay before retry
          } else {
            this.setState({
              geo_location_status: "error",
              geo_location_error: error,
              modal_error_gps: true,
              gps_error_message: errorMessage,
              error_string: errorString,
            });
          }
        }
      );
    } else {
      this.setState({
        geo_location_status: "browser_not_supported",
      });
    }
  };

  on_reload_browser = () => {
    window.location.reload();
  };

  render() {
    const {
      geo_location_status,
      modal_error_gps,
      gps_error_message,
      browser_info,
      error_string,
    } = this.state;

    let gps_class = "";
    if (geo_location_status === "loading") {
      gps_class = "icon_rotate";
    } else if (geo_location_status === "success") {
      gps_class = "icon_scale";
      setTimeout(() => {
        const icon_gps = document.getElementById("icon_gps");
        if (icon_gps) {
          icon_gps.classList.remove("icon_scale");
          icon_gps.classList.add("icon_reset");
        }
      }, 1000);
    }

    const modal_error_gps_content = modal_error_gps && (
      <Modal
        modalSize="small"
        isOpen={modal_error_gps}
        onClose={this.toggle_error_gps}
      >
        <div className="box-body" id="box-body">
          <h3 className="margin_bottom">
            Pengambilan lokasi dari browser gagal, keterangan error:
          </h3>
          <div className="container_light background_red_bright margin_bottom">
            {gps_error_message}
          </div>
          <div className="container_light background_red_bright margin_bottom">
            {error_string}
          </div>
          <table className="table margin_bottom">
            <tbody>
              <tr>
                <td>browser_name</td>
                <td>{browser_info.browser_name}</td>
              </tr>
              <tr>
                <td>browser_engine</td>
                <td>{browser_info.browser_engine}</td>
              </tr>
              <tr>
                <td>os_name</td>
                <td>{browser_info.os_name}</td>
              </tr>
              <tr>
                <td>os_version</td>
                <td>{browser_info.os_version}</td>
              </tr>
            </tbody>
          </table>
          <h3 className="margin_bottom">
            Harap ijinkan pengambilan lokasi browser Anda, atau
          </h3>
          {/* <button
            className="button margin_right margin_bottom"
            onClick={this.on_geolocate}
          >
            Coba lagi
          </button> */}
          <button
            className="button margin_right margin_bottom"
            onClick={this.on_reload_browser}
          >
            Muat ulang halaman
          </button>
        </div>
      </Modal>
    );

    return (
      <aside className="map_controls_wrapper">
        {modal_error_gps_content}
        <nav className="map_controls_background">
          <section className="map_controls_container">
            <button
              className="map_button_draw zoom_in"
              onClick={this.on_zoom_in}
              data-mapid="clickZoomIn"
            >
              <img alt="Zoom In" src={icon_zoom_in} width={12} />
            </button>
            <div className="separator"></div>
            <button
              className="map_button_draw zoom_out"
              onClick={this.on_zoom_out}
              data-mapid="clickZoomOut"
            >
              <img alt="Zoom Out" src={icon_zoom_out} width={12} />
            </button>
            <div className="separator"></div>
            <button
              className="map_button_draw reset_compass"
              onClick={this.on_reset_compass}
              data-mapid="clickCompas"
            >
              <img
                id="map_compass"
                alt="Reset Compass"
                src={icon_compass}
                height={17}
              />
            </button>
            <div className="separator"></div>
            <button
              className="map_button_draw gps_map"
              onClick={this.on_geolocate}
              data-mapid="clickGPS"
            >
              <img
                id="icon_gps"
                alt="Geolocate"
                src={icon_gps}
                width={15}
                className={gps_class}
              />
            </button>
          </section>
        </nav>
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  set_value_properties,
  set_value_user,
  status_action,
})(MAP_CONTROL_DRAW);
