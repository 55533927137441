/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class LAYER_S_POI_SURVEY_1 extends Component {
  state = {
    popup_handlers: {},
  };

  componentDidUpdate(prevProps) {
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];

    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }

      // this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const id_data = "poi_property_2025";
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      is_sini_visible,
    } = this.props.sini;
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const visibility =
      sini_map_show.includes(id_data) && is_sini_visible ? "visible" : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const features = item?.[id_data] || [];

        const geojson = {
          type: "FeatureCollection",
          features: features,
        };
        const paint_object = {
          "circle-color": "#a0d062",
          "circle-radius": 4,
          "circle-stroke-width": 2,
          "circle-stroke-color": "#fff",
        };

        const layer_id = `${id_data}_${request_id}`;
        if (!map_object.getSource(layer_id)) {
          map_object.addSource(layer_id, {
            type: "geojson",
            data: geojson,
          });
        } else {
          map_object.getSource(layer_id).setData(geojson);
        }
        if (!map_object.getLayer(layer_id)) {
          map_object.addLayer({
            id: layer_id,
            source: layer_id,
            type: "circle",
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
        } else {
          map_object.setLayoutProperty(layer_id, "visibility", visibility);
          for (const property in paint_object) {
            map_object.setPaintProperty(
              layer_id,
              property,
              paint_object[property]
            );
          }
        }
      });
    }
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_POI_SURVEY_1);
