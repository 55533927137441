/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import RANGKING_CHART_MANUAL from "./RANKING_CHART_MANUAL";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/
import is_not_empty from "../../App/validation/is_not_empty";
import sort_array from "../../App/validation/sort_array";

/*DATA*/

/*CONST*/

class HBU_SUMMARY_MANUAL extends Component {

  formatArray = (arr) => {
    if (arr.length === 0) return "";
    if (arr.length === 1) return arr[0];
    if (arr.length === 2) return `${arr[0]} dan ${arr[1]}`;

    const lastElement = arr.pop();
    return `${arr.join(", ")}, dan ${lastElement}`;
  }

  render() {
    //global props
    const { ai_parent_detail_list, request_id_active } = this.props.sini;
    let {
      luas_tanah,
      // luas_bangunan,
      kesimpulan_hbu_fisik_tanah_kosong,
      kesimpulan_hbu_fisik_bangunan,
      kesimpulan_uji_legal,
      KDB,
      GSB,
      KLB,
      LLBE,
      LDBE,
      hbu_type,
      tahun_bangun,
      tahun_peraturan_dikeluarkan,
      IMB,
      LDB,
      LLB,
      LLB_excel,
      is_force_pass_1
    } = this.props.hbu_reducer;
    let real_filtered_rows = this.props.hbu_reducer?.real_filtered_rows || [];

    const { kesimpulan, detail_value } = kesimpulan_uji_legal || {}

    const is_terbangun = hbu_type === "HBU Terbangun";
    const label_luas_bangunan = is_terbangun ? "Luas Lantai Bangunan Eksisting (LLBE)" : "Luas Lantai Bangunan (LLB)"
    const luas_bangunan = is_terbangun ? LLBE : (LLB_excel || LLB)

    //content
    const sini_object = ai_parent_detail_list?.find(
      (item) => item?.request_id === request_id_active
    ); //sini_object dengan request_id_active
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;
    real_filtered_rows = sort_array(real_filtered_rows, "total_rank", true);
    const not_pass_rows = []
    const pass_rows = real_filtered_rows.filter(item => {
      if (item?.npv <= 0) not_pass_rows.push(item);
      return item?.npv > 0
    })
    const showed_rows = pass_rows.slice(0, 3);
    const alternatif_1 = showed_rows?.[0];

    if (!is_not_empty(KDB)) KDB = "-";
    if (!is_not_empty(GSB)) GSB = "-";
    if (!is_not_empty(KLB)) KLB = "-";

    let sentence_of_not_pass_rows = "";
    let sentence_negative_npv = "";
    if (not_pass_rows?.length > 0) {
      sentence_of_not_pass_rows = this.formatArray(not_pass_rows?.map(item => item?.JENIS));
      sentence_negative_npv = `Ket: Berdasarkan uji finansial, ${sentence_of_not_pass_rows} tidak untuk dikembangkan di sini.`;
    }

    //content
    let rangkuman_kalkulasi_hbu_content = null
    if (is_force_pass_1) {
      rangkuman_kalkulasi_hbu_content =
        <span className="center_perfect">
          <label className="margin_bottom text_center">
            Berdasarkan uji supply dan demand, tidak ada alternatif yang layak untuk dikembangkan.
          </label>
        </span>
    } else if (showed_rows?.length > 0) {
      rangkuman_kalkulasi_hbu_content =
        <section className="container_light background_white">
          <div className="center_perfect h_full">
            <div>
              <span className="center_perfect">
                <label className="margin_bottom text_center">
                  Alternatif HBU terbaik
                </label>
              </span>
              <span className="center_perfect">
                <h1 className="rounded_5 padding_5 background_black text_center">
                  {alternatif_1?.JENIS ? alternatif_1?.JENIS : "-"}
                </h1>
              </span>
            </div>
          </div>
        </section>
    } else if (showed_rows?.length === 0) {
      rangkuman_kalkulasi_hbu_content =
        <span className="center_perfect">
          <label className="margin_bottom text_center">
            Tidak ditemukan alternatif properti yang layak untuk dikembangkan di lokasi ini.
          </label>
        </span>
    }

    return (
      <section>
        <h1 className="margin_bottom">HBU SUMMARY</h1>

        <section className="margin_bottom">
          {/* {loading_item_array.includes("generate_ai_hbu_summary") ? (
            <button className="button_big background_grey">
              AI SEDANG MELAKUKAN SUMMARY...
            </button>
          ) : !ai_sini_sheet_object?.request_id ? (
            <button className="button_big background_grey">WAIT</button>
          ) : (
            <button
              className="button_big background_black"
            // onClick={this.toggle_re_hbu}
            >
              Perbarui Ringkasan HBU dengan Prompt
            </button>
          )} */}
        </section>

        <aside className="container_column">
          <div className="child_tile container_light background_grey_light outline_transparent">
            {/* {peruntukan && (
              <h1 className="margin_bottom">
                <label>Zoning: </label>
                <p className="text_bold">{peruntukan}</p>
              </h1>
            )} */}
            <div className="margin_bottom">
              <label className="text_small">Provinsi</label>
              <p className="text_bold">{PROVINSI}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Kabupaten atau Kota</label>
              <p className="text_bold">{KABKOT}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Kecamatan</label>
              <p className="text_bold">{KECAMATAN}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Desa atau Kelurahan</label>
              <p className="text_bold">{DESA}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">Luas Tanah m2</label>
              <p className="text_bold">{luas_tanah}</p>
            </div>
            <div className="margin_bottom">
              <label className="text_small">{label_luas_bangunan} m2</label>
              <p className="text_bold">{luas_bangunan}</p>
            </div>
          </div>

          <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent flex">
            <section>
              <h1 className="text_bold margin_bottom">
                Rangkuman kalkulasi HBU
              </h1>
              <RANGKING_CHART_MANUAL hbu_alternative_array={showed_rows} />
              <label>{sentence_negative_npv}</label>
            </section>
            {rangkuman_kalkulasi_hbu_content}
          </div>

          <section className="child_tile span_3 container_light background_grey_light outline_transparent">
            <div className="text_bold margin_bottom">Uji Legal</div>
            <div className="container background_white margin_bottom">
              <table>
                <tbody>
                  {/* <tr>
                    <td className="text_medium_normal">Zoning</td>
                    <td>:</td>
                    <td className="text_bold">{peruntukan ? peruntukan : "-"}</td>
                  </tr> */}
                  <tr>
                    <td className="text_medium_normal">KDB</td>
                    <td>:</td>
                    <td className="text_bold"> {`${KDB}%`}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">GSB</td>
                    <td>:</td>
                    <td className="text_bold"> {GSB}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">KLB</td>
                    <td>:</td>
                    <td className="text_bold"> {KLB}</td>
                  </tr>
                  <tr>
                    <td className="text_medium_normal">Luas Terbangun</td>
                    <td>:</td>
                    <td className="text_bold">
                      {LLBE} m<sup>2</sup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* <div className="two_container_equal"> */}
            <article
              className="container background_white"
              style={{ position: "relative", paddingBottom: "35px" }}
            >
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">
                  Perhitungan Sesuai Tata Ruang
                </h1>
              </div>

              {hbu_type === "HBU Terbangun" && detail_value &&
                <div className="block">
                  {<div>{`Tahun Bangun = ${+tahun_bangun || 0}`}</div>}
                  {<div>{`Tahun Peraturan Dikeluarkan = ${+tahun_peraturan_dikeluarkan || 0}`}</div>}
                  {<div>{`IMB = ${+IMB || "-"}`}</div>}
                  {<div>{`Luas Dasar Bangunan Eksisting (LDBE) = ${+LDBE || 0}`}</div>}
                  {<div>{`Luas Lantai Bangunan Eksisting (LLBE) = ${+LLBE || 0} [${detail_value?.is_optimal ? "Optimal" : "Tidak Optimal"}]`}</div>}
                  {<div>{`Luas Dasar Bangunan (LDB) = ${+LDB || 0}`}</div>}
                  {<div>{`Luas Lantai Bangunan (LLB) = ${+LLB || 0}`}</div>}
                </div>}

              <br />

              <p className="text_medium">{kesimpulan}</p>


              {/* {!is_kdb_and_klb_fill && (
                <p className="">
                  <br />
                  KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                  kesimpulan sesuai.
                </p>
              )} */}

              {/* {is_not_empty(ktb_tanah_kosong) && (
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    bottom: 10,
                  }}
                >
                  KTB : {ktb_tanah_kosong}
                </div>
              )} */}
              {/* <div className="two_container_small_right">
                  <h1 className="text_bold margin_bottom">
                    Perhitungan Sesuai Tata Ruang (Tanah Kosong)
                  </h1>
                </div>
                <p className="text_medium">{kesimpulan_tanah_kosong}</p>

                {!is_kdb_and_klb_fill && (
                  <p className="">
                    <br />
                    KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                    kesimpulan sesuai.
                  </p>
                )}

                {is_not_empty(ktb_tanah_kosong) && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      bottom: 10,
                    }}
                  >
                    KTB : {ktb_tanah_kosong}
                  </div>
                )} */}
            </article>
            {/* <article
                className="container background_white"
                style={{ position: "relative", paddingBottom: "35px" }}
              >
                <div className="two_container_small_right">
                  <h1 className="text_bold margin_bottom">
                    Perhitungan Sesuai Tata Ruang (Bangunan)
                  </h1>
                </div>
                <p className="text_medium">{kesimpulan_bangunan}</p>

                {!is_kdb_and_klb_fill && (
                  <p>
                    <br />
                    KDB dan KLB harus diisi pada sheet 'Uji HBU - Legal' agar
                    kesimpulan sesuai.
                  </p>
                )}

                {is_not_empty(ktb_bangunan) && (
                  <div
                    style={{
                      position: "absolute",
                      right: 10,
                      bottom: 10,
                    }}
                  >
                    KTB : {ktb_bangunan}
                  </div>
                )}
              </article> */}
            {/* </div> */}
          </section>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Maximum Productivity
            </h1>
            <p>
              Kesimpulan uji maximum productivity menyatakan bahwa aset yang
              terletak di lokasi ini memenuhi syarat indikator kelayakan.
            </p>
          </div>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Fisik HBU (Tanah Kosong)
            </h1>

            <p>{kesimpulan_hbu_fisik_tanah_kosong}</p>
          </div>

          <div className="child_tile container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Uji Fisik HBU Bangunan (Existing)
            </h1>

            <p>{kesimpulan_hbu_fisik_bangunan}</p>
          </div>

          <div className="child_tile span_3 container_light background_grey_light outline_transparent">
            <h1 className="text_bold margin_bottom">
              Rangkuman finansial setiap alternatif
            </h1>
            <table className="table scroll_auto">
              <thead>
                <tr className="font_10">
                  <th></th>
                  <th>CAPEX Tanah</th>
                  <th>CAPEX Bangunan</th>
                  <th>CAPEX Total</th>
                  <th>NPV</th>
                  <th>IRR</th>
                  <th>PI</th>
                  <th>PP</th>
                  <th>ARR</th>
                  <th>DR</th>
                  <th>Ranking</th>
                </tr>
              </thead>
              <tbody>
                {showed_rows?.map((item, idx) => {
                  const {
                    JENIS,
                    capex_tanah,
                    capex_bangunan,
                    capex_total,
                    npv,
                    irr,
                    arr,
                    payback_periode,
                    profitability_index,
                  } = item;

                  const to_id_format = (num_val) => {
                    return new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                      maximumFractionDigits: 2,
                    })?.format(Number(num_val));
                  };

                  return (
                    <tr key={idx} className="font_10">
                      <td>{JENIS}</td>
                      <td>Rp{to_id_format(capex_tanah)}</td>
                      <td>Rp{to_id_format(capex_bangunan)}</td>
                      <td>Rp{to_id_format(capex_total)}</td>
                      <td>Rp{to_id_format(npv)}</td>
                      <td>{to_id_format(irr)}%</td>
                      <td>{`${profitability_index} tahun`}</td>
                      <td>{to_id_format(payback_periode)}</td>
                      <td>{to_id_format(arr)}</td>
                      <td>15%</td>
                      <td>{to_id_format(idx + 1)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div>
              <br />
              <h1 className="text_bold margin_bottom">Indikator Kelayakan</h1>
              <ul>
                <li>
                  <b style={{ fontWeight: 800 }}>NPV</b>
                  {
                    " > 0 maka layak, semakin besar maka baik karena lebih profitable"
                  }
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>IRR</b>
                  {
                    " > Investasi, semakin besar maka baik karena lebih profitable"
                  }
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>PP</b>
                  {" Semakin kecil maka baik / cepat balik modal"}
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>PI</b>
                  {" > 1 maka layak"}
                </li>
                <li>
                  <b style={{ fontWeight: 800 }}>Akumulasi Rank</b>
                  {" semakin rendah, maka prioritas semakin tinggi."}
                </li>
              </ul>
            </div>

            <div className="h_full margin_top">
              <br />
              <h1 className="text_bold margin_bottom">
                Alasan Pemilihan Alternatif
              </h1>
              <p className="text_medium">
                {`Pemilihan ${alternatif_1?.JENIS ? alternatif_1?.JENIS : "-"
                  } sebagai alternatif terbaik didasarkan pada analisis mendalam terhadap kebutuhan lokal dan tren pasar yang stabil, memberikan return investasi yang diharapkan serta memenuhi fungsi utilitas nyata bagi masyarakat.`}
              </p>
            </div>
          </div>
        </aside>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(HBU_SUMMARY_MANUAL);
