/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
// import { generate_paint } from "../../Functions/map_style/generate_paint";
// import { extract_value_array } from "../../Functions/map_style/extract_value_array";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1000;

class DEFAULT_STYLE_EDITOR extends Component {
  state = {};

  componentDidMount() {
    this.timer = null;
  }

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "range" || type === "number") {
      value = Number(value);
    }
    const { paint_object_edited } = this.props.properties;
    paint_object_edited[name] = value;
    let { style_parameter } = this.props.properties;
    // const { style_type } = style_parameter;
    // const value_array = extract_value_array(paint_object_edited, style_type);
    // style_parameter = {
    //   ...style_parameter,
    //   value_array,
    //   paint_object_edited,
    // };
    // const style_object_new = generate_paint(style_parameter);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_edited,
    });
    this.props.set_value_properties({
      key: "style_parameter",
      value: style_parameter,
    });
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  };

  trigger_action = () => {
    this.props.status_action();
  };

  render_scale = (min, max, step) => {
    const numbers_array = [];
    for (let i = min; i <= max; i += step) {
      let number = i;
      if (!Number.isInteger(i)) {
        number = i.toFixed(1);
      }
      numbers_array.push(number);
    }
    return (
      <div className="scale_range text_small">
        {numbers_array.map((number) => (
          <span key={number} className="scale_range_number">
            {number}
          </span>
        ))}
      </div>
    );
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { paint_object_edited, style_parameter } = this.props.properties;

    //content
    const paint_properties_object = {
      circle: [
        {
          name: dict?.["Circle opacity"]?.[language],
          key: "circle-opacity",
          type: "number",
          min: 0,
          max: 1,
          step: 0.01,
          step_visual: 0.2,
          is_limit: true, // Opacity is limited between 0 and 1
        },
        {
          name: dict?.["Circle radius"]?.[language],
          key: "circle-radius",
          type: "number",
          min: 0,
          max: 100,
          step: 1,
          step_visual: 10,
          is_limit: false,
        },
        {
          name: dict?.["Circle stroke color"]?.[language],
          key: "circle-stroke-color",
          type: "color",
          is_limit: false,
        },
        {
          name: dict?.["Circle stroke width"]?.[language],
          key: "circle-stroke-width",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          step_visual: 1,
          is_limit: false,
        },
        {
          name: dict?.["Circle stroke opacity"]?.[language],
          key: "circle-stroke-opacity",
          type: "number",
          min: 0,
          max: 1,
          step: 0.01,
          step_visual: 0.2,
          is_limit: true,
        },
        {
          name: dict?.["Circle blur"]?.[language],
          key: "circle-blur",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          step_visual: 1,
          is_limit: false,
        },
        // {
        //   name: "Circle pitch alignment",
        //   key: "circle-pitch-alignment",
        //   type: "options",
        //   options: [
        //     { name: "Map", value: "map" },
        //     { name: "Viewport", value: "viewport" },
        //   ],
        //   is_limit: false,
        // },
        // {
        //   name: "Circle pitch scale",
        //   key: "circle-pitch-scale",
        //   type: "options",
        //   options: [
        //     { name: "Map", value: "map" },
        //     { name: "Viewport", value: "viewport" },
        //   ],
        //   is_limit: false,
        // },
        // {
        //   name: "Circle translate",
        //   key: "circle-translate",
        //   type: "array", // Represents an array of numbers [x, y]
        //   is_limit: false,
        // },
        // {
        //   name: "Circle translate anchor",
        //   key: "circle-translate-anchor",
        //   type: "options",
        //   options: [
        //     { name: "Map", value: "map" },
        //     { name: "Viewport", value: "viewport" },
        //   ],
        //   is_limit: false,
        // },
      ],
      line: [
        // {
        //   name: "Line color",
        //   key: "line-color",
        //   type: "color",
        //   is_limit: false,
        // },
        {
          name: dict?.["Line width"]?.[language],
          key: "line-width",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          is_limit: false,
        },
        {
          name: dict?.["Line opacity"]?.[language],
          key: "line-opacity",
          type: "number",
          min: 0,
          max: 1,
          step: 0.01,
          step_visual: 0.2,
          is_limit: true, // Opacity is limited between 0 and 1
        },
        // {
        //   name: "Line dasharray",
        //   key: "line-dasharray",
        //   type: "options",
        //   options: [
        //     { name: "Solid", value: [] },
        //     { name: "Dotted", value: [1, 1] },
        //     { name: "Dashed", value: [4, 2] },
        //     { name: "Long Dash", value: [8, 4] },
        //     { name: "Complex Pattern", value: [3, 1, 1, 1] },
        //   ],
        //   is_limit: false,
        // },
        {
          name: dict?.["Line gap width"]?.[language],
          key: "line-gap-width",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          is_limit: false,
        },
        {
          name: dict?.["Line blur"]?.[language],
          key: "line-blur",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          is_limit: false,
        },
        // {
        //   name: "Line translate",
        //   key: "line-translate",
        //   type: "array", // Represents an array of numbers [x, y]
        //   is_limit: false,
        // },
        {
          name: dict?.["Line translate anchor"]?.[language],
          key: "line-translate-anchor",
          type: "options",
          options: [
            { name: "Map", value: "map" },
            { name: "Viewport", value: "viewport" },
          ],
          is_limit: false,
        },
        // {
        //   name: dict?.["Line join"]?.[language],
        //   key: "line-join",
        //   type: "options",
        //   options: [
        //     { name: "Miter", value: "miter" },
        //     { name: "Bevel", value: "bevel" },
        //     { name: "Round", value: "round" },
        //   ],
        //   is_limit: false,
        // },
        // {
        //   name: dict?.["Line cap"]?.[language],
        //   key: "line-cap",
        //   type: "options",
        //   options: [
        //     { name: "Butt", value: "butt" },
        //     { name: "Round", value: "round" },
        //     { name: "Square", value: "square" },
        //   ],
        //   is_limit: false,
        // },
      ],
      fill: [
        {
          name: dict?.["Fill opacity"]?.[language],
          key: "fill-opacity",
          type: "number",
          min: 0,
          max: 1,
          step: 0.01,
          step_visual: 0.2,
          is_limit: true,
        },
        // {
        //   name: "Fill outline color",
        //   key: "fill-outline-color",
        //   type: "color",
        //   is_limit: false,
        // },
        // Line properties for the fill style
        {
          name: dict?.["Line color"]?.[language],
          key: "line-color",
          type: "color",
          is_limit: false,
        },
        {
          name: dict?.["Line width"]?.[language],
          key: "line-width",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          step_visual: 2,
          is_limit: false,
        },
        {
          name: dict?.["Line opacity"]?.[language],
          key: "line-opacity",
          type: "number",
          min: 0,
          max: 1,
          step: 0.01,
          step_visual: 0.2, // Updated visual step for opacity
          is_limit: true, // Opacity is limited between 0 and 1
        },
        // {
        //   name: "Line dasharray",
        //   key: "line-dasharray",
        //   type: "options",
        //   options: [
        //     { name: "Solid", value: [] }, // No dashes, solid line
        //     { name: "Dotted", value: [1, 1] }, // Dotted line
        //     { name: "Dashed", value: [4, 2] }, // Standard dashed line
        //     { name: "Long Dash", value: [8, 4] }, // Longer dashes
        //     { name: "Complex Pattern", value: [3, 1, 1, 1] }, // A more complex pattern
        //   ],
        //   is_limit: false,
        // },
        {
          name: dict?.["Line gap width"]?.[language],
          key: "line-gap-width",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          step_visual: 2,
          is_limit: false,
        },
        {
          name: dict?.["Line blur"]?.[language],
          key: "line-blur",
          type: "number",
          min: 0,
          max: 10,
          step: 0.1,
          step_visual: 2,
          is_limit: false,
        },
        {
          name: dict?.["Line translate anchor"]?.[language],
          key: "line-translate-anchor",
          type: "options",
          options: [
            { name: "Map", value: "map" },
            { name: "Viewport", value: "viewport" },
          ],
          is_limit: false,
        },
        // {
        //   name: dict?.["Line join"]?.[language],
        //   key: "line-join",
        //   type: "options",
        //   options: [
        //     { name: "Miter", value: "miter" },
        //     { name: "Bevel", value: "bevel" },
        //     { name: "Round", value: "round" },
        //   ],
        //   is_limit: false,
        // },
        // {
        //   name: dict?.["Line cap"]?.[language],
        //   key: "line-cap",
        //   type: "options",
        //   options: [
        //     { name: "Butt", value: "butt" },
        //     { name: "Round", value: "round" },
        //     { name: "Square", value: "square" },
        //   ],
        //   is_limit: false,
        // },
      ],
    };
    const { style_type } = style_parameter;
    const array_editor = paint_properties_object?.[style_type] || [];
    return (
      <main>
        {array_editor.map((item, idx) => {
          const {
            name,
            key,
            type,
            min,
            max,
            step,
            step_visual,
            options,
            // is_limit,
          } = item;
          let input_content;
          const value = paint_object_edited?.[key];
          if (type === "number") {
            input_content = (
              <section>
                {/* {!is_limit && (
                  <input
                    name={key}
                    value={value}
                    onChange={this.on_change}
                    step={step}
                    type="number"
                  />
                )} */}
                <input
                  name={key}
                  value={value}
                  onChange={this.on_change}
                  min={min}
                  max={max}
                  step={step}
                  type="range"
                />
                {this.render_scale(min, max, step_visual)}
              </section>
            );
          } else if (type === "color") {
            input_content = (
              <section>
                <input
                  name={key}
                  value={value}
                  onChange={this.on_change}
                  type="color"
                  className="margin_bottom"
                />
                <input
                  name={key}
                  value={value}
                  onChange={this.on_change}
                  type="text"
                />
              </section>
            );
          } else if (type === "options") {
            input_content = (
              <section>
                <select name={key} value={value} onChange={this.on_change}>
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </section>
            );
          }
          return (
            <div
              key={idx}
              className="container_light background_grey_light margin_bottom"
            >
              <p className="text_small">
                {name} ({value})
              </p>
              {input_content}
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(DEFAULT_STYLE_EDITOR);
