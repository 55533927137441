
const keysToDelete = [
  "poi_features",
  "flood_neighbor",
  "landslide_neighbor",
  "tsunami_neighbor",
  "jenis_bangunan_resume",
  "poi_survey_1_features",
  "manifest_survey_1",
  "geojson_polygon_isochrone",
  "landzone_neighbor",
  "poi_property",
  "landvalue_neighbor",
  "rdtr_neighbor",
]

const properties_only = [
  "demography_neighbor",
  "demography_one",
  "landvalue_one",
  "landzone_one",
  "people_spending",
  "rdtr_one",
  "rdtr_neighbor",
  "flood_one"
]

export const compress_sini = (data) => {
  let new_data = { ...data }; // Copy object agar tidak merusak original
  keysToDelete.forEach(key => delete new_data[key]);

  properties_only.forEach(key => {
    if (new_data?.[key]?.properties) {
      new_data[key] = new_data?.[key]?.properties
    } else if (Array.isArray(new_data?.[key])) {
      new_data[key] = new_data?.[key]?.map(item => item?.properties)
    }
  })

  return new_data
}