/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
/*
  "JENIS",
  "MIN_LUAS",
  "MAX_LUAS",
  "MIN_RASIO",
  "MAX_RASIO",
  "MIN_LEBAR_JALAN",
  "TIPE_BANGUNAN",
  "BIAYA_PEMBANGUNAN_PER_M_2",
  "HARGA_SEWA_PER_M_2_PER_TAHUN",
  "OKUPANSI_PER_TAHUN",
  "DISEWAKAN_DIGUNAKAN",
  "FASUM",
  "PENDAPATAN PER M_2",
  "RASIO_BIOP_MIN",
  "RASIO_BIOP_MAX",
  "BIOP_PER_M_2_MIN",
  "BIOP_PER_M_2_MAX"
  */

class UJI_LUAS_BANGUNAN extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const {
      luas_tanah,
      LLBE,
      LDBE,
      LLB,
      LDB,
      LLB_excel,
      LDB_excel,
      JLB,
      hbu_type,
      panjang_tanah,
      lebar_tanah,
      ratio_tanah,
      rows_filtered_3,
      rows_rejected_3,
      is_force_pass_3
    } = this.props.hbu_reducer;

    // digunakan ketika data diloloskan secara paksa
    const is_not_same = rows_filtered_3?.length !== rows_rejected_3?.length

    const is_terbangun = hbu_type === "HBU Terbangun";
    const label_luas_bangunan = is_terbangun ? "Luas Lantai Bangunan Eksisting (LLBE)" : "Luas Lantai Bangunan (LLB)"
    const luas_bangunan = is_terbangun ? LLBE : (LLB_excel || LLB)
    const label_luas_dasar_bangunan = is_terbangun ? "Luas Dasar Bangunan Eksisting (LDBE)" : "Luas Dasar Bangunan (LDB)"
    const luas_dasar_bangunan = is_terbangun ? LDBE : (LDB_excel || LDB)
    

    //content

    return (
      <main className="two_container_auto">
        <section>
          <h3 className="text_bold margin_bottom">Data objek</h3>
          <table className="table margin_bottom">
            <tbody>
              <tr>
                <td>Luas tanah</td>
                <td>{luas_tanah}</td>
              </tr>
              <tr>
                <td>{label_luas_bangunan}</td>
                <td>{luas_bangunan}</td>
              </tr>
              <tr>
                <td>{label_luas_dasar_bangunan}</td>
                <td>{luas_dasar_bangunan}</td>
              </tr>
              <tr>
                <td>Panjang tanah</td>
                <td>{panjang_tanah}</td>
              </tr>
              <tr>
                <td>Lebar tanah</td>
                <td>{lebar_tanah}</td>
              </tr>
              <tr>
                <td>Rasion panjang/lebar</td>
                <td>{ratio_tanah}</td>
              </tr>
              <tr>
                <td>Jumlah lantai bangunan (JLB)</td>
                <td>{JLB}</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h3 className="text_bold margin_bottom">Hasil uji</h3>
          <table className="table">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama alternatif</th>
                <th>Luas dasar bangunan minimal</th>
                <th>Luas dasar bangunan maksimal</th>
                <th>Jumlah lantai minimal</th>
                <th>Jumlah lantai maksimal</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={7}>Lolos uji</td>
              </tr>
              {!is_force_pass_3 && is_not_same && rows_filtered_3.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item?.JENIS}</td>
                    <td>{item?.MIN_LDB}</td>
                    <td>{item?.MAX_LDB}</td>
                    <td>{item?.MIN_LANTAI}</td>
                    <td>{item?.MAX_LANTAI}</td>
                    <td>
                      <div className="badge background_green">LOLOS UJI</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tbody>
              <tr>
                <td colSpan={7}>Tidak lolos uji</td>
              </tr>
              {rows_rejected_3.map((item, idx) => {
                let text = "OBJEK TERLALU KECIL";
                if (parseFloat(luas_tanah) > parseFloat(item?.MAX_LUAS))
                  text = "OBJEK TERLALU LUAS";
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item?.JENIS}</td>
                    <td>{item?.MIN_LDB}</td>
                    <td>{item?.MAX_LDB}</td>
                    <td>{item?.MIN_LANTAI}</td>
                    <td>{item?.MAX_LANTAI}</td>
                    <td>
                      <div className="badge background_red">{text}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(UJI_LUAS_BANGUNAN);
