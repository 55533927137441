/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import { createLayer } from "../../App/actions/layerNewActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import { select_field_from_default_props } from "../../App/validation/generateGeoJson";

/*NON IMPORT*/

class CreateLayer extends Component {
  state = {
    name: "",
    description: "",
    type: "Point",
    user_id: this.props.auth.user._id,
    folder: "",
    modal_warn: false,
  };

  componentDidMount() {
    const { _id } = this.props.auth.user;
    this.setState({ user_id: _id });
  }

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { geo_project } = this.props.project;
    const { name, description, type, folder, user_id } = this.state;

    if (!name) {
      this.toggle_warn();
    } else {
      const fields = select_field_from_default_props(type);

      //khusus_danamas
      const domain = window.location.hostname;
      let architecture;
      if (domain === "danamas.mapid.io") {
        architecture = "v1";
      } else {
        architecture = process.env.REACT_APP_ARCHITECTURE;
      }

      const body = {
        name,
        description,
        type,
        folder,
        user_id,
        geo_project_id: geo_project._id,
        fields: [],
        properties: fields,
        status: "digitasi",
        architecture: architecture, // penambahan versi untuk arcitekture data v2
      };
      this.props.createLayer(body);

      this.setState({
        name: "",
        description: "",
        type: "Point",
        user_id: this.props.auth.user._id,
        folder: "",
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name, description, type, folder, modal_warn } = this.state;

    //global props
    const { geo_project, loadingDetail } = this.props.project;
    const { loadingProcess, itemLoading } = this.props.layer;

    //content
    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body" id="box-body">
          Mohon isi nama layer terlebih dahulu
        </div>
      </Modal>
    );

    const { folders } = geo_project;
    let folders_state = [];
    if (!loadingDetail && geo_project.folders) {
      folders_state = folders.slice();
      folders_state.unshift({
        name: dict?.["Outside folders"]?.[language],
        _id: "",
      });
    }

    const layer_type_array = ["Point", "LineString", "Polygon"];
    let button_content;
    if (loadingProcess && itemLoading === "createLayer") {
      button_content = (
        <div type="submit" className="button background_grey">
          {dict?.["Create Layer"]?.[language]}...
        </div>
      );
    } else {
      button_content = (
        <button
          type="submit"
          className={`button ${name ? "background_blue" : "background_grey"}`}
          data-mapid="clickCreate"
        >
          {dict?.["Create Layer"]?.[language]}
        </button>
      );
    }

    const content = (
      <main>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <input
            type="text"
            placeholder={dict["Layer name"][language]}
            autoComplete="off"
            className="input_new"
            name="name"
            value={name}
            onChange={this.handleChange.bind(this)}
            data-mapid="inputLayerName"
          />
          <textarea
            type="text"
            placeholder={dict["Layer description"][language]}
            autoComplete="off"
            className="input_new"
            name="description"
            value={description}
            onChange={this.handleChange.bind(this)}
            data-mapid="inputLayerDeskription"
          />
          <select
            id="layer_type"
            name="type"
            value={type}
            className="input_new"
            onChange={this.handleChange.bind(this)}
            data-mapid="selectType"
          >
            {layer_type_array.map((type, idx) => {
              return (
                <option key={idx} value={type}>
                  {type}
                </option>
              );
            })}
          </select>
          <select
            id="folder"
            name="folder"
            value={folder}
            className="input_new"
            onChange={this.handleChange.bind(this)}
            data-mapid="selectFolder"
          >
            {folders_state.map(({ _id, name }, idx) => {
              return (
                <option key={idx} value={_id}>
                  {name}
                </option>
              );
            })}
          </select>
          <div>{button_content}</div>
        </form>
      </main>
    );

    return (
      <main>
        {content}
        {modal_warn_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createLayer,
})(CreateLayer);
