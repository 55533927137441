import React from "react";
const format_ina_number = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "decimal",
  }).format(number);
};

const FORMULA_SIM = ({
  feature_best,
  feature_sample,
  array_formula_insight,
  config_final_score,
  array_group_insight,
}) => {
  if (!array_formula_insight || array_formula_insight.length === 0) {
    return <p>No data</p>;
  }
  const { total_weight_score, min_weighted, max_weighted } = config_final_score;
  const feature_final = feature_sample ? feature_sample : feature_best;
  return (
    <div className="margin_bottom">
      <h1 className="text_bold">Contoh perhitungan formula</h1>
      <p className="text_medium margin_bottom">
        Diambil dari data di dalam grid terbaik
      </p>
      <p className="text_medium margin_bottom">
        Total bobot: {total_weight_score}
      </p>
      <div style={{ maxWidth: "100%", overflowX: "auto" }}>
        <table className="table margin_right">
          {array_group_insight.map((group, index_group) => {
            const array_formula_insight_local = array_formula_insight.filter(
              (formula) => formula.group === group.key
            );
            return (
              <tbody key={index_group}>
                <tr>
                  <td className="text_bold">{group.key}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>
                    {group.key === "poi" ? "Jumlah titik" : "Data demografi"}
                  </td>
                  <td>
                    Rentang nilai untuk semua grid
                    <br />
                    (min-max)
                  </td>
                  <td>
                    Berbanding lurus/
                    <br />
                    terbalik
                  </td>
                  <td>
                    Nilai ter-normalisasi
                    <br />
                    (0-1)
                  </td>
                  <td>Bobot</td>
                  <td>Nilai dengan bobot</td>
                </tr>
                {array_formula_insight_local.map((formula, idx) => {
                  let title_formula = "";
                  if (formula.is_directly_proportional) {
                    title_formula = `normalized_value = (value - min_raw) / (max_raw - min_raw);
normalized_value = ( ${format_ina_number(
                      feature_final?.properties?.[`${formula.key}`]
                    )} - ${format_ina_number(
                      formula.min_raw
                    )}) / ( ${format_ina_number(
                      formula.max_raw
                    )} - ${format_ina_number(formula.min_raw)} )
normalized_value = ${format_ina_number(
                      feature_final?.properties?.[`${formula.key}_NORMAL`]
                    )}`;
                  } else {
                    title_formula = `normalized_value = 1 - (value - min_raw) / (max_raw - min_raw);
normalized_value = 1 - (${new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(
                      feature_final?.properties?.[`${formula.key}`]
                    )} - ${new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(formula.min_raw)}) / ( ${new Intl.NumberFormat(
                      "id-ID",
                      {
                        style: "decimal",
                      }
                    ).format(formula.max_raw)} - ${new Intl.NumberFormat(
                      "id-ID",
                      {
                        style: "decimal",
                      }
                    ).format(formula.min_raw)} ) 
normalized_value = ${new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(
                      feature_final?.properties?.[`${formula.key}_NORMAL`]
                    )}`;
                  }

                  return (
                    <tr key={idx}>
                      <td>{formula.key}</td>
                      <td className="text_right">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(
                          feature_final?.properties?.[`${formula.key}`]
                        )}
                      </td>
                      <td className="text_center">
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(formula.min_raw)}{" "}
                        -{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(formula.max_raw)}
                      </td>
                      <td className="text_center">
                        {formula.is_directly_proportional ? (
                          <div className="badge_circle background_green">+</div>
                        ) : (
                          <div className="badge_circle background_red">-</div>
                        )}
                      </td>
                      <td title={title_formula} className="text_right">
                        <p className="max_width">
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(
                            feature_final?.properties?.[`${formula.key}_NORMAL`]
                          )}
                        </p>
                      </td>
                      <td className="text_right">{formula.weight_score}</td>
                      <td
                        className="text_right"
                        title={`= ( bobot / total bobot) * hasil normalisasi
= ( ${formula.weight_score} / ${total_weight_score} ) * ${new Intl.NumberFormat(
                          "id-ID",
                          {
                            style: "decimal",
                          }
                        ).format(
                          feature_final?.properties?.[`${formula.key}_NORMAL`]
                        )}
= ${new Intl.NumberFormat("id-ID", {
                          style: "decimal",
                        }).format(
                          feature_final?.properties?.[`${formula.key}_WEIGHTED`]
                        )}`}
                      >
                        <p className="max_width">
                          {new Intl.NumberFormat("id-ID", {
                            style: "decimal",
                          }).format(
                            feature_final?.properties?.[
                              `${formula.key}_WEIGHTED`
                            ]
                          )}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            );
          })}
          <tbody>
            <tr>
              <td colSpan={6} className="text_right">
                Sum nilai
              </td>
              <td
                className="text_right"
                title={`${array_formula_insight.map((formula, idx) => {
                  let content = new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(
                    feature_final?.properties?.[`${formula.key}_WEIGHTED`]
                  );
                  if (idx < array_formula_insight.length - 1) content += "+";
                  return content;
                })}`}
              >
                <p className="max_width">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(feature_final?.properties?.[`TOTAL_WEIGHTED`])}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text_right">
                Rentang nilai semua grid (min-max)
              </td>
              <td className="text_right">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(min_weighted)}{" "}
                -{" "}
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(max_weighted)}
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text_right">
                Sum nilai setelah normalisasi
              </td>
              <td
                className="text_right"
                title={`= (total_weighted - min_weighted) / (max_weighted - min_weighted)
= ( ${format_ina_number(
                  feature_final?.properties?.[`TOTAL_WEIGHTED`]
                )} - ${format_ina_number(
                  min_weighted
                )} ) / ( ${format_ina_number(
                  max_weighted
                )}  - ${format_ina_number(min_weighted)})`}
              >
                <p className="max_width">
                  {new Intl.NumberFormat("id-ID", {
                    style: "decimal",
                  }).format(feature_final?.properties?.[`NORMALIZE_SCORE`])}
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={6} className="text_right">
                Nilai dalam persen (nilai akhir)
              </td>
              <td className="text_right">
                {new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(feature_final?.properties?.[`FINAL_NUMBER_SCORE`])}
              </td>
            </tr>
            <tr
              title={`if (score >= 80) return "Sangat Sesuai";
    if (score >= 60) return "Sesuai";
    if (score >= 40) return "Cukup Sesuai";
    if (score >= 20) return "Tidak Sesuai";
    else "Sangat Tidak Sesuai";`}
            >
              <td colSpan={6} className="text_right">
                <p className="max_width">Status</p>
              </td>
              <td className="text_right">
                <p className="max_width">
                  {feature_final?.properties?.[`FINAL_STATUS_SCORE`]}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
    </div>
  );
};

export default FORMULA_SIM;
