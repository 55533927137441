/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/
import INPUT_DEMOGRAPHY from "./INPUT_DEMOGRAPHY";

/*REDUX*/
import {
  set_value_insight,
  insight_update,
} from "../../App/actions/insight_v2";

/*PICTURE*/
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";

/*FUNCTION*/
import run_scoring from "../../Functions/insight/run_scoring";

/*DATA*/

/*CONST*/

class INPUT_FORMULA_CONTENT extends Component {
  state = {
    anchor: null,
    modal_edit_weight: false,
    key_selected: "",
    weight_score_selected: 1,
  };

  on_run_scoring = ({ is_toggle_full_screen }) => {
    if (is_toggle_full_screen) this.props.toggle_full_screen();
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "formula",
    });
    this.props.set_value_insight({
      key: "step_formula",
      value: 1,
    });
    let {
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
    } = this.props.insight_reducer;
    run_scoring({
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      set_value_insight: this.props.set_value_insight,
      insight_update: this.props.insight_update,
    });
  };

  toggle_is_directly_proportional = (key) => {
    const { array_formula_insight } = this.props.insight_reducer;
    const object = array_formula_insight.find((item) => item.key === key);
    object.is_directly_proportional = !object.is_directly_proportional;
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
    this.on_run_scoring({ is_toggle_full_screen: false });
  };

  toggle_edit_weight = (event) => {
    this.setState(
      {
        modal_edit_weight: !this.state.modal_edit_weight,
        anchor: event.currentTarget,
      },
      () => {
        const { modal_edit_weight } = this.state;
        if (modal_edit_weight) {
          const key_selected = event.target.id;
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === key_selected
          );
          const weight_score_selected = object.weight_score;
          this.setState({ key_selected, weight_score_selected });
        }
      }
    );
  };

  on_change_weight = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    this.setState(
      {
        weight_score_selected: value,
      },
      () => {
        if (value > 0) {
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === name
          );
          object.weight_score = value;
          this.props.set_value_insight({
            key: "array_formula_insight",
            value: array_formula_insight,
          });
          this.on_run_scoring({ is_toggle_full_screen: false });
        }
      }
    );
  };

  on_click_step = (step_formula) => {
    this.props.set_value_insight({
      key: "step_formula",
      value: step_formula,
    });
  };

  render() {
    //local storage

    //local state
    const { anchor, modal_edit_weight, key_selected, weight_score_selected } =
      this.state;

    //global props
    const { array_formula_insight, array_group_insight, step_formula } =
      this.props.insight_reducer;

    //content
    const modal_edit_weight_content = modal_edit_weight && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_edit_weight}
        onClose={this.toggle_edit_weight}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px", width: "100px" }}>
          <input
            type="number"
            name={key_selected}
            value={weight_score_selected}
            onChange={this.on_change_weight}
          />
        </div>
      </Menu>
    );

    const demography_content = <INPUT_DEMOGRAPHY />;

    const poi_content = (
      <section>
        <section id="tree_diagram">
          <div className="branch_tree_diagram">
            <article className="entry_tree_diagram">
              <span className="button_small background_black">Skor akhir</span>
              <div className="branch_tree_diagram">
                {array_group_insight.map((parent) => {
                  const child_array = array_formula_insight.filter(
                    (child) => child.group === parent.key
                  );
                  return (
                    <article className="entry_tree_diagram" key={parent.key}>
                      <span className="button_small background_black">
                        {parent.key}
                      </span>
                      <div className="branch_tree_diagram">
                        {child_array.map((child) => {
                          const { is_directly_proportional, weight_score } =
                            child;
                          return (
                            <article
                              className="entry_tree_diagram"
                              key={child.key}
                            >
                              <span className="button background_grey_light outline_grey">
                                <section
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20px 30px auto",
                                    gridGap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className={`badge_circle ${
                                      is_directly_proportional
                                        ? "background_green"
                                        : "background_red"
                                    }`}
                                    onClick={this.toggle_is_directly_proportional.bind(
                                      this,
                                      child.key
                                    )}
                                  >
                                    {is_directly_proportional ? (
                                      <ICON_ARROW_UP line_color="#fff" />
                                    ) : (
                                      <ICON_ARROW_DOWN line_color="#fff" />
                                    )}
                                  </button>
                                  <button
                                    className="button_small button_white outline_grey"
                                    id={child.key}
                                    onClick={this.toggle_edit_weight}
                                  >
                                    {weight_score}
                                  </button>
                                  <p className="text_small text_left">
                                    {child.key}
                                  </p>
                                </section>
                              </span>
                            </article>
                          );
                        })}
                      </div>
                    </article>
                  );
                })}
              </div>
            </article>
          </div>
        </section>
        <table className="table">
          <tbody>
            <tr>
              <td>Simbol</td>
              <td>Arti</td>
              <td>Keterangan</td>
            </tr>
            <tr>
              <td>
                <div className="badge_circle background_green">
                  <ICON_ARROW_UP line_color="#fff" />
                </div>
              </td>
              <td>Berbanding lurus</td>
              <td>
                Jika nilai dari parameter besar maka akan memperbesar skor
                akhir, gunakan ini jika ingin mencari area yang paling banyak
                mengandung nilai ini di dalamnya.
              </td>
            </tr>
            <tr>
              <td>
                <div className="badge_circle background_red">
                  <ICON_ARROW_DOWN line_color="#fff" />
                </div>
              </td>
              <td>Berbanding berbalik</td>
              <td>
                Jika nilai dari parameter kecil maka akan memperbesar skor
                akhir, gunakan ini jika ingin mencari area yang paling sedikit
                mengandung nilai ini di dalamnya.
              </td>
            </tr>
            <tr>
              <td>
                <button className="button button_white outline_grey">1</button>
              </td>
              <td>Bobot parameter</td>
              <td>
                Jika angka yang dimasukkan melebihi dari parameter lainnya maka
                pengaruhnya akan lebih besar dalam perhitungan skor akhir
                (begitu juga sebaliknya).
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );

    return (
      <main className="max_height parent_section">
        {modal_edit_weight_content}
        <section className="section_1">
          <div className="two_container_auto">
            <aside>
              <h1 className="text_header">Atur formula</h1>
            </aside>
            <aside className="text_right">
              <button
                className={`button_pill outline_black margin_right ${
                  step_formula === 0
                    ? "background_black"
                    : "background_grey_light"
                }`}
                onClick={this.on_click_step.bind(this, 0)}
              >
                <span className="badge_circle background_black margin_right">
                  1
                </span>
                <span className="margin_right">Demografi</span>
              </button>

              <button
                className={`button_pill outline_black margin_right ${
                  step_formula === 1 ? "background_black" : "button_white"
                }`}
                onClick={this.on_click_step.bind(this, 1)}
              >
                <span className="badge_circle background_black margin_right">
                  2
                </span>
                <span className="margin_right">Formula</span>
              </button>

              <button
                className="button_pill background_grey_light outline_black margin_right"
                onClick={this.on_run_scoring.bind(this, {
                  is_toggle_full_screen: true,
                })}
              >
                <span className="badge_circle background_black margin_right">
                  3
                </span>
                <span className="margin_right">Run</span>
              </button>
            </aside>
          </div>
        </section>
        <section className="section_2">
          {step_formula === 0 ? demography_content : poi_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight, insight_update })(
  INPUT_FORMULA_CONTENT
);
