/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import UJI_LUAS from "./UJI_LUAS";
import UJI_RASIO from "./UJI_RASIO";
import UJI_FINANSIAL from "./UJI_FINANSIAL";
import UJI_LEGAL from "./UJI_LEGAL";


/*REDUX*/

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";

/*FUNCTION*/
import UJI_LUAS_BANGUNAN from "./UJI_LUAS_BANGUNAN";
import UJI_LEBAR_JALAN from "./UJI_LEBAR_JALAN";
import UJI_SUPPLY_DEMAND from "./UJI_SUPPLY_DEMAND";

/*DATA*/

/*CONST*/

class HBU_MANUAL extends Component {
  state = {
    modal_show_all_alt: false,
    modal_uji_luas: false,
    modal_uji_rasio: false,
    modal_uji_luas_bangunan: false,
    modal_uji_lebar_jalan: false,
    modal_uji_supply_demand: false,
    modal_uji_legal: false
  };

  toggle_uji_rasio = () => {
    this.setState({
      modal_uji_rasio: !this.state.modal_uji_rasio,
    });
  };

  toggle_uji_luas = () => {
    this.setState({
      modal_uji_luas: !this.state.modal_uji_luas,
    });
  };
  toggle_uji_lebar_jalan = () => {
    this.setState({
      modal_uji_lebar_jalan: !this.state.modal_uji_lebar_jalan,
    });
  };

  toggle_uji_luas_bangunan = () => {
    this.setState({
      modal_uji_luas_bangunan: !this.state.modal_uji_luas_bangunan,
    });
  };

  toggle_uji_supply_demand = () => {
    this.setState({
      modal_uji_supply_demand: !this.state.modal_uji_supply_demand,
    });
  };

  toggle_uji_legal = () => {
    this.setState({
      modal_uji_legal: !this.state.modal_uji_legal,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  toggle_show_all_alt = () => {
    this.setState({ modal_show_all_alt: !this.state.modal_show_all_alt });
  };

  render() {
    //local storage

    //local state
    const { modal_show_all_alt, modal_uji_luas, modal_uji_rasio, modal_uji_luas_bangunan, modal_uji_lebar_jalan, modal_uji_supply_demand, modal_uji_legal } = this.state;

    //global props
    const {
      peruntukan,
      KDB,
      KLB,
      GSB,
      headers,
      rows,
      luas_tanah,
      LLBE,
      panjang_tanah,
      lebar_tanah,
      ratio_tanah,
      rows_filtered_1,
      rows_filtered_2,
      rows_filtered_3,
      rows_filtered_4,
      rows_filtered_5,
      rows_rejected_1,
      rows_rejected_2,
      rows_rejected_3,
      rows_rejected_4,
      rows_rejected_5,
      is_force_pass_1,
      is_force_pass_2,
      is_force_pass_3,
      is_force_pass_4,
      is_force_pass_5,
      kesimpulan_uji_legal,
      hbu_type,
      LLB_excel,
      LLB,
    } = this.props.hbu_reducer;

    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;

    const sini_object = ai_parent_detail_list.find(
      (item) => item?.request_id === sheet_object_selected?.request_id
    );
    const landvalue_one = sini_object?.landvalue_one;
    const landvalue_value = landvalue_one?.properties?.["HARGA"];

    const is_terbangun = hbu_type === "HBU Terbangun";
    const label_luas_bangunan = is_terbangun ? "Luas Lantai Bangunan Eksisting (LLBE)" : "Luas Lantai Bangunan (LLB)"
    const luas_bangunan = is_terbangun ? LLBE : (LLB_excel || LLB)

    // const poi_resume_2 = sini_object?.poi_resume_2;
    // const sorted_poi_resume_2 = sort_array(
    //   Object.keys(poi_resume_2)?.map((item) => poi_resume_2?.[item]),
    //   "number",
    //   false
    // );

    const modal_uji_legal_content = modal_uji_legal && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_legal}
        onClose={this.toggle_uji_legal}
      >
        <div className="box-body" id="box-body">
          <UJI_LEGAL />
        </div>
      </Modal>
    );

    const modal_uji_rasio_content = modal_uji_rasio && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_rasio}
        onClose={this.toggle_uji_rasio}
      >
        <div className="box-body" id="box-body">
          <UJI_RASIO />
        </div>
      </Modal>
    );

    const modal_uji_luas_content = modal_uji_luas && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_luas}
        onClose={this.toggle_uji_luas}
      >
        <div className="box-body" id="box-body">
          <UJI_LUAS />
        </div>
      </Modal>
    );

    const modal_uji_luas_bangunan_content = modal_uji_luas_bangunan && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_luas_bangunan}
        onClose={this.toggle_uji_luas_bangunan}
      >
        <div className="box-body" id="box-body">
          <UJI_LUAS_BANGUNAN />
        </div>
      </Modal>
    );

    const modal_uji_lebar_jalan_content = modal_uji_lebar_jalan && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_lebar_jalan}
        onClose={this.toggle_uji_lebar_jalan}
      >
        <div className="box-body" id="box-body">
          <UJI_LEBAR_JALAN />
        </div>
      </Modal>
    );

    const modal_uji_supply_demand_content = modal_uji_supply_demand && (
      <Modal
        modalSize="medium"
        isOpen={modal_uji_supply_demand}
        onClose={this.toggle_uji_supply_demand}
      >
        <div className="box-body" id="box-body">
          <UJI_SUPPLY_DEMAND />
        </div>
      </Modal>
    );

    const modal_show_all_alt_content = modal_show_all_alt && (
      <Modal
        modalSize="large"
        isOpen={modal_show_all_alt}
        onClose={this.toggle_show_all_alt}
      >
        <div className="box-body" id="box-body">
          <section style={{ overflowX: "auto" }}>
            <table className="table scroll_auto">
              <thead>
                <tr>
                  <td>No</td>
                  {headers.map((header, index) => (
                    <td key={index}>{header}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    {headers.map((header, index) => (
                      <td key={index}>{item[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </Modal>
    );

    return (
      <main
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          overflowY: "auto",
          height: "100%",
          gap: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {modal_show_all_alt_content}
        {modal_uji_legal_content}
        {modal_uji_luas_content}
        {modal_uji_rasio_content}
        {modal_uji_luas_bangunan_content}
        {modal_uji_lebar_jalan_content}
        {modal_uji_supply_demand_content}
        <table className="table margin_bottom">
          <tbody>
            <tr>
              <td>Luas tanah</td>
              <td>{luas_tanah}</td>
            </tr>
            <tr>
              <td>{label_luas_bangunan} m2</td>
              <td>{luas_bangunan}</td>
            </tr>
            <tr>
              <td>Panjang tanah</td>
              <td>{panjang_tanah}</td>
            </tr>
            <tr>
              <td>Lebar tanah</td>
              <td>{lebar_tanah}</td>
            </tr>
            <tr>
              <td>Rasion panjang/lebar</td>
              <td>{ratio_tanah}</td>
            </tr>
            <tr>
              <td>Landvalue SINI</td>
              <td>{landvalue_value}</td>
            </tr>
          </tbody>
        </table>

        <button
          className="button background_black margin_bottom margin_right"
          onClick={this.toggle_show_all_alt}
        >
          Buka pedoman
        </button>

        {/* uji legal */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Legal</div>
          <div className="container background_white margin_bottom">
            <table>
              <tbody>
                <tr>
                  <td className="text_medium_normal">Zoning</td>
                  <td>:</td>
                  <td className="text_bold"> {peruntukan}</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">KDB</td>
                  <td>:</td>
                  <td className="text_bold"> {KDB}%</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">GSB</td>
                  <td>:</td>
                  <td className="text_bold"> {GSB}</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">KLB</td>
                  <td>:</td>
                  <td className="text_bold"> {KLB}</td>
                </tr>
                <tr>
                  <td className="text_medium_normal">{`Luas Lantai Bangunan Eksisting (LLBE)`}</td>
                  <td>:</td>
                  <td className="text_bold">
                    {" "}
                    {LLBE} m<sup>2</sup>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <article
            className="container background_white"
            style={{ position: "relative", paddingBottom: "35px" }}
          >
            <div className="two_container_small_right">
              <h1 className="text_bold margin_bottom">
                Perhitungan Sesuai Tata Ruang
              </h1>
              <img
                className="cursor_pointer text_right"
                alt="expand"
                src={expand}
                style={{ height: "20px" }}
                onClick={this.toggle_uji_legal}
              />
            </div>
            <p className="text_medium">{kesimpulan_uji_legal?.kesimpulan}</p>

          </article>

        </section>

        {/* supply demand */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">
            Uji Kecocokan supply dan demand
          </div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {!is_force_pass_1 &&
                        rows_filtered_1.map((item, idx) => {
                          return (
                            <div key={idx}>
                              {idx + 1}. {item.JENIS}
                            </div>
                          );
                        })}
                    </td>
                    <td>
                      {rows_rejected_1.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_supply_demand}
                />
              </div>
              <p
                className="text_medium"
                style={{ maxHeight: 400, overflowY: "auto", marginBottom: "10px" }}
              >
                Uji supply-demand dalam penentuan Highest and Best Use (HBU) bertujuan untuk menganalisis keseimbangan antara permintaan pasar dan ketersediaan pasokan suatu jenis properti di lokasi tertentu. Evaluasi ini mempertimbangkan faktor seperti tren pasar, pertumbuhan populasi, serta potensi kompetisi. Dengan memahami dinamika supply-demand, membantu penentuan jenis penggunaan lahan yang memiliki prospek ekonomi terbaik dan mengoptimalkan tingkat okupansi serta profitabilitas dalam jangka panjang.
              </p>
              {is_force_pass_1 && <label>- Diloloskan -</label>}
            </article>
          </div>
        </section>

        {/* luas tanah */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Luas Tanah</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_2.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_2.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white" >
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas}
                />
              </div>
              <p className="text_medium" style={{ marginBottom: "10px" }}>
                Uji luas objek dalam penentuan Highest and Best Use (HBU) bertujuan memastikan lahan sesuai dengan regulasi dan mendukung pengembangan yang paling menguntungkan. Proses ini membantu mengevaluasi kelayakan investasi, mengoptimalkan pemanfaatan sumber daya, serta meningkatkan nilai properti dengan memilih alternatif penggunaan terbaik berdasarkan aspek fisik, ekonomi, dan hukum.
              </p>
              {is_force_pass_2 && <label>- Diloloskan -</label>}
            </article>
          </div>
        </section>

        {/* luas dasar bangunan */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Luas Bangunan</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_3?.filter(item => !item?.is_not_pass)?.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_3.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_luas_bangunan}
                />
              </div>
              <p className="text_medium" style={{ marginBottom: "10px" }}>
                Uji fisik luas bangunan dalam penentuan Highest and Best Use (HBU) bertujuan untuk memastikan bahwa luas bangunan yang direncanakan sesuai dengan regulasi serta mendukung optimalisasi pemanfaatan lahan. Evaluasi ini mempertimbangkan ketentuan tata ruang, koefisien dasar bangunan (KDB), serta kebutuhan ruang berdasarkan fungsi properti yang diusulkan. Selain itu, analisis ini juga menilai efisiensi penggunaan lahan untuk memaksimalkan nilai ekonomi, memastikan kepatuhan terhadap peraturan zonasi, serta mengakomodasi kebutuhan pasar dan infrastruktur pendukung.
              </p>
              {is_force_pass_3 && <label>- Diloloskan -</label>}
            </article>
          </div>
        </section>

        {/* lebar jalan */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Lebar Jalan</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_4.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_4.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_lebar_jalan}
                />
              </div>
              <p className="text_medium margin_bottom" style={{ marginBottom: "10px" }}>
                Lebar jalan dalam penentuan Highest and Best Use (HBU) berperan penting dalam menentukan jenis penggunaan lahan yang paling sesuai dengan kondisi aksesibilitas di suatu lokasi. Analisis ini mempertimbangkan standar lebar jalan berdasarkan regulasi zonasi dan kapasitas lalu lintas. Lebar jalan yang memadai mendukung kelancaran mobilitas, kemudahan akses bagi pelanggan atau penghuni, serta kepatuhan terhadap tata ruang. Selain itu, faktor ini juga mempengaruhi nilai properti dan kelayakan investasi, terutama untuk sektor-sektor yang bergantung pada akses transportasi, retail, perkantoran, atau hunian vertikal.
              </p>
              {is_force_pass_4 && <label>- Diloloskan -</label>}
            </article>
          </div>
        </section>

        {/* rasio */}
        <section className="container background_grey_light margin_bottom">
          <div className="text_bold margin_bottom">Uji Fisik Rasio dimensi</div>
          <div className="two_container_equal">
            <aside>
              <table
                className="table margin_bottom"
                style={{ tableLayout: "fixed", width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Memenuhi Syarat</td>
                    <td>Tidak Memenuhi Syarat</td>
                  </tr>
                  <tr>
                    <td>
                      {rows_filtered_5.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {rows_rejected_5.map((item, idx) => {
                        return (
                          <div key={idx}>
                            {idx + 1}. {item.JENIS}
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </thead>
              </table>
            </aside>
            <article className="container background_white">
              <div className="two_container_small_right">
                <h1 className="text_bold margin_bottom">Penjelasan</h1>
                <img
                  className="cursor_pointer text_right"
                  alt="expand"
                  src={expand}
                  style={{ height: "20px" }}
                  onClick={this.toggle_uji_rasio}
                />
              </div>
              <p className="text_medium margin_bottom" style={{ marginBottom: "10px" }}>
                Uji rasio dimensi, seperti 30x30 meter (persegi) atau 30x20 meter (persegi panjang), dalam penentuan Highest and Best Use (HBU) perlu dilakukan untuk memastikan efisiensi tata letak dan optimalisasi pemanfaatan lahan. Rasio ini berpengaruh terhadap desain bangunan, aksesibilitas, serta kepatuhan terhadap regulasi zonasi dan tata ruang. Selain itu, pemilihan dimensi yang tepat membantu meningkatkan nilai ekonomi properti dengan menyesuaikan bentuk lahan terhadap kebutuhan pasar dan jenis penggunaan terbaiknya, seperti komersial, residensial, atau industri.
              </p>
              {is_force_pass_5 && <label>- Diloloskan -</label>}
            </article>
          </div>
        </section>

        {/* ================================================================ */}

        <UJI_FINANSIAL {...this.props} on_run={this.props.on_run} />
      </main >
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
  hbu_reducer: state.hbu_reducer,
});

export default connect(mapStateToProps, {})(HBU_MANUAL);
