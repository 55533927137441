/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { create_payment } from "../../App/actions/paymentActions";
/*PICTURE ASSET*/
import icon_gift from "../../Assets/svg/gift.svg";
import Pricing from "./Pricing";
import { Modal } from "../reuseable";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/
class RedeemPopup extends Component {
  toggle_modal_redeem = () => {
    const payment = localStorage.getItem("payment");
    if (payment) {
      localStorage.removeItem("payment");
    }
    this.props.set_value_properties({
      key: "modal_redeem",
      value: !this.props.properties.modal_redeem,
    });
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const payment = localStorage.getItem("payment");
    const parampayment = params.get("payment");

    if (payment || parampayment) {
      if (payment !== "") {
        this.props.set_value_properties({
          key: "modal_redeem",
          value: !this.props.properties.modal_redeem,
        });
      } else if (parampayment !== "") {
        localStorage.setItem("payment", parampayment);
        this.props.set_value_properties({
          key: "modal_redeem",
          value: !this.props.properties.modal_redeem,
        });
      }
    }
  }

  render() {
    const { language } = this.props;

    const modal_redeem = this.props.properties.modal_redeem;

    const modal_redeem_content = modal_redeem && (
      <Modal
        title={""}
        modalSize="large"
        id="modal_pricing"
        isOpen={modal_redeem}
        onClose={this.toggle_modal_redeem}
      >
        <main className="box-body flex flex_col gap_10 h_full">
          <Pricing create_payment={this.props.create_payment} />
        </main>
      </Modal>
    );

    //content
    return (
      <div className="sidebar_button vertical_center w_200 hover_lightGrey">
        <div
          onClick={this.toggle_modal_redeem}
          className="vertical_center_child"
        >
          <div style={{ marginLeft: "20px" }}>
            <img src={icon_gift} alt={"Redeem"} width="35px" />
          </div>
          <div
            className="text_medium"
            style={{ marginLeft: "20px", width: "250px", color: "black" }}
          >
            {dict["Offer"][language]}
          </div>
        </div>
        {modal_redeem_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_properties,
  create_payment,
})(RedeemPopup);
