const run_scoring = function ({
  features_grid,
  array_formula_insight,
  config_final_score,
  set_value_insight,
  insight_update,
}) {
  function get_textual_score(score) {
    if (score >= 80) return "Sangat Sesuai";
    if (score >= 60) return "Sesuai";
    if (score >= 40) return "Cukup Sesuai";
    if (score >= 20) return "Tidak Sesuai";
    return "Sangat Tidak Sesuai";
  }

  const sum_array = array_formula_insight.map((item) => item.weight_score);
  const total_weight_score = sum_array.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  config_final_score.total_weight_score = total_weight_score;

  array_formula_insight.forEach((formula) => {
    const { weight_score } = formula;
    let array_value = [];
    features_grid.forEach((feature) => {
      const value = feature?.properties?.[formula.key] || 0;
      array_value.push(value);
    });
    const min_raw = Math.min(...array_value);
    const max_raw = Math.max(...array_value);
    formula.min_raw = min_raw;
    formula.max_raw = max_raw;
    features_grid.forEach((feature) => {
      const value = feature.properties[formula.key] || 0;
      //Step 1: Normalisasi
      let normalized_value;
      if (formula.is_directly_proportional) {
        normalized_value = (value - min_raw) / (max_raw - min_raw);
      } else {
        normalized_value = 1 - (value - min_raw) / (max_raw - min_raw);
      }
      feature.properties[formula.key + "_NORMAL"] = normalized_value;

      //Step 2: Melibatkan bobot
      const weighted_value =
        (weight_score / total_weight_score) * normalized_value;
      feature.properties[formula.key + "_WEIGHTED"] = weighted_value;
    });
  });

  // First loop: Sum weighted values and calculate min/max weighted values
  let min_weighted = Infinity;
  let max_weighted = -Infinity;

  features_grid.forEach((feature) => {
    let total_weighted = 0;

    // Sum the weighted values for each formula
    array_formula_insight.forEach((formula) => {
      const weighted_value = feature.properties[formula.key + "_WEIGHTED"] || 0;
      total_weighted += weighted_value;
    });

    // Store the total weighted value for the feature
    feature.properties["TOTAL_WEIGHTED"] = total_weighted;

    // Update the min and max weighted values
    if (total_weighted < min_weighted) {
      min_weighted = total_weighted;
      config_final_score.min_weighted = min_weighted;
    }
    if (total_weighted > max_weighted) {
      max_weighted = total_weighted;
      config_final_score.max_weighted = max_weighted;
    }
  });

  // Second loop: Normalize, calculate percentage, and assign textual score
  features_grid.forEach((feature) => {
    const total_weighted = feature.properties["TOTAL_WEIGHTED"] || 0;

    // Step 1: Normalize the total weighted value to the range [0, 1]
    let normalized_score =
      (total_weighted - min_weighted) / (max_weighted - min_weighted);

    // Step 2: Convert normalized score into a percentage (0-100)
    const percentage_score = normalized_score * 100;

    // Step 3: Get the textual score based on the percentage
    const textual_score = get_textual_score(percentage_score);

    // Step 4: Store the final values in the feature's properties
    feature.properties["NORMALIZE_SCORE"] = normalized_score;
    feature.properties["FINAL_NUMBER_SCORE"] = percentage_score;
    feature.properties["FINAL_STATUS_SCORE"] = textual_score;
  });

  set_value_insight({
    key: "features_grid",
    value: features_grid,
  });
  set_value_insight({
    key: "array_formula_insight",
    value: array_formula_insight,
  });
  set_value_insight({
    key: "config_final_score",
    value: config_final_score,
  });
  insight_update();
};

export default run_scoring;
