import React, { Component } from 'react'
import TextFieldGroup from '../common_input/TextFieldGroup'

//General Function
import dict from "../../Data/dict.json";

export default class RegisterStep3Page1 extends Component {
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { first_name, last_name, name, onChange, errors, name_cb } = this.props;
    return (
      <>
        <TextFieldGroup
          Title={dict["First name"][language]}
          placeholder={`${dict["Enter your first name"][language]}`}
          name="first_name"
          value={first_name}
          onChange={onChange}
          error={errors?.first_name}
        />
        <TextFieldGroup
          Title={dict["Last name"][language]}
          placeholder={`${dict["Enter your last name"][language]}`}
          name="last_name"
          value={last_name}
          onChange={onChange}
          error={errors?.last_name}
        />
        <TextFieldGroup
          Title={"Username"}
          placeholder={`${dict["Enter your username"][language]}`}
          name="name"
          value={name}
          onChange={onChange}
          error={errors?.name}
          onBlur={name_cb}
        />
      </>
    )
  }
}
