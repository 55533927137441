import React, { useState, useEffect } from "react";

const COLOR_INPUT = ({ on_change, name, value }) => {
  const [color, set_color] = useState("rgba(0, 0, 0, 1)");
  const [alpha, set_alpha] = useState(1);

  useEffect(() => {
    if (value) {
      const parsedColor = value.startsWith("#")
        ? convert_to_rgba(value)
        : value;
      set_color(parsedColor);
      const alphaValue = parseFloat(parsedColor.split(",")[3]);
      set_alpha(isNaN(alphaValue) ? 1 : alphaValue);
    }
  }, [value]);

  const handle_color_change = (e) => {
    const hex = e.target.value;
    const rgba = convert_to_rgba(hex, alpha);
    set_color(rgba);
    on_change({ name, value: rgba });
  };

  const handle_alpha_change = (e) => {
    const new_alpha = parseFloat(e.target.value);
    set_alpha(new_alpha);
    const rgba = color.replace(/\d+\.?\d*\)$/, `${new_alpha})`);
    set_color(rgba);
    on_change({ name, value: rgba });
  };

  const convert_to_rgba = (color_value, alpha = 1) => {
    if (color_value.startsWith("#")) {
      let r = parseInt(color_value.slice(1, 3), 16);
      let g = parseInt(color_value.slice(3, 5), 16);
      let b = parseInt(color_value.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else if (color_value.startsWith("rgb(")) {
      return color_value.replace("rgb(", "rgba(").replace(")", `, ${alpha})`);
    } else if (color_value.startsWith("rgba(")) {
      return color_value;
    }
    return "rgba(0, 0, 0, 1)"; // Default fallback
  };

  return (
    <table
      style={{
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <tbody>
        <tr>
          <td>
            Warna
            <br />
            <p className="badge_small background_black">{color}</p>
          </td>
          <td>
            <input
              type="color"
              name={name}
              value={`#${color
                .match(/\d+/g)
                .slice(0, 3)
                .map((x) => (+x).toString(16).padStart(2, "0"))
                .join("")}`}
              onChange={handle_color_change}
            />
          </td>
        </tr>
        <tr>
          <td>
            Transparansi
            <br />
            <p className="badge_small background_black">{alpha}</p>
          </td>
          <td>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={alpha}
              onChange={handle_alpha_change}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default COLOR_INPUT;
