/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import centroid from "@turf/centroid";
import { timeout } from "workbox-core/_private";

/*COMPONENT*/

import PARENT_HBU_MANUAL from "./PARENT_HBU_MANUAL";

/*REDUX*/
import {
  get_ai_hbu_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { generate_ai_hbu_summary } from "../../App/actions/generate_ai_hbu_summary";

/*PICTURE*/

/*FUNCTION*/
import uuid from "../../App/validation/uuid";
import { snackbar } from "../../App/actions/snackbarActions";

/*DATA*/
import {
  get_value_cell,
  // getColRowIndex,
  numberToColumn,
} from "../../App/validation/calculateExcelFormula";

/*CONST*/

class EXCEL_HBU extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.state = {
      user_message: "",
      user_message_hbu: "",
      mode_capex: "manual", //excel_ai || manual
      is_chat_ai_show: false,
      modal_re_hbu: false,
      is_print: false,
      is_loading: false,
      is_timeout: false,
      modal_show_pedoman: false,
      modal_hbu_manual: false,
    };
  }

  componentDidMount() {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_hbu_summary(params);
    this.props.get_ai_sheet_chat_list(params);

    //advance
    const { feature_object_selected } = this.props.layer;
    const type = feature_object_selected?.geometry?.type;

    let long = 0;
    let lat = 0;

    if (type === "Point") {
      long = feature_object_selected?.geometry?.coordinates?.[0];
      lat = feature_object_selected?.geometry?.coordinates?.[1];
    } else {
      const geojson = {
        type: "FeatureCollection",
        features: [feature_object_selected],
      };
      const feature_center = centroid(geojson);
      long = feature_center?.geometry?.coordinates?.[0];
      lat = feature_center?.geometry?.coordinates?.[1];
    }

    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini, "ai_hbu");
  }

  toggle_show_pedoman = () => {
    this.setState({ modal_show_pedoman: !this.state.modal_show_pedoman });
  };

  on_set_mode_capex = (mode_capex) => {
    this.setState({
      mode_capex,
    });
  };

  toggle_re_hbu = () => {
    this.setState({
      modal_re_hbu: !this.state.modal_re_hbu,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  handle_refresh = () => {
    //basic
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.setState({ is_loading: true }, async () => {
      await this.props.get_ai_hbu_summary(params);
      this.setState({
        is_loading: false,
      });
    });
  };

  convert2DArrayToObjects = (arr) => {
    return arr.map((row) => {
      return row.reduce((obj, value, index) => {
        if (value !== null && value !== "") {
          obj[numberToColumn(index)] = value;
        }
        return obj;
      }, {});
    });
  };

  on_generate_ai_hbu_summary = async () => {
    this.setState({ is_loading: true }, async () => {
      const { user_message_hbu } = this.state;
      const { geo_layer_id, feature_key, field_key } = this.props;
      const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
      const sini_object = ai_parent_detail_list?.find(
        (item) => item.request_id === sheet_object_selected.request_id
      );

      const sini_not_used_list = ["people_spending"];

      for await (const key of sini_not_used_list) {
        if (sini_object?.[key]) delete sini_object[key];
      }

      let worksheet = JSON.parse(JSON.stringify(this.props.worksheet));
      if (worksheet["Read Me"]) delete worksheet["Read Me"];
      if (worksheet["DATA"]) delete worksheet["DATA"];
      if (worksheet["Asumsi Finansial"]) delete worksheet["Asumsi Finansial"];

      const sheet_array = Object.keys(worksheet).map((item) => {
        let sheet = worksheet[item];
        sheet[0][0] = item;
        return this.convert2DArrayToObjects(sheet);
      });

      const landvalue_one = sini_object?.landvalue_one;
      const landvalue_value = landvalue_one?.properties?.["HARGA"];

      const luas_tanah = get_value_cell("D12", worksheet, "Data Objek");
      const capex_tanah = luas_tanah * landvalue_value;

      const body = {
        worksheet: sheet_array,
        geo_layer_id,
        feature_key,
        field_key,
        sini_object,
        user_message_hbu,
        capex_tanah,
      };

      const is_timeout =
        (await this.props.generate_ai_hbu_summary(body)) === "Timeout";
      if (is_timeout) await timeout(25000);

      this.setState({
        user_message_hbu: "",
        modal_re_hbu: false,
        is_loading: false,
        is_timeout: is_timeout ? true : false,
      });
    });
  };

  reset_ai_hbu_summary = () => {
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    const { worksheet } = this.props;
    const sheet_array = Object.keys(worksheet).map((item) => {
      let sheet = worksheet[item];
      sheet[0][0] = item;
      return this.convert2DArrayToObjects(sheet);
    });

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu: "",
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_hbu_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show,
    });
  };

  handleBeforePrint = () => {
    // const canvas = document.getElementById("myCanvas");
    // if (canvas) {
    //   const ctx = canvas.getContext("2d");
    //   canvas.width = 500;
    //   canvas.height = 500;
    //   const img = new Image();
    //   img.src = "path/to/image.jpg";
    //   img.onload = () => {
    //     ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //   };
    // }
  };

  render() {
    return (
      <main>
        <PARENT_HBU_MANUAL {...this.props} />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_hbu_summary,
  get_ai_hbu_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
  snackbar,
})(EXCEL_HBU);
